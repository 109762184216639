import {
    BadgesStateType,
    BadgesActionsType,
    BadgesActionsList
} from './types';

export const initialState: BadgesStateType = {
    data: null,
    modals: {
        create: false,
        edit: false
    },
    loading: false,
    error: null
};

const badgesReducer = (state: BadgesStateType = initialState, action: BadgesActionsType) : BadgesStateType => {
    switch (action.type) {
        case BadgesActionsList.ERROR_BADGES: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case BadgesActionsList.MODAL_ADD_BADGES: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: !state.modals.create
                }
            };
        }
        case BadgesActionsList.MODAL_EDIT_BADGES: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    edit: !state.modals.edit
                }
            };
        }
        case BadgesActionsList.REQUEST_GET_BADGES:
        case BadgesActionsList.REQUEST_ADD_BADGES:
        case BadgesActionsList.REQUEST_EDIT_BADGES:
        case BadgesActionsList.REQUEST_REMOVE_BADGES:
            return {
                ...state,
                loading: true
            };
        case BadgesActionsList.RESPONSE_GET_BADGES: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case BadgesActionsList.RESPONSE_ADD_BADGES: {
            return {
                ...state,
                loading: false,
                data: state.data ? [...state.data, action.data] : state.data,
                modals: {
                    ...state.modals,
                    create: false
                }
            };
        }
        case BadgesActionsList.RESPONSE_EDIT_BADGES: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.data.id) {
                        return {
                            ...item,
                            ...action.data,
                            icon: action.data.icon ? action.data.icon : item.icon
                        };
                    }
                    return item;
                }) : [action.data],
                modals: {
                    ...state.modals,
                    edit: false
                }
            };
        }
        case BadgesActionsList.RESPONSE_REMOVE_BADGES: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.filter((item) => item.id !== action.id) : state.data
            };
        }
        default:
            return state;
    }
};

export default badgesReducer;
