import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload, InputNumber
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import { AppState } from '../../store';
import { editTaskAsyncAction, switchEditModalAction } from './actions';
import { EditTaskForm } from './types';

interface ParamsPathname {
    id?: string
}

const EditTask:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<ParamsPathname>();
    const tasks = useSelector((state: AppState) => state.tasks);
    const [form] = Form.useForm();
    const [editID, setEditID] = useState<number | null>(null);
    const [conditionsType, setConditionsType] = useState<string | undefined>(undefined);

    window.onpopstate = (event) => {
        if (event.type === 'popstate' && window.location.pathname === '/tasks' && tasks.modals.edit) {
            dispatch(switchEditModalAction());
            setEditID(null);
        }
    };

    const onCancel = () => {
        history.goBack();
        dispatch(switchEditModalAction());
        setEditID(null);
    };

    useEffect(() => {
        if (params.id) {
            dispatch(switchEditModalAction());
            setEditID(+params.id);
        }
    }, []);

    useEffect(() => {
        if (tasks.modals.edit) {
            if (params.id) {
                setEditID(+params.id);
            }
        } else if (!tasks.modals.edit && editID) {
            form.resetFields();
            setConditionsType(undefined);
            setEditID(null);
            history.goBack();
        }
    }, [tasks.modals.edit]);

    useEffect(() => {
        if (editID) {
            const editItem = tasks.data ? tasks.data.filter((item) => item.id === editID)[0] : null;
            if (editItem) {
                form.setFieldsValue({
                    title: editItem.title,
                    period: editItem.period,
                    category: editItem.category,
                    conditionsType: editItem.conditions.type,
                    conditionsValue: editItem.conditions.value,
                    description: editItem.description
                });

                setConditionsType(editItem.conditions.type);
            }
        }
    }, [editID]);

    const onSend = (values: EditTaskForm) => {
        if (editID) {
            dispatch(editTaskAsyncAction({
                ...values,
                id: editID
            }));
        }
    };

    return (
        <>
            <Modal
                title="Редактирование задания"
                centered
                visible={tasks.modals.edit}
                onCancel={() => onCancel()}
                width={1150}
                forceRender
                footer={[
                    <Button
                        key="send"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={tasks.loading}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSend}
                >
                    <Row gutter={15}>
                        <Col lg={12}>
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: 'Введите название' }]}
                                label="Название"
                            >
                                <Input placeholder="Введите название" />
                            </Form.Item>
                            <Form.Item
                                name="period"
                                label="Период"
                                rules={[{ required: true, message: 'Выберите период' }]}
                            >
                                <DatePicker.RangePicker
                                    placeholder={['Начало', 'Конец']}
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item
                                name="category"
                                rules={[{ required: true, message: 'Выберите категорию' }]}
                                label="Категория"
                            >
                                <Select
                                    placeholder="Выберите категорию"
                                >
                                    <Select.Option value="single">Одиночное</Select.Option>
                                    <Select.Option value="command">Командное</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                name="conditionsType"
                                rules={[{ required: true, message: 'Выберите тип условия' }]}
                                label="Тип условия"
                            >
                                <Select
                                    placeholder="Выберите тип условия"
                                    onChange={(value: string) => {
                                        form.resetFields(['conditionsValue']);
                                        setConditionsType(value);
                                    }}
                                >
                                    <Select.Option value="distance">Дистанция</Select.Option>
                                    <Select.Option value="time">Время</Select.Option>
                                </Select>
                            </Form.Item>
                            {conditionsType === 'distance' && (
                                <Form.Item
                                    name="conditionsValue"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Дистанция, км"
                                >
                                    <InputNumber min={1} placeholder="Введите дистанцию" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {conditionsType === 'time' && (
                                <Form.Item
                                    name="conditionsValue"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Время, мин"
                                >
                                    <InputNumber min={1} placeholder="Введите время" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Описание"
                            >
                                <Input.TextArea placeholder="Текст описания" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default EditTask;
