import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    Router
} from 'react-router-dom';

import routes from './routes';
import { AppState } from './store';
import PrivateRoute from './components/PrivateRoute';
import { signInAsyncAction } from './pages/Auth/actions';
import Preloader from './components/Preloader';

const App:React.FC = () => {
    const path = window.location.pathname.split('/')[1];
    const dispatch = useDispatch();
    const auth = useSelector((state: AppState) => state.auth);

    useEffect(() => {
        if (auth.data) {
            dispatch(signInAsyncAction({ token: auth.data.token }));
        }
    }, [0]);

    useEffect(() => {
        if (auth.data && auth.data.role && window.location.pathname === '/') {
            switch (auth.data.role) {
                case 'admin':
                    window.location.href = `${window.location.origin}/news`;
                    break;
                case 'user':
                    window.location.href = `${window.location.origin}/profile`;
                    break;
                default:
                    break;
            }
        }
    }, [auth]);

    if (auth.data && auth.data.token && !auth.data.role) {
        return <Preloader fullscreen />;
    }

    return (
        <div className={`app ${path}`}>
            <BrowserRouter>
                <Switch>
                    {routes.map((route) => {
                        if (route.private) {
                            if (route.path.length > 1) {
                                return route.path.map((p) => (
                                    <PrivateRoute
                                        key={p}
                                        path={route.path}
                                        component={route.component}
                                        access={route.access}
                                        exact
                                    />
                                ));
                            }
                            return (
                                <PrivateRoute
                                    key={route.path[0]}
                                    path={route.path}
                                    component={route.component}
                                    access={route.access}
                                    exact
                                />
                            );
                        }

                        return (
                            <Route
                                key={route.path[0]}
                                path={route.path}
                                component={route.component}
                                exact
                            />
                        );
                    })}
                    <Route exact path="/">
                        {localStorage.getItem('token') === null && <Redirect to="/sign-in" />}
                        {auth.data?.role === 'admin' && <Redirect to="/news" />}
                        {auth.data?.role === 'user' && <Redirect to="/profile" />}
                    </Route>
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default App;
