import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload, InputNumber
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import { AppState } from '../../store';
import { editCupAsyncAction, switchEditModalAction } from './actions';
import { EditCupForm } from './types';

interface ParamsPathname {
    id?: string
}

const EditCup:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<ParamsPathname>();
    const cups = useSelector((state: AppState) => state.cups);
    const [form] = Form.useForm();
    const [editID, setEditID] = useState<number | null>(null);

    window.onpopstate = (event) => {
        if (event.type === 'popstate' && window.location.pathname === '/cups' && cups.modals.edit) {
            dispatch(switchEditModalAction());
            setEditID(null);
        }
    };

    const onCancel = () => {
        history.goBack();
        dispatch(switchEditModalAction());
        setEditID(null);
    };

    useEffect(() => {
        if (params.id) {
            dispatch(switchEditModalAction());
            setEditID(+params.id);
        }
    }, []);

    useEffect(() => {
        if (cups.modals.edit) {
            if (params.id) {
                setEditID(+params.id);
            }
        } else if (!cups.modals.edit && editID) {
            form.resetFields();
            setEditID(null);
            history.goBack();
        }
    }, [cups.modals.edit]);

    useEffect(() => {
        if (editID) {
            const editItem = cups.data ? cups.data.filter((item) => item.id === editID)[0] : null;
            if (editItem) {
                form.setFieldsValue({
                    title: editItem.title,
                    period: editItem.period,
                    conditions: editItem.conditions,
                    tasks: editItem.tasks
                });
            }
        }
    }, [editID]);

    const onSend = (values: EditCupForm) => {
        if (editID) {
            dispatch(editCupAsyncAction({
                ...values,
                id: editID
            }));
        }
    };

    return (
        <>
            <Modal
                title="Редактирование кубка"
                centered
                visible={cups.modals.edit}
                onCancel={() => onCancel()}
                width={1150}
                forceRender
                footer={[
                    <Button
                        key="send"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={cups.loading}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSend}
                >
                    <Row gutter={15}>
                        <Col lg={12}>
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: 'Введите название' }]}
                                label="Название"
                            >
                                <Input placeholder="Введите название" />
                            </Form.Item>
                            <Form.Item
                                name="conditions"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Условия"
                            >
                                <Input.TextArea placeholder="Текст условий" rows={5} />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                name="period"
                                label="Период"
                                rules={[{ required: true, message: 'Выберите период' }]}
                            >
                                <DatePicker.RangePicker
                                    placeholder={['Начало', 'Конец']}
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tasks"
                                rules={[{ required: true, message: 'Выберите задания' }]}
                                label="Задания для кубка"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Выберите задания"
                                >
                                    {cups.tasks ? cups.tasks.map((task) => (
                                        <Select.Option value={task.id} key={task.id}>{task.title}</Select.Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default EditCup;
