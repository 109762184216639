import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    ProfileActionsList,
    ProfileActionsType,
    ProfileForm,
    ProfileItem,
    ProfileItemFromServer,
    ProfileStateType
} from './types';

export const errorProfileAction = (error: string): ProfileActionsType => ({
    type: ProfileActionsList.ERROR_PROFILE,
    error
});

// GET ACTIONS //

export const requestGetProfileAction = (): ProfileActionsType => ({
    type: ProfileActionsList.REQUEST_GET_PROFILE
});

export const responseGetProfileAction = (data: ProfileItem): ProfileActionsType => ({
    type: ProfileActionsList.RESPONSE_GET_PROFILE,
    data
});

export const getProfileAsyncAction = (id: number) => async (dispatch: ThunkDispatch<ProfileStateType, void, Action>): Promise<void> => {
    dispatch(requestGetProfileAction());

    const result = await axios.get(`${API}api/v1/users/${id}`);
    const response = result.data;

    if (response.error) {
        dispatch(errorProfileAction(response.message));
    } else {
        const profileResponse = response.data as ProfileItemFromServer;
        const profile: ProfileItem = {
            avatar: profileResponse.avatar,
            birthday: profileResponse.birthday ? moment(profileResponse.birthday) : null,
            city: profileResponse.city,
            country: profileResponse.country,
            email: profileResponse.login,
            gender: profileResponse.gender,
            phone: profileResponse.phone,
            stravaID: profileResponse.strava_id,
            stravaName: profileResponse.strava_name
        };

        dispatch(responseGetProfileAction(profile));
    }
};

// EDIT ACTIONS //

export const editProfileAction = (): ProfileActionsType => ({
    type: ProfileActionsList.EDIT_PROFILE
});

export const saveProfileAction = (): ProfileActionsType => ({
    type: ProfileActionsList.SAVE_PROFILE
});

export const requestSaveProfileAction = (): ProfileActionsType => ({
    type: ProfileActionsList.REQUEST_GET_PROFILE
});

export const responseSaveProfileAction = (data: ProfileItem): ProfileActionsType => ({
    type: ProfileActionsList.RESPONSE_SAVE_PROFILE,
    data
});

export const saveProfileAsyncAction = (data: ProfileForm & { id: number, avatar: string | null }) => async (dispatch: ThunkDispatch<ProfileStateType, void, Action>): Promise<void> => {
    dispatch(requestSaveProfileAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('birthday', data.birthday ? data.birthday.format('X') : '');
    formData.append('city', data.city ? data.city : '');
    formData.append('country', data.country ? data.country : '');
    formData.append('login', data.email ? data.email : '');
    formData.append('gender', data.gender ? data.gender : '');
    formData.append('phone', data.phone ? data.phone : '');

    const result = await axios.post(`${API}api/v1/users/edit`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorProfileAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const profileData: ProfileItem = {
            avatar: data.avatar,
            birthday: data.birthday ? data.birthday : null,
            city: data.city ? data.city : null,
            country: data.country ? data.country : null,
            email: data.email ? data.email : '',
            gender: data.gender ? data.gender : null,
            phone: data.phone ? data.phone : null,
            stravaID: null,
            stravaName: null
        };

        dispatch(responseSaveProfileAction(profileData));
        notification('success', 'Успех', 'Профиль обновлен');
    }
};

export const requestUploadAvatarProfileAction = (): ProfileActionsType => ({
    type: ProfileActionsList.REQUEST_UPLOAD_AVATAR_PROFILE
});

export const responseUploadAvatarProfileAction = (data: string): ProfileActionsType => ({
    type: ProfileActionsList.RESPONSE_UPLOAD_AVATAR_PROFILE,
    data
});

export const uploadAvatarProfileAsyncAction = (data: { file: File, id: number }) => async (dispatch: ThunkDispatch<ProfileStateType, void, Action>): Promise<void> => {
    dispatch(requestSaveProfileAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('file', data.file);

    const result = await axios.post(`${API}api/v1/users/editAvatar`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorProfileAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseUploadAvatarProfileAction(response.data.path as string));
        notification('success', 'Успех', 'Профиль обновлен');
    }
};

// PAIR //

export const pairStravaProfileAsyncAction = () => async (dispatch: ThunkDispatch<ProfileStateType, void, Action>): Promise<void> => {
    const result = await axios.get(`${API}api/v1/auth/strava`);
    const response = result.data;

    if (response.error) {
        dispatch(errorProfileAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        window.location.href = response.data.url;
    }
};
