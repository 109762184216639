import {
    ProfileStateType,
    ProfileActionsList,
    ProfileActionsType
} from './types';

export const initialState: ProfileStateType = {
    data: null,
    loading: false,
    edit: false,
    save: false,
    error: null
};

const profileReducer = (state: ProfileStateType = initialState, action: ProfileActionsType) : ProfileStateType => {
    switch (action.type) {
        case ProfileActionsList.ERROR_PROFILE: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case ProfileActionsList.EDIT_PROFILE: {
            return {
                ...state,
                edit: !state.edit
            };
        }
        case ProfileActionsList.SAVE_PROFILE: {
            return {
                ...state,
                save: !state.save
            };
        }
        case ProfileActionsList.REQUEST_GET_PROFILE:
        case ProfileActionsList.REQUEST_SAVE_PROFILE:
        case ProfileActionsList.REQUEST_UPLOAD_AVATAR_PROFILE: {
            return {
                ...state,
                loading: true
            };
        }
        case ProfileActionsList.RESPONSE_GET_PROFILE: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case ProfileActionsList.RESPONSE_SAVE_PROFILE: {
            return {
                ...state,
                loading: false,
                edit: false,
                save: false,
                data: action.data
            };
        }
        case ProfileActionsList.RESPONSE_UPLOAD_AVATAR_PROFILE: {
            return {
                ...state,
                loading: false,
                data: state.data ? {
                    ...state.data,
                    avatar: action.data
                } : state.data
            };
        }
        default:
            return state;
    }
};

export default profileReducer;
