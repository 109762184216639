export type AccountsType = 'admin' | 'user' | 'organizer';

export type AccountData = {
    id: number | null,
    role: AccountsType | null,
    token: string,
    login: string
};

export interface AuthStateType {
    data: AccountData | null,
    loading: boolean,
    error: string | null
}

export interface SignInFormData {
    login: string,
    password: string
}

export interface SignInTokenData {
    token: string
}

export interface SignUpFormData {
    login: string,
    password: string,
    'password-retry': string,
    type: string,
    code: string,
    remember: boolean
}

export enum AuthActionsList {
    ERROR_AUTH = 'ERROR_AUTH',
    REQUEST_SIGNIN_AUTH = 'REQUEST_SIGNIN_AUTH',
    RESPONSE_SIGNIN_AUTH = 'RESPONSE_SIGNIN_AUTH',
    REQUEST_SIGNOUT_AUTH = 'REQUEST_SIGNOUT_AUTH',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH',
    REQUEST_SIGNUP_AUTH = 'REQUEST_SIGNUP_AUTH',
    RESPONSE_SIGNUP_AUTH = 'RESPONSE_SIGNUP_AUTH',
    LOGOUT_AUTH = 'LOGOUT_AUTH'
}

export interface ErrorAuthActionType {
    type: AuthActionsList.ERROR_AUTH,
    error: string
}

export interface RequestSignInActionType {
    type: AuthActionsList.REQUEST_SIGNIN_AUTH
}

export interface ResponseSignInActionType {
    type: AuthActionsList.RESPONSE_SIGNIN_AUTH,
    data: AccountData
}

export interface RequestSignOutActionType {
    type: AuthActionsList.REQUEST_SIGNOUT_AUTH
}

export interface ResponseSignOutActionType {
    type: AuthActionsList.RESPONSE_SIGNOUT_AUTH
}

export interface RequestSignUpActionType {
    type: AuthActionsList.REQUEST_SIGNUP_AUTH,
    data: SignUpFormData
}

export interface ResponseSignUpActionType {
    type: AuthActionsList.RESPONSE_SIGNUP_AUTH,
    id: string | null,
    uid: string | null,
    email: string | null,
    account: AccountsType | null,
    company: string | null
}

export interface LogoutActionType {
    type: AuthActionsList.LOGOUT_AUTH
}

export type AuthActionsType =
ErrorAuthActionType
| RequestSignInActionType
| ResponseSignInActionType
| RequestSignOutActionType
| ResponseSignOutActionType
| RequestSignUpActionType
| ResponseSignUpActionType
| LogoutActionType;
