/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Badge, Breadcrumb, Calendar, Typography, Row, Col, Select, Radio, Modal, Table
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
import Preloader from '../../components/Preloader';
import { ReactComponent as ActivityIcon1Svg } from '../../assets/activity-icon-1.svg';
import { ReactComponent as ActivityIcon2Svg } from '../../assets/activity-icon-2.svg';
import { ReactComponent as ActivityIcon3Svg } from '../../assets/activity-icon-3.svg';
import { ReactComponent as ActivityIcon4Svg } from '../../assets/activity-icon-4.svg';
import { ReactComponent as ActivityIcon5Svg } from '../../assets/activity-icon-5.svg';
import { getWorkoutsAsyncAction } from './actions';
import { CalendarWorkouts, TrainingItem } from './types';

const { Title } = Typography;

interface ViewTrainModal {
    show: boolean,
    title: string,
    data: CalendarWorkouts | null
}

const HeaderWorkoutPage:React.FC = () => (
    <div className="page__header">
        <Breadcrumb>
            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>Мои тренировки</Breadcrumb.Item>
        </Breadcrumb>
    </div>
);

const CalendarHeader = ({
    value, type, onChange, onTypeChange
}: {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
}): React.ReactNode => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
        monthOptions.push(
            <Select.Option className="month-item" key={index}>
                {months[index]}
            </Select.Option>
        );
    }
    const month = value.month();

    const year = value.year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
            <Select.Option key={i} value={String(i)} className="year-item">
                {i}
            </Select.Option>
        );
    }

    return (
        <div className="calendar__header">
            <Row gutter={15}>
                <Col>
                    <Badge color="#6b8720" text="Ходьба" />
                </Col>
                <Col>
                    <Badge color="#95c11f" text="Бег" />
                </Col>
                <Col>
                    <Badge color="#cb5c34" text="Велосипед" />
                </Col>
                <Col>
                    <Badge color="#2196f3" text="Плавание" />
                </Col>
                <Col>
                    <Badge color="#3f51b5" text="Лыжи" />
                </Col>
            </Row>
            <Row gutter={15}>
                <Col>
                    <Select
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        dropdownClassName="calendar__select-month"
                        onChange={(selectedMonth) => {
                            const newValue = value.clone();
                            newValue.month(parseInt(selectedMonth, 10));
                            onChange(newValue);
                        }}
                    >
                        {monthOptions}
                    </Select>
                </Col>
                <Col>
                    <Select
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={(newYear) => {
                            const now = value.clone().year(+newYear);
                            onChange(now);
                        }}
                        value={String(year)}
                    >
                        {options}
                    </Select>
                </Col>
                <Col>
                    <Radio.Group onChange={(e) => onTypeChange(e.target.value)} value={type}>
                        <Radio.Button value="month">Месяц</Radio.Button>
                        <Radio.Button value="year">Год</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </div>
    );
};

const WorkoutPage:React.FC = () => {
    const dispatch = useDispatch();
    const workout = useSelector((state: AppState) => state.workout);
    const defaultViewModal = {
        show: false,
        title: '',
        data: null
    };
    const [viewModal, setViewModal] = useState<ViewTrainModal>(defaultViewModal);
    const [calendarMode, setCalendarMode] = useState<CalendarMode>('month');
    const [prevDate, setPrevDate] = useState<Moment>(moment());

    useEffect(() => {
        dispatch(getWorkoutsAsyncAction());
    }, []);

    const dateCellRender = (date: Moment) => {
        if (workout.data) {
            const dayTrains = workout.data.calendar[date.format('YYYY-MM-D')];

            if (dayTrains === undefined) {
                return null;
            }

            return (
                <ul className="workout__events">
                    {dayTrains.walk.map((item) => (
                        <li key={item.id}>
                            <Badge color="#6b8720" text={item.name} />
                        </li>
                    ))}
                    {dayTrains.run.map((item) => (
                        <li key={item.id}>
                            <Badge color="#95c11f" text={item.name} />
                        </li>
                    ))}
                    {dayTrains.ride.map((item) => (
                        <li key={item.id}>
                            <Badge color="#cb5c34" text={item.name} />
                        </li>
                    ))}
                    {dayTrains.swim.map((item) => (
                        <li key={item.id}>
                            <Badge color="#2196f3" text={item.name} />
                        </li>
                    ))}
                    {dayTrains.ski.map((item) => (
                        <li key={item.id}>
                            <Badge color="#3f51b5" text={item.name} />
                        </li>
                    ))}
                </ul>
            );
        }

        return null;
    };

    if (workout.data === null) {
        return <Preloader />;
    }

    return (
        <>
            <section className="workout page">
                <Title level={2}>Тип тренировки</Title>
                <div className="workout__types types">
                    <button type="button" className="types__item">
                        <ActivityIcon1Svg />
                        <span>
                            {workout.data.workouts.totals.walk / 1000}
                            &nbsp;км
                        </span>
                    </button>
                    <button type="button" className="types__item">
                        <ActivityIcon2Svg />
                        <span>
                            {workout.data.workouts.totals.run / 1000}
                            &nbsp;км
                        </span>
                    </button>
                    <button type="button" className="types__item">
                        <ActivityIcon3Svg />
                        <span>
                            {workout.data.workouts.totals.ride / 1000}
                            &nbsp;км
                        </span>
                    </button>
                    <button type="button" className="types__item">
                        <ActivityIcon4Svg />
                        <span>
                            {workout.data.workouts.totals.swim / 1000}
                            &nbsp;км
                        </span>
                    </button>
                    <button type="button" className="types__item">
                        <ActivityIcon5Svg />
                        <span>
                            {workout.data.workouts.totals.ski / 1000}
                            &nbsp;км
                        </span>
                    </button>
                </div>
                <Title level={2} style={{ marginTop: '3rem' }}>Календарь тренировок</Title>
                <Calendar
                    dateCellRender={dateCellRender}
                    className="calendar"
                    headerRender={CalendarHeader}
                    mode={calendarMode}
                    onChange={(date) => {
                        if (date.format('YYYY-M') !== prevDate.format('YYYY-M')) {
                            dispatch(getWorkoutsAsyncAction(date.format('YYYY-M')));
                        }
                        if (calendarMode === 'year') {
                            setCalendarMode('month');
                        }
                        setPrevDate(date);
                    }}
                    onPanelChange={(date, mode) => setCalendarMode(mode)}
                    onSelect={(date: Moment) => {
                        if (workout.data && (date.format('YYYY-M') === prevDate.format('YYYY-M'))) {
                            const dayTrains = workout.data.calendar[date.format('YYYY-MM-D')];
                            setViewModal({
                                show: true,
                                title: `Тренировки за ${date.format('DD.MM.YYYY')}`,
                                data: dayTrains
                            });
                        }
                    }}
                />
            </section>
            <Modal
                title={viewModal.title}
                centered
                visible={viewModal.show}
                onOk={() => setViewModal(defaultViewModal)}
                onCancel={() => setViewModal(defaultViewModal)}
                width={1000}
                className="trains-day"
            >
                {viewModal.data && viewModal.data.walk.length ? (
                    <>
                        <Title level={3} className="trains-day__head">
                            <ActivityIcon1Svg />
                            Ходьба
                        </Title>
                        <Table
                            dataSource={viewModal.data ? viewModal.data.walk : []}
                            size="small"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column title="Название" dataIndex="name" key="name" />
                            <Table.Column
                                title="Дистанция"
                                dataIndex="distance"
                                key="distance"
                                render={(value: number) => `${value / 1000} км`}
                            />
                            <Table.Column
                                title="Средний темп"
                                key="temp"
                                render={(_, record: TrainingItem) => (`${((record.distance / 1000) / (record.time / 60)).toFixed(2)} мин/км`)}
                            />
                            <Table.Column
                                title="Перепад высот"
                                dataIndex="elevation"
                                key="elevation"
                                render={(value: number) => `${value} м`}
                            />
                            <Table.Column
                                title="Чистое время"
                                dataIndex="time"
                                key="time"
                                render={(value: number) => `${value / 60}:${(value % 60).toFixed(0) ? `0${(value % 60).toFixed(0)}` : (value % 60).toFixed(0)}`}
                            />
                        </Table>
                    </>
                ) : null}
                {viewModal.data && viewModal.data.run.length ? (
                    <>
                        <Title level={3} className="trains-day__head">
                            <ActivityIcon2Svg />
                            Бег
                        </Title>
                        <Table
                            dataSource={viewModal.data ? viewModal.data.run : []}
                            size="small"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column title="Название" dataIndex="name" key="name" />
                            <Table.Column
                                title="Дистанция"
                                dataIndex="distance"
                                key="distance"
                                render={(value: number) => `${value / 1000} км`}
                            />
                            <Table.Column
                                title="Средний темп"
                                key="temp"
                                render={(_, record: TrainingItem) => (`${((record.distance / 1000) / (record.time / 60)).toFixed(2)} мин/км`)}
                            />
                            <Table.Column
                                title="Перепад высот"
                                dataIndex="elevation"
                                key="elevation"
                                render={(value: number) => `${value} м`}
                            />
                            <Table.Column
                                title="Чистое время"
                                dataIndex="time"
                                key="time"
                                render={(value: number) => `${value / 60}:${(value % 60).toFixed(0) ? `0${(value % 60).toFixed(0)}` : (value % 60).toFixed(0)}`}
                            />
                        </Table>
                    </>
                ) : null}
                {viewModal.data && viewModal.data.ride.length ? (
                    <>
                        <Title level={3} className="trains-day__head">
                            <ActivityIcon3Svg />
                            Велосипед
                        </Title>
                        <Table
                            dataSource={viewModal.data ? viewModal.data.ride : []}
                            size="small"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column title="Название" dataIndex="name" key="name" />
                            <Table.Column
                                title="Дистанция"
                                dataIndex="distance"
                                key="distance"
                                render={(value: number) => `${value / 1000} км`}
                            />
                            <Table.Column
                                title="Средний темп"
                                key="temp"
                                render={(_, record: TrainingItem) => (`${((record.distance / 1000) / (record.time / 60)).toFixed(2)} мин/км`)}
                            />
                            <Table.Column
                                title="Перепад высот"
                                dataIndex="elevation"
                                key="elevation"
                                render={(value: number) => `${value} м`}
                            />
                            <Table.Column
                                title="Чистое время"
                                dataIndex="time"
                                key="time"
                                render={(value: number) => `${value / 60}:${(value % 60).toFixed(0) ? `0${(value % 60).toFixed(0)}` : (value % 60).toFixed(0)}`}
                            />
                        </Table>
                    </>
                ) : null}
                {viewModal.data && viewModal.data.swim.length ? (
                    <>
                        <Title level={3} className="trains-day__head">
                            <ActivityIcon4Svg />
                            Плавание
                        </Title>
                        <Table
                            dataSource={viewModal.data ? viewModal.data.swim : []}
                            size="small"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column title="Название" dataIndex="name" key="name" />
                            <Table.Column
                                title="Дистанция"
                                dataIndex="distance"
                                key="distance"
                                render={(value: number) => `${value / 1000} км`}
                            />
                            <Table.Column
                                title="Средний темп"
                                key="temp"
                                render={(_, record: TrainingItem) => (`${((record.distance / 1000) / (record.time / 60)).toFixed(2)} мин/км`)}
                            />
                            <Table.Column
                                title="Перепад высот"
                                dataIndex="elevation"
                                key="elevation"
                                render={(value: number) => `${value} м`}
                            />
                            <Table.Column
                                title="Чистое время"
                                dataIndex="time"
                                key="time"
                                render={(value: number) => `${value / 60}:${(value % 60).toFixed(0) ? `0${(value % 60).toFixed(0)}` : (value % 60).toFixed(0)}`}
                            />
                        </Table>
                    </>
                ) : null}
                {viewModal.data && viewModal.data.ski.length ? (
                    <>
                        <Title level={3} className="trains-day__head">
                            <ActivityIcon5Svg />
                            Лыжи
                        </Title>
                        <Table
                            dataSource={viewModal.data ? viewModal.data.ski : []}
                            size="small"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column title="Название" dataIndex="name" key="name" />
                            <Table.Column
                                title="Дистанция"
                                dataIndex="distance"
                                key="distance"
                                render={(value: number) => `${value / 1000} км`}
                            />
                            <Table.Column
                                title="Средний темп"
                                key="temp"
                                render={(_, record: TrainingItem) => (`${((record.distance / 1000) / (record.time / 60)).toFixed(2)} мин/км`)}
                            />
                            <Table.Column
                                title="Перепад высот"
                                dataIndex="elevation"
                                key="elevation"
                                render={(value: number) => `${value} м`}
                            />
                            <Table.Column
                                title="Чистое время"
                                dataIndex="time"
                                key="time"
                                render={(value: number) => `${value / 60}:${(value % 60).toFixed(0) ? `0${(value % 60).toFixed(0)}` : (value % 60).toFixed(0)}`}
                            />
                        </Table>
                    </>
                ) : null}
            </Modal>
            {workout.loading && (<Preloader fullpage />)}
        </>
    );
};

export default WrapperPage(WorkoutPage, HeaderWorkoutPage);
