/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Steps
} from 'antd';

import { AppState } from '../../store';
import { addRunAsyncAction, changeStepRunsAction, switchAddModalAction } from './actions';
import BaseInfo from './steps/BaseInfo';
import Contacts from './steps/Contacts';
import Distance, { defaultDistanceData } from './steps/Distance';
import Payment from './steps/Payment';
import Registration from './steps/Registration';
import { RunsItem } from './types';
import { RunFormContextType } from './index';

export const AddRunFormContext = React.createContext<RunFormContextType>({
    createItem: null,
    onNext: undefined
});

const defaultItemRunData: RunsItem = {
    id: 0,
    title: undefined,
    country: undefined,
    region: undefined,
    city: undefined,
    date: undefined,
    description: undefined,
    distances: [defaultDistanceData],
    start: {
        place: undefined,
        time: undefined
    },
    finish: {
        place: undefined,
        time: undefined
    },
    coords: undefined,
    image: undefined,
    numbering: {
        numberingType: undefined,
        requireFields: undefined,
        numberingDistances: undefined
    },
    organizer: {
        name: '',
        email: undefined,
        phone: undefined,
        site: undefined,
        attachments: undefined
    },
    payments: [],
    program: undefined,
    requirements: undefined,
    typeEvent: undefined,
    otherInfo: undefined,
    howToGet: undefined,
    whereToLive: undefined,
    expo: undefined,
    registrationType: 'none',
    registrationSite: undefined,
    participant: []
};

const AddRun:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const data = defaultItemRunData; // testData;
    const contextData: RunFormContextType = { createItem: data, onNext: undefined };

    const prevStep = () => {
        dispatch(changeStepRunsAction('prev'));
    };

    const nextStep = () => {
        runs.stepForm?.submit();
        if (runs.step === 4 && contextData.createItem) {
            dispatch(addRunAsyncAction(contextData.createItem));
        }
    };

    return (
        <>
            <Modal
                centered
                visible={runs.modals.create}
                onCancel={() => dispatch(switchAddModalAction())}
                width={1150}
                className={`runs__modal ${runs.step === 0 ? 'start' : runs.step === 4 ? 'end' : ''}`}
                footer={(
                    <>
                        {runs.step > 0 && (
                            <Button
                                key="prev"
                                onClick={() => prevStep()}
                                loading={runs.loading}
                            >
                                Назад
                            </Button>
                        )}
                        {runs.step < 5 && (
                            <Button
                                key="next"
                                type="primary"
                                onClick={() => nextStep()}
                                loading={runs.loading}
                            >
                                {runs.step === 4 ? 'Создать забег' : 'Продолжить'}
                            </Button>
                        )}
                    </>
                )}
            >
                <Steps size="small" current={runs.step} style={{ margin: '30px 0' }}>
                    <Steps.Step title="Основная инф." />
                    <Steps.Step title="Контакты" />
                    <Steps.Step title="Дистанция" />
                    <Steps.Step title="Оплата" />
                    <Steps.Step title="Рег. данные" />
                </Steps>
                <AddRunFormContext.Provider value={contextData}>
                    {runs.step === 0 && (<BaseInfo />)}
                    {runs.step === 1 && (<Contacts />)}
                    {runs.step === 2 && (<Distance />)}
                    {runs.step === 3 && (<Payment />)}
                    {runs.step === 4 && (<Registration />)}
                </AddRunFormContext.Provider>
            </Modal>
        </>
    );
};

export default AddRun;
