/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row, Col, Form, Input, Select, Typography, Radio, Checkbox, Tabs
} from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { AppState } from '../../../store';
import { changeStepFormRunsAction } from '../actions';
import { AddRunFormContext } from '../AddRun';
import { NumberingDistanceItem } from '../types';
import { EditRunFormContext } from '../EditRun';

const { Title } = Typography;
const { TabPane } = Tabs;

/*
    requireFields: string[],
    numberingType: string,
    numberingDistances?: NumberingDustanceItem[] | undefined
*/

const Registration:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const { createItem } = runs.modals.create ? useContext(AddRunFormContext) : useContext(EditRunFormContext);
    const [numberingType, setNumberingType] = useState<string>('normal');
    const [activeKey, setActiveKey] = useState('0');
    const [registrationType, setRegistrationType] = useState(createItem ? createItem.registrationType : 'none');
    const [numberingDistance, setNumberingDistance] = useState<NumberingDistanceItem[]>([]);

    const setFieldsTab = (key: number, newNumbering?: NumberingDistanceItem[] | undefined) => {
        const itemNumbering = newNumbering || numberingDistance;

        const newFieldsData: any[] = [
            {
                name: 'requireFields',
                value: form.getFieldValue('requireFields')
            },
            {
                name: 'numberingType',
                value: form.getFieldValue('numberingType')
            }
        ];

        itemNumbering.forEach((_, i) => {
            const tmp = [
                {
                    name: `range-${i}`,
                    value: itemNumbering[i].range
                },
                {
                    name: `gender-${i}`,
                    value: itemNumbering[i].gender
                },
                {
                    name: `exceptions-${i}`,
                    value: itemNumbering[i].exceptions
                }
            ];
            newFieldsData.push(...tmp);
        });

        form.resetFields();
        form.setFields(newFieldsData);
    };

    useEffect(() => {
        if (createItem) {
            if (createItem.numbering.numberingType) setNumberingType(createItem.numbering.numberingType);

            form.setFields([{ name: 'requireFields', value: createItem.numbering.requireFields }]);
            form.setFields([{ name: 'numberingType', value: createItem.numbering.numberingType }]);

            if (runs.modals.create) {
                if (createItem?.distances.length) {
                    const numberingDistances: NumberingDistanceItem[] = createItem.distances.map((_, i): NumberingDistanceItem => ({
                        distanceKey: i.toString(),
                        range: undefined,
                        gender: [],
                        exceptions: undefined
                    }));
                    setNumberingDistance(numberingDistances);
                }
            } else if (runs.modals.edit && createItem.numbering.numberingDistances) {
                setNumberingDistance(createItem.numbering.numberingDistances);
                setFieldsTab(0, createItem.numbering.numberingDistances);
            }
        }
    }, []);

    useEffect(() => {
        if (runs.step === 4) {
            dispatch(changeStepFormRunsAction(form));
        }
    }, [runs.step]);

    const onChangeTabs = (key: string) => {
        setActiveKey(key);
        setFieldsTab(+key);
    };

    const onChange = (key: string, index: number, value: any) => {
        const newNumbering = numberingDistance.map((item, i) => {
            if (i === index) {
                if (key === 'gender') {
                    return {
                        ...item,
                        [key]: value.length ? value : undefined
                    };
                }
                return {
                    ...item,
                    [key]: value
                };
            }
            return item;
        });

        setNumberingDistance(newNumbering);
    };

    const onFinish = () => {
        if (createItem) {
            createItem.registrationType = form.getFieldValue('registrationType');
            createItem.registrationSite = form.getFieldValue('registrationSite');
            createItem.numbering = {
                numberingType,
                requireFields: form.getFieldValue('requireFields')
            };

            if (numberingType === 'byDistance') {
                for (let i = 0; i < numberingDistance.length; i++) {
                    const keys = Object.keys(numberingDistance[i]);
                    for (let j = 0; j < keys.length; j++) {
                        const key = keys[j];
                        if (key !== 'exceptions' && numberingDistance[i][key] === undefined) {
                            setActiveKey(i.toString());
                            setFieldsTab(i);
                            form.validateFields();
                            return;
                        }
                    }
                }

                createItem.numbering.numberingDistances = numberingDistance;
            }
        }
    };

    const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
        if (numberingType === 'byDistance') {
            for (let i = 0; i < numberingDistance.length; i++) {
                const keys = Object.keys(numberingDistance[i]);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    if (key !== 'exceptions' && numberingDistance[i][key] === undefined) {
                        setActiveKey(i.toString());
                        setFieldsTab(i);
                        form.validateFields();
                        return;
                    }
                }
            }
        }
    };

    return (
        <Row gutter={15}>
            <Col lg={24}>
                <Title level={3}>Шаг 5: Регистрационнаые данные</Title>
            </Col>
            <Col lg={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        registrationType,
                        registrationSite: createItem?.registrationSite
                    }}
                >
                    <Form.Item
                        name="registrationType"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Вид регистрации"
                    >
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) => setRegistrationType(e.target.value)}
                        >
                            <Radio.Button value="none">Без регистрации</Radio.Button>
                            <Radio.Button value="athletica">Регистрация на athletica.space</Radio.Button>
                            <Radio.Button value="other">Регистрация на стороннем сайте</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    {registrationType === 'athletica' ? (
                        <Row gutter={15}>
                            <Col lg={12}>
                                <Form.Item
                                    name="requireFields"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Обязательные поля для регистрации"
                                >
                                    <Select
                                        placeholder="Выберите поля"
                                        mode="multiple"
                                    >
                                        <Select.Option value="birthday">Дата рождения</Select.Option>
                                        <Select.Option value="gender">Пол</Select.Option>
                                        <Select.Option value="country">Страна</Select.Option>
                                        <Select.Option value="city">Город</Select.Option>
                                        <Select.Option value="email">E-mail</Select.Option>
                                        <Select.Option value="phone">Телефон</Select.Option>
                                        <Select.Option value="period">Период регистрации</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    name="numberingType"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Правила присвоения номеров"
                                    initialValue="normal"
                                >
                                    <Radio.Group
                                        buttonStyle="solid"
                                        onChange={(e) => setNumberingType(e.target.value)}
                                    >
                                        <Radio.Button value="normal">Сквозная нумерация</Radio.Button>
                                        <Radio.Button value="byDistance">Нумерация для каждого забега</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {numberingType === 'byDistance' && (
                                    <Tabs
                                        type="card"
                                        onChange={onChangeTabs}
                                        activeKey={activeKey}
                                    >
                                        {numberingDistance.map((item, i) => (
                                            <TabPane
                                                tab={createItem?.distances[+item.distanceKey].name}
                                                key={i}
                                                closable={false}
                                            >
                                                <Form.Item
                                                    name={`range-${i}`}
                                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                                    label="Диапазон номеров"
                                                >
                                                    <Input
                                                        placeholder="1-100"
                                                        onChange={(e) => onChange('range', i, e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={`gender-${i}`}
                                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                                    label="Пол"
                                                >
                                                    <Checkbox.Group
                                                        options={[
                                                            { label: 'мужской', value: 'male' },
                                                            { label: 'женский', value: 'female' }
                                                        ]}
                                                        onChange={(checkedValue) => onChange('gender', i, checkedValue)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={`exceptions-${i}`}
                                                    rules={[{ required: false, message: 'Заполните поле' }]}
                                                    label="Исключения"
                                                >
                                                    <Input
                                                        placeholder="1,2,3"
                                                        onChange={(e) => onChange('exceptions', i, e.target.value)}
                                                    />
                                                </Form.Item>
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                )}
                            </Col>
                        </Row>
                    ) : null}
                    {registrationType === 'other' ? (
                        <Row gutter={15}>
                            <Col lg={12}>
                                <Form.Item
                                    name="registrationSite"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Сайт для регистрации"
                                >
                                    <Input placeholder="https://athletica.space" />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Form>
            </Col>
        </Row>
    );
};

export default Registration;
