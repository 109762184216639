import React from 'react';

import {
    AuthPage,
    NotAccess,
    NotFound,
    NewsPage,
    RunsPage,
    BadgesPage,
    TasksPage,
    CupsPage,
    ProfilePage,
    WorkoutPage,
    CalendarPage,
    RatingPage
} from './pages';
import { AccountsType } from './pages/Auth/types';

type RouteType = {
    path: string[]
    component: any,
    private: boolean,
    access: AccountsType | null,
    showInMenu: boolean,
    nameInMenu?: string,
    nameInPage?: string,
    icon?: React.ReactNode
};

const routes:RouteType[] = [
    {
        path: ['/403'],
        component: NotAccess,
        private: false,
        access: null,
        showInMenu: false
    },
    {
        path: ['/404'],
        component: NotFound,
        private: false,
        access: null,
        showInMenu: false
    },
    {
        path: ['/sign-in'],
        component: AuthPage,
        private: false,
        access: null,
        showInMenu: false
    },
    {
        path: ['/sign-up'],
        component: AuthPage,
        private: false,
        access: null,
        showInMenu: false
    },
    {
        path: ['/news', '/news/edit/:id'],
        component: NewsPage,
        private: true,
        access: 'admin',
        showInMenu: true,
        nameInPage: 'Новости'
    },
    {
        path: ['/runs', '/runs/edit/:id'],
        component: RunsPage,
        private: true,
        access: 'admin',
        showInMenu: true,
        nameInPage: 'Забеги'
    },
    {
        path: ['/badges', '/badges/edit/:id'],
        component: BadgesPage,
        private: true,
        access: 'admin',
        showInMenu: true,
        nameInPage: 'Бейджи'
    },
    {
        path: ['/tasks', '/tasks/edit/:id'],
        component: TasksPage,
        private: true,
        access: 'admin',
        showInMenu: true,
        nameInPage: 'Задания кубков'
    },
    {
        path: ['/cups', '/cups/edit/:id'],
        component: CupsPage,
        private: true,
        access: 'admin',
        showInMenu: true,
        nameInPage: 'Кубки'
    },
    {
        path: ['/profile'],
        component: ProfilePage,
        private: true,
        access: 'user',
        showInMenu: true,
        nameInPage: 'Профиль'
    },
    {
        path: ['/workout'],
        component: WorkoutPage,
        private: true,
        access: 'user',
        showInMenu: true,
        nameInPage: 'Тренировки'
    },
    {
        path: ['/calendar'],
        component: CalendarPage,
        private: true,
        access: 'user',
        showInMenu: true,
        nameInPage: 'Календарь забегов'
    },
    {
        path: ['/rating'],
        component: RatingPage,
        private: true,
        access: 'user',
        showInMenu: true,
        nameInPage: 'Рейтинг'
    }
];

export default routes;
