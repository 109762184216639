import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table, Tag, Space, Button, Breadcrumb, Modal, Result
} from 'antd';
import moment, { Moment } from 'moment';
import {
    CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, HomeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
// import { NewsItemData } from './types';
import {
    getBadgesAsyncAction,
    removeBadgeAsyncAction,
    switchAddModalAction,
    switchEditModalAction
} from './actions';
import AddBadge from './AddBadge';
import EditBadge from './EditBadge';
import Preloader from '../../components/Preloader';
import { BadgeItem } from './types';
import { API } from '../..';

const { Column } = Table;

const HeaderBadgesPage:React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="page__header">
            <Breadcrumb>
                <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Бейджи</Breadcrumb.Item>
            </Breadcrumb>
            <Button type="primary" onClick={() => dispatch(switchAddModalAction())}>Добавить бейдж</Button>
        </div>
    );
};

const BadgesPage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const badges = useSelector((state: AppState) => state.badges);

    useEffect(() => {
        dispatch(getBadgesAsyncAction());
    }, []);

    const edit = (id: number) => {
        history.push(`/badges/edit/${id}`);
        dispatch(switchEditModalAction());
    };

    const confirm = (id: number) => {
        Modal.confirm({
            title: '',
            icon: null,
            content: (
                <Result
                    status="warning"
                    title="Вы уверены что хотите удалить бейдж?"
                />
            ),
            okText: 'Подтвердить',
            cancelText: 'Отменить',
            centered: true,
            width: 685,
            className: 'app__confirm',
            onOk: () => dispatch(removeBadgeAsyncAction(id))
        });
    };

    if (badges.data === null) {
        return <Preloader />;
    }

    return (
        <section className="badges page">
            <Table dataSource={badges.data} pagination={false} rowKey="id">
                <Column
                    title="Изображение"
                    dataIndex="icon"
                    render={(value: string) => <img src={`${API}${value}`} alt={value} style={{ width: '50px' }} />}
                />
                <Column title="Название" dataIndex="title" />
                <Column
                    title="Период"
                    dataIndex="period"
                    render={(value: Moment[]) => (value ? `${value[0].format('DD.MM.YYYY')} - ${value[1].format('DD.MM.YYYY')}` : null)}
                />
                <Column title="Уровень" dataIndex="level" />
                <Column
                    title="Действия"
                    render={(item: BadgeItem) => (
                        <Space size="middle">
                            <Button onClick={() => edit(item.id)}>
                                <EditOutlined />
                            </Button>
                            <Button danger onClick={() => confirm(item.id)}>
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    )}
                />
            </Table>
            <AddBadge />
            <EditBadge />
        </section>
    );
};

export default WrapperPage(BadgesPage, HeaderBadgesPage);
