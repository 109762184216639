import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table, Tag, Space, Button, Breadcrumb, Modal, Result
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
    CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, OrderedListOutlined, UserAddOutlined
} from '@ant-design/icons';
import { Moment } from 'moment';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
import Preloader from '../../components/Preloader';
import AddRun from './AddRun';
import {
    getRunsAsyncAction, removeRunAsyncAction, switchAddModalAction, switchEditModalAction, copyRunAsyncAction, switchParticipantModalAction, switchResultsModalAction
} from './actions';
import EditRun from './EditRun';
import { RunsItem } from './types';
import AddParticipant from './AddParticipant';
import AddResults from './AddResults';

export interface RunFormContextType {
    createItem: RunsItem | null,
    onNext: React.MouseEventHandler<HTMLElement> | undefined
}

const { Column } = Table;

const HeaderRunsPage:React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="page__header">
            <Breadcrumb>
                <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Забеги</Breadcrumb.Item>
            </Breadcrumb>
            <Button type="primary" onClick={() => dispatch(switchAddModalAction())}>Создать забег</Button>
        </div>
    );
};

const RunsPage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const runs = useSelector((state: AppState) => state.runs);

    useEffect(() => {
        dispatch(getRunsAsyncAction());
    }, []);

    const edit = (id: number) => {
        history.push(`/runs/edit/${id}`);
        dispatch(switchEditModalAction());
    };

    const addParticipant = (id: number) => {
        dispatch(switchParticipantModalAction(id));
    };

    const addResults = (id: number) => {
        dispatch(switchResultsModalAction(id));
    };

    const remove = (id: number) => {
        dispatch(removeRunAsyncAction(id));
    };

    const copy = (id: number) => {
        dispatch(copyRunAsyncAction(id));
    };

    if (runs.data === null) {
        return <Preloader />;
    }

    return (
        <section className="runs page">
            <Table dataSource={runs.data} pagination={false} rowKey="id">
                <Column title="Событие" dataIndex="title" render={(value) => value} />
                <Column title="Дата события" dataIndex="date" render={(value: Moment) => value.format('DD.MM.YYYY')} />
                <Column title="Кол-во усчастников" dataIndex="participant" render={(value) => value.length} />
                <Column
                    title="Действия"
                    render={(item: RunsItem) => (
                        <Space size="middle">
                            <Button onClick={() => addResults(item.id)}>
                                <OrderedListOutlined />
                            </Button>
                            <Button onClick={() => addParticipant(item.id)}>
                                <UserAddOutlined />
                            </Button>
                            <Button onClick={() => copy(item.id)}>
                                <CopyOutlined />
                            </Button>
                            <Button onClick={() => edit(item.id)}>
                                <EditOutlined />
                            </Button>
                            <Button danger onClick={() => remove(item.id)}>
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    )}
                />
            </Table>
            <AddRun />
            <EditRun />
            <AddParticipant />
            <AddResults />
        </section>
    );
};

export default WrapperPage(RunsPage, HeaderRunsPage);
