import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row, Col, Form, Input, Select, DatePicker, Typography, TimePicker
} from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';

import { AppState } from '../../../store';
import CustomUpload from '../../../components/CustomUpload';
import { changeStepFormRunsAction, changeStepRunsAction } from '../actions';
import { AddRunFormContext } from '../AddRun';
import { EditRunFormContext } from '../EditRun';
import { API } from '../../..';
import regions from '../../../regions.json';

const { Title } = Typography;

/*
    title: string,
    country: string,
    region: string,
    city: string,
    typeEvent: string,
    date: Moment,
    start: {
        time: Moment,
        place: string
    },
    end: {
        time: Moment,
        place: string
    },
    image: string,
    description: string,
    program: string,
    requirements: string,
    expo: string,
*/

const BaseInfo:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const { createItem } = runs.modals.create ? useContext(AddRunFormContext) : useContext(EditRunFormContext);

    useEffect(() => {
        if (createItem) {
            form.setFields([{ name: 'title', value: createItem.title }]);
            form.setFields([{ name: 'country', value: createItem.country }]);
            form.setFields([{ name: 'region', value: createItem.region }]);
            form.setFields([{ name: 'city', value: createItem.city }]);
            form.setFields([{ name: 'typeEvent', value: createItem.typeEvent }]);
            form.setFields([{ name: 'date', value: createItem.date }]);
            form.setFields([{ name: 'timeStart', value: createItem.start.time }]);
            form.setFields([{ name: 'placeStart', value: createItem.start.place }]);
            form.setFields([{ name: 'timeFinish', value: createItem.finish.time }]);
            form.setFields([{ name: 'placeFinish', value: createItem.finish.place }]);
            form.setFields([{ name: 'coords', value: createItem.coords }]);
            form.setFields([{ name: 'description', value: createItem.description }]);
            form.setFields([{ name: 'program', value: createItem.program }]);
            form.setFields([{ name: 'requirements', value: createItem.requirements }]);
            form.setFields([{ name: 'expo', value: createItem.expo }]);

            if (createItem?.image !== undefined) {
                if (typeof createItem.image === 'string') {
                    const filePath = createItem.image.split('/');
                    const fileName = filePath[filePath.length - 1];
                    form.setFields([{
                        name: 'image',
                        value: [{
                            uid: '0',
                            name: fileName,
                            thumbUrl: `${API}${createItem.image}`
                        }] as UploadFile[]
                    }]);
                } else {
                    form.setFields([{
                        name: 'image',
                        value: [createItem.image] as UploadFile[]
                    }]);
                }
            } else {
                form.setFields([{
                    name: 'image',
                    value: undefined
                }]);
            }
        }
    }, []);

    useEffect(() => {
        if (runs.step === 0) {
            dispatch(changeStepFormRunsAction(form));
        }
    }, [runs.step]);

    const onFinish = (values: any) => {
        if (createItem) {
            Object.keys(values).forEach((key) => {
                switch (key) {
                    case 'title': createItem.title = values[key]; break;
                    case 'country': createItem.country = values[key]; break;
                    case 'region': createItem.region = values[key]; break;
                    case 'city': createItem.city = values[key]; break;
                    case 'typeEvent': createItem.typeEvent = values[key]; break;
                    case 'date': createItem.date = values[key]; break;
                    case 'timeStart': createItem.start.time = values[key]; break;
                    case 'placeStart': createItem.start.place = values[key]; break;
                    case 'timeFinish': createItem.finish.time = values[key]; break;
                    case 'placeFinish': createItem.finish.place = values[key]; break;
                    case 'image': {
                        if (values[key]) {
                            const field: UploadChangeParam | RcFile[] = values[key];
                            const file = Array.isArray(field) ? field[0] : field.fileList[0].originFileObj;
                            createItem.image = file;
                        } else {
                            createItem.image = values[key];
                        }
                        break;
                    }
                    case 'coords': createItem.coords = values[key]; break;
                    case 'description': createItem.description = values[key]; break;
                    case 'program': createItem.program = values[key]; break;
                    case 'requirements': createItem.requirements = values[key]; break;
                    case 'expo': createItem.expo = values[key]; break;
                    default:
                        break;
                }
            });

            if (runs.modals.create) {
                dispatch(changeStepRunsAction('next'));
            }
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={15}>
                <Col lg={24}>
                    <Title level={3}>Шаг 1: Основная информация</Title>
                </Col>
                <Col lg={12}>
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Название"
                    >
                        <Input placeholder="Введите название" />
                    </Form.Item>
                    <Form.Item
                        name="country"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Место"
                    >
                        <Select placeholder="Выберите страну">
                            <Select.Option value="russia">Россия</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="region"
                        rules={[{ required: false }]}
                    >
                        <Select placeholder="Выберите регион">
                            {regions.map((item) => (
                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="city"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                    >
                        <Input placeholder="Город" />
                    </Form.Item>
                    <Form.Item
                        name="typeEvent"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Тип события"
                    >
                        <Select
                            mode="multiple"
                            placeholder="Выберите тип"
                        >
                            <Select.Option value="parkrun">Парковый забег</Select.Option>
                            <Select.Option value="festival">Фестиваль</Select.Option>
                            <Select.Option value="run">Забег</Select.Option>
                            <Select.Option value="virtual">Виртуальное</Select.Option>
                            <Select.Option value="kids">Детское</Select.Option>
                            <Select.Option value="free">Бесплатное</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="date"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Дата"
                    >
                        <DatePicker
                            placeholder="Выберите дату события"
                            style={{ width: '100%' }}
                            format="DD.MM.YYYY"
                        />
                    </Form.Item>
                    <Row gutter={15}>
                        <Col lg={12}>
                            <Form.Item
                                name="timeStart"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Старт"
                            >
                                <DatePicker
                                    format="DD.MM.YYYY HH:mm"
                                    placeholder="Время"
                                    showTime
                                    minuteStep={5}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="placeStart"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                            >
                                <Input placeholder="Место" />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                name="timeFinish"
                                label="Финиш"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Заполните поле'
                                    },
                                    {
                                        message: 'Финиш меньше или равен старту',
                                        validator: (_, valueEnd) => {
                                            const valueStart = form.getFieldValue('timeStart');
                                            if (valueEnd && valueEnd !== undefined && valueStart && valueStart !== undefined) {
                                                const end = valueEnd as Moment;
                                                const start = valueStart as Moment;

                                                if (+end.format('x') <= +start.format('x')) {
                                                    return Promise.reject();
                                                }

                                                return Promise.resolve();
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <DatePicker
                                    format="DD.MM.YYYY HH:mm"
                                    placeholder="Время"
                                    showTime
                                    minuteStep={5}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="placeFinish"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                            >
                                <Input placeholder="Место" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Координаты события"
                        name="coords"
                        rules={[{ required: false, message: 'Заполните поле' }]}
                    >
                        <Input placeholder="59.213532, 39.885053" />
                    </Form.Item>
                    <Form.Item
                        name="image"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Лейбл"
                    >
                        <CustomUpload
                            form={form}
                            name="image"
                            listType="picture-card"
                        />
                    </Form.Item>
                </Col>
                <Col lg={12}>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: 'Введите текст' }]}
                        label="Описание"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="program"
                        rules={[{ required: true, message: 'Введите текст' }]}
                        label="Программа"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="requirements"
                        rules={[{ required: true, message: 'Введите текст' }]}
                        label="Требования к участникам"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="expo"
                        rules={[{ required: true, message: 'Введите текст' }]}
                        label="Экспо"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BaseInfo;
