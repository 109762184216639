import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import { Locale } from 'antd/lib/locale-provider';
import 'moment/locale/ru';
import moment from 'moment';

import './index.scss';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.less';
import { ruLocalePicker } from './utils';

moment.locale('ru');

const locale: Locale = {
    ...ruRU,
    DatePicker: {
        ...ruRU.DatePicker,
        lang: ruLocalePicker.lang,
        timePickerLocale: ruLocalePicker.timePickerLocale
    },
    Calendar: {
        ...ruRU.DatePicker,
        lang: ruLocalePicker.lang,
        timePickerLocale: ruLocalePicker.timePickerLocale
    }
};

export const API = process.env.NODE_ENV === 'development' ? 'http://athletica.local/' : 'https://account.athletica.space/';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider
            locale={locale}
        >
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
