import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload, InputNumber
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AppState } from '../../store';
import { addCupAsyncAction, switchAddModalAction } from './actions';
import { AddCupForm } from './types';

const AddCup:React.FC = () => {
    const dispatch = useDispatch();
    const cups = useSelector((state: AppState) => state.cups);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!cups.modals.create) {
            form.resetFields();
        }
    }, [cups.modals.create]);

    const onSend = (values: AddCupForm) => {
        dispatch(addCupAsyncAction(values));
    };

    return (
        <Modal
            title="Добавить кубок"
            centered
            visible={cups.modals.create}
            onCancel={() => dispatch(switchAddModalAction())}
            width={1150}
            forceRender
            footer={[
                <Button
                    key="send"
                    type="primary"
                    onClick={() => form.submit()}
                    loading={cups.loading}
                >
                    Создать
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSend}
            >
                <Row gutter={15}>
                    <Col lg={12}>
                        <Form.Item
                            name="title"
                            rules={[{ required: true, message: 'Введите название' }]}
                            label="Название"
                        >
                            <Input placeholder="Введите название" />
                        </Form.Item>
                        <Form.Item
                            name="conditions"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Условия"
                        >
                            <Input.TextArea placeholder="Текст условий" rows={5} />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item
                            name="period"
                            label="Период"
                            rules={[{ required: true, message: 'Выберите период' }]}
                        >
                            <DatePicker.RangePicker
                                placeholder={['Начало', 'Конец']}
                                style={{ width: '100%' }}
                                format="DD.MM.YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="tasks"
                            rules={[{ required: true, message: 'Выберите задания' }]}
                            label="Задания для кубка"
                        >
                            <Select
                                mode="multiple"
                                placeholder="Выберите задания"
                            >
                                {cups.tasks ? cups.tasks.map((task) => (
                                    <Select.Option value={task.id} key={task.id}>{task.title}</Select.Option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddCup;
