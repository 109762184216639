/* eslint-disable camelcase */
import { FormInstance } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Moment } from 'moment';

// LISTS

// export type StepStatuses = 'success' | 'validating' | 'editing' | 'error' | 'none';

// DATA ITEMS TYPES //

export interface DistanceItem {
    name: string | undefined,
    mileage: number | undefined,
    slots: number | undefined,
    startTime: Moment | undefined,
    finishTime: Moment | undefined,
    categories: string[] | undefined,
    prizeCategories: string | undefined,
    foodOutlets: number | undefined,
    description: string | undefined,
    requirements: string | undefined,
    routeText: string | undefined,
    routeJpx: RcFile | string | undefined,
    startCoords: string | undefined,
    [key: string]: string | string[] | number | Moment | RcFile | undefined;
}

export interface PaymentItem {
    distanceKey: string,
    basePrice: number | undefined,
    priceByDate: PaymentPriceByDateItem[],
    priceBySlots: PaymentPriceBySlotsItem[],
    [key: string]: number | string | PaymentPriceByDateItem[] | PaymentPriceBySlotsItem[] | undefined
}

export interface PaymentPriceByDateItem {
    date: Moment | undefined,
    price: number | undefined,
    [key: string]: Moment | number | undefined
}

export interface PaymentPriceBySlotsItem {
    slots: number | undefined,
    price: number | undefined,
    [key: string]: number | undefined
}

export interface NumberingDistanceItem {
    distanceKey: string,
    range: string | undefined,
    gender: string[],
    exceptions: string | undefined,
    [key: string]: string | string[] | undefined
}

export interface RunsItem extends RunsStepBaseInfo, RunsStepContacts, RunsStepDistance, RunsStepPayment, RunsStepRegistration {
    id: number,
    participant: ParticipantItem[]
}

export interface RunsItemResponse {
    id: number,
    city: string,
    country: string,
    date: string,
    description: string,
    distances: string,
    expo: string,
    finish: string,
    howToGet: string,
    image: string,
    numbering: string,
    organizerAttachments: string,
    organizerEmail: string,
    organizerName: string,
    organizerPhone: string,
    organizerSite: string,
    otherInfo: string,
    payments: string,
    program: string,
    region: string,
    requirements: string,
    start: string,
    title: string,
    typeEvent: string,
    whereToLive: string,
    registrationType: 'none' | 'athletica' | 'other',
    registrationSite: string,
    participant: ParticipantItem[],
    coords: string
}

export interface RunsUsersItem {
    id: number,
    name: string
}

export interface AddParticipantForm {
    typeParticipant: number,
    run: number,
    distanceKey: number,
    user?: number,
    name?: string,
    birthday?: Moment
}

export interface AddResultsForm {
    id: number,
    time: string | null
}

export interface NumberingType {
    requireFields: string[] | undefined,
    numberingType: string | undefined,
    numberingDistances?: NumberingDistanceItem[] | undefined
}

export interface ParticipantItem {
    id: number,
    run_id: number,
    user_id: number | null,
    distance_key: number | null,
    name: string | null,
    birthday: string | null,
    time: string | null
}

// STEPS //

export interface RunsStepBaseInfo {
    title: string | undefined,
    country: string | undefined,
    region: string | undefined,
    city: string | undefined,
    typeEvent: string[] | undefined,
    date: Moment | undefined,
    start: {
        time: Moment | undefined,
        place: string | undefined
    },
    finish: {
        time: Moment | undefined,
        place: string | undefined
    },
    coords: string | undefined,
    image: RcFile | string | undefined,
    description: string | undefined,
    program: string | undefined,
    requirements: string | undefined,
    expo: string | undefined
}

export interface RunsStepContacts {
    organizer: {
        name: string,
        email: string | undefined,
        phone: string | undefined,
        site: string | undefined,
        attachments: RcFile[] | string[] | undefined
    },
    howToGet: string | undefined,
    whereToLive: string | undefined,
    otherInfo: string | undefined
}

export interface RunsStepDistance {
    distances: DistanceItem[]
}

export interface RunsStepPayment {
    payments: PaymentItem[]
}

export interface RunsStepRegistration {
    registrationType: 'none' | 'athletica' | 'other',
    registrationSite: string | undefined,
    numbering: NumberingType
}

// STORE TYPE //

export interface RunsStateType {
    data: RunsItem[] | null,
    step: number,
    stepForm: FormInstance | null,
    modals: {
        create: boolean,
        edit: boolean,
        addParticipant: {
            visible: boolean,
            runID: number,
            loading: boolean,
            users: RunsUsersItem[] | null
        },
        addResults: {
            visible: boolean,
            runID: number,
            loading: boolean
        }
    }
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum RunsActionsList {
    ERROR_RUNS = 'ERROR_RUNS',
    MODAL_ADD_RUNS = 'MODAL_ADD_RUNS',
    MODAL_EDIT_RUNS = 'MODAL_EDIT_RUNS',
    MODAL_PARTICIPANT_RUNS = 'MODAL_PARTICIPANT_RUNS',
    MODAL_RESULTS_RUNS = 'MODAL_RESULTS_RUNS',
    CHANGE_STEP_RUNS = 'CHANGE_STEP_RUNS',
    CHANGE_STEP_FORM_RUNS = 'CHANGE_STEP_FORM_RUNS',
    REQUEST_GET_RUNS = 'REQUEST_GET_RUNS',
    RESPONSE_GET_RUNS = 'RESPONSE_GET_RUNS',
    REQUEST_GET_RUNS_USERS = 'REQUEST_GET_RUNS_USERS',
    RESPONSE_GET_RUNS_USERS = 'RESPONSE_GET_RUNS_USERS',
    REQUEST_ADD_RUN = 'REQUEST_ADD_RUN',
    RESPONSE_ADD_RUN = 'RESPONSE_ADD_RUN',
    REQUEST_EDIT_RUN = 'REQUEST_EDIT_RUN',
    RESPONSE_EDIT_RUN = 'RESPONSE_EDIT_RUN',
    REQUEST_REMOVE_RUN = 'REQUEST_REMOVE_RUN',
    RESPONSE_REMOVE_RUN = 'RESPONSE_REMOVE_RUN',
    REQUEST_COPY_RUN = 'REQUEST_COPY_RUN',
    RESPONSE_COPY_RUN = 'RESPONSE_COPY_RUN',
    REQUEST_ADD_PARTICIPANT_RUN = 'REQUEST_ADD_PARTICIPANT_RUN',
    RESPONSE_ADD_PARTICIPANT_RUN = 'RESPONSE_ADD_PARTICIPANT_RUN',
    REQUEST_ADD_RESULTS_RUN = 'REQUEST_ADD_RESULTS_RUN',
    RESPONSE_ADD_RESULTS_RUN = 'RESPONSE_ADD_RESULTS_RUN',
}

// ERROR //

export interface ErrorRunsActionType {
    type: RunsActionsList.ERROR_RUNS,
    error: string
}

// SWITCH MODALS //

export interface SwitchModalAddActionType {
    type: RunsActionsList.MODAL_ADD_RUNS
}

export interface SwitchModalEditActionType {
    type: RunsActionsList.MODAL_EDIT_RUNS
}

export interface SwitchModalParticipantActionType {
    type: RunsActionsList.MODAL_PARTICIPANT_RUNS,
    runID: number
}

export interface SwitchModalResultsActionType {
    type: RunsActionsList.MODAL_RESULTS_RUNS,
    runID: number
}

// CHANGE STEP //

export interface ChangeStepRunsActionType {
    type: RunsActionsList.CHANGE_STEP_RUNS,
    direction: 'prev' | 'next' | 'step',
    step?: number
}

// CHANGE STEP STATUS //

export interface ChangeStepFormRunsActionType {
    type: RunsActionsList.CHANGE_STEP_FORM_RUNS,
    form: FormInstance
}

// GET NEWS TYPES //

export interface RequestGetRunsActionType {
    type: RunsActionsList.REQUEST_GET_RUNS
}

export interface ResponseGetRunsActionType {
    type: RunsActionsList.RESPONSE_GET_RUNS,
    data: RunsItem[]
}

// ADD RUN TYPES //

export interface RequestAddRunActionType {
    type: RunsActionsList.REQUEST_ADD_RUN
}

export interface ResponseAddRunActionType {
    type: RunsActionsList.RESPONSE_ADD_RUN,
    data: RunsItem
}

// EDIT RUN TYPES //

export interface RequestEditRunActionType {
    type: RunsActionsList.REQUEST_EDIT_RUN
}

export interface ResponseEditRunActionType {
    type: RunsActionsList.RESPONSE_EDIT_RUN,
    data: RunsItem
}

// REMOVE RUN TYPES //

export interface RequestRemoveRunActionType {
    type: RunsActionsList.REQUEST_REMOVE_RUN
}

export interface ResponseRemoveRunActionType {
    type: RunsActionsList.RESPONSE_REMOVE_RUN,
    id: number
}

// COPY RUN TYPES //

export interface RequestCopyRunActionType {
    type: RunsActionsList.REQUEST_COPY_RUN
}

export interface ResponseCopyRunActionType {
    type: RunsActionsList.RESPONSE_COPY_RUN,
    id: number,
    newID: number
}

// GET RUNS USERS //

export interface RequestGetRunsUsersActionType {
    type: RunsActionsList.REQUEST_GET_RUNS_USERS
}

export interface ResponseGetRunsUsersActionType {
    type: RunsActionsList.RESPONSE_GET_RUNS_USERS,
    data: RunsUsersItem[]
}

// ADD RUNS USERS //

export interface RequestAddParticipantRunActionType {
    type: RunsActionsList.REQUEST_ADD_PARTICIPANT_RUN
}

export interface ResponseAddParticipantRunActionType {
    type: RunsActionsList.RESPONSE_ADD_PARTICIPANT_RUN,
    data: ParticipantItem
}

// ADD RUNS RESULTS //

export interface RequestAddResultsRunActionType {
    type: RunsActionsList.REQUEST_ADD_RESULTS_RUN
}

export interface ResponseAddResultsRunActionType {
    type: RunsActionsList.RESPONSE_ADD_RESULTS_RUN,
    data: {
        results: AddResultsForm[],
        runID: number
    }
}

export type RunsActionsType =
ErrorRunsActionType
| SwitchModalAddActionType
| SwitchModalEditActionType
| SwitchModalParticipantActionType
| SwitchModalResultsActionType
| ChangeStepRunsActionType
| ChangeStepFormRunsActionType
| RequestAddRunActionType
| ResponseAddRunActionType
| RequestGetRunsActionType
| ResponseGetRunsActionType
| RequestEditRunActionType
| ResponseEditRunActionType
| RequestRemoveRunActionType
| ResponseRemoveRunActionType
| RequestCopyRunActionType
| ResponseCopyRunActionType
| RequestGetRunsUsersActionType
| ResponseGetRunsUsersActionType
| RequestAddParticipantRunActionType
| ResponseAddParticipantRunActionType
| RequestAddResultsRunActionType
| ResponseAddResultsRunActionType;
