import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AppState } from '../../store';
import { addNewAsyncAction, switchAddModalAction } from './actions';
import { AddNewFormData } from './types';

const AddNew:React.FC = () => {
    const dispatch = useDispatch();
    const news = useSelector((state: AppState) => state.news);
    const [form] = Form.useForm();
    const [previewModal, setPreviewModal] = useState({
        image: '',
        visible: false,
        title: ''
    });
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

    useEffect(() => {
        if (news.modals.create) {
            form.setFieldsValue({
                publish: moment()
            });
        } else {
            form.resetFields();
        }
    }, [news.modals.create]);

    const handlePreview = async (file: UploadFile<unknown>) => {
        if (file.thumbUrl) {
            setPreviewModal({
                image: file.thumbUrl,
                title: file.name,
                visible: true
            });
        }
    };

    const onSend = (values: AddNewFormData) => {
        dispatch(addNewAsyncAction({
            ...values,
            image: fileList.length && fileList[0].originFileObj ? fileList[0].originFileObj : null
        }));
    };

    return (
        <>
            <Modal
                title="Добавить новость"
                centered
                visible={news.modals.create}
                onCancel={() => dispatch(switchAddModalAction())}
                width={1150}
                forceRender
                footer={[
                    <Button
                        key="send"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={news.loading}
                    >
                        Опубликовать
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSend}
                >
                    <Row gutter={15}>
                        <Col lg={12}>
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: 'Введите название' }]}
                                label="Название"
                            >
                                <Input placeholder="Введите название" />
                            </Form.Item>
                            <Form.Item
                                name="subtitle"
                                rules={[{ required: true, message: 'Введите подзаголовок' }]}
                                label="Подзаголовок"
                            >
                                <Input placeholder="Введите ползаголовок" />
                            </Form.Item>
                            <Form.Item
                                name="publish"
                                label="Дата"
                            >
                                <DatePicker
                                    placeholder="Выберите дату публикации"
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item
                                name="image"
                                label="Изображение новости"
                            >
                                <Upload
                                    accept=".jpg,.jpeg,.png"
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={(info: UploadChangeParam) => setFileList([...fileList, ...info.fileList])}
                                    onRemove={() => { setFileList([]); return false; }}
                                >
                                    {fileList.length < 1 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Загрузить</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                name="categories"
                                rules={[{ required: true, message: 'Выберите хотя бы одну категорию' }]}
                                label="Категория"
                                tooltip="Выберите одну или несколько категорий"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Категория"
                                >
                                    <Select.Option value="runs">Забеги</Select.Option>
                                    <Select.Option value="cup">Кубок</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="text"
                                rules={[{ required: true, message: 'Введите текст новости' }]}
                                label="Текст новости"
                            >
                                <Input.TextArea placeholder="Введите текст новости" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                visible={previewModal.visible}
                title={previewModal.title}
                footer={null}
                onCancel={() => setPreviewModal({ ...previewModal, visible: false })}
                centered
            >
                <img style={{ width: '100%' }} src={previewModal.image} alt={previewModal.title} />
            </Modal>
        </>
    );
};

export default AddNew;
