import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload, InputNumber
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AppState } from '../../store';
import { addTaskAsyncAction, switchAddModalAction } from './actions';
import { AddTaskForm } from './types';

const AddTask:React.FC = () => {
    const dispatch = useDispatch();
    const tasks = useSelector((state: AppState) => state.tasks);
    const [form] = Form.useForm();
    const [conditionsType, setConditionsType] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!tasks.modals.create) {
            form.resetFields();
        }
    }, [tasks.modals.create]);

    useEffect(() => {
        form.resetFields(['conditionsValue']);
    }, [conditionsType]);

    const onSend = (values: AddTaskForm) => {
        dispatch(addTaskAsyncAction(values));
    };

    return (
        <Modal
            title="Добавить задание кубка"
            centered
            visible={tasks.modals.create}
            onCancel={() => dispatch(switchAddModalAction())}
            width={1150}
            forceRender
            footer={[
                <Button
                    key="send"
                    type="primary"
                    onClick={() => form.submit()}
                    loading={tasks.loading}
                >
                    Создать
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSend}
            >
                <Row gutter={15}>
                    <Col lg={12}>
                        <Form.Item
                            name="title"
                            rules={[{ required: true, message: 'Введите название' }]}
                            label="Название"
                        >
                            <Input placeholder="Введите название" />
                        </Form.Item>
                        <Form.Item
                            name="period"
                            label="Период"
                            rules={[{ required: true, message: 'Выберите период' }]}
                        >
                            <DatePicker.RangePicker
                                placeholder={['Начало', 'Конец']}
                                style={{ width: '100%' }}
                                format="DD.MM.YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="category"
                            rules={[{ required: true, message: 'Выберите категорию' }]}
                            label="Категория"
                        >
                            <Select
                                placeholder="Выберите категорию"
                            >
                                <Select.Option value="single">Одиночное</Select.Option>
                                <Select.Option value="command">Командное</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item
                            name="conditionsType"
                            rules={[{ required: true, message: 'Выберите тип условия' }]}
                            label="Тип условия"
                        >
                            <Select
                                placeholder="Выберите тип условия"
                                onChange={(value: string) => {
                                    form.resetFields(['conditionsValue']);
                                    setConditionsType(value);
                                }}
                            >
                                <Select.Option value="distance">Дистанция</Select.Option>
                                <Select.Option value="time">Время</Select.Option>
                            </Select>
                        </Form.Item>
                        {conditionsType === 'distance' && (
                            <Form.Item
                                name="conditionsValue"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Дистанция, км"
                            >
                                <InputNumber min={1} placeholder="Введите дистанцию" style={{ width: '100%' }} />
                            </Form.Item>
                        )}
                        {conditionsType === 'time' && (
                            <Form.Item
                                name="conditionsValue"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Время, мин"
                            >
                                <InputNumber min={1} placeholder="Введите время" style={{ width: '100%' }} />
                            </Form.Item>
                        )}
                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Описание"
                        >
                            <Input.TextArea placeholder="Текст описания" rows={5} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddTask;
