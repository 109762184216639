import React from 'react';
import { useSelector } from 'react-redux';
import {
    Route,
    Redirect,
    RouteProps
} from 'react-router-dom';
import { AppState } from '../../store';
import { AccountsType } from '../../pages/Auth/types';

interface PrivateRouteProps extends RouteProps {
    access: AccountsType | null
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const auth = useSelector((state: AppState) => state.auth);
    const { path, access } = props;
    if (!auth.data) {
        return <Route {...props} component={() => <Redirect to={{ pathname: '/sign-in' }} />} />;
    }
    // if (auth.data && auth.data.token) {
    //     return <Route {...props} component={() => <Redirect to={{ pathname: '/' }} />} />;
    // }
    if (access && access !== auth.data.role as AccountsType) {
        return <Route {...props} component={() => <Redirect to={{ pathname: '/403' }} />} />;
    }
    if (auth.data && auth.data.role && path === '/') {
        switch (auth.data.role) {
            case 'admin': return <Route {...props} component={() => <Redirect to={{ pathname: '/news' }} />} />;
            case 'user': return <Route {...props} component={() => <Redirect to={{ pathname: '/profile' }} />} />;
            default: return <Route {...props} component={() => <Redirect to={{ pathname: '/404' }} />} />;
        }
    }
    return <Route {...props} />;
};

export default PrivateRoute;
