import { RcFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';

// DATA ITEMS TYPES //

export type ConditionsTypes = 'run' | 'time';
export type CategoryTypes = 'single' | 'command';
export interface TaskCondition {
    type: ConditionsTypes,
    value: number
}

export interface TaskItem {
    id: number,
    title: string,
    period: Moment[],
    category: CategoryTypes,
    conditions: TaskCondition,
    description: string
}

// DATA FROM SERVER //

export interface TaskItemFromServer {
    id: number,
    title: string,
    category: CategoryTypes,
    periodStart: string,
    periodEnd: string,
    conditions: string,
    description: string
}

// DATA FORMS TYPES //

export interface AddTaskForm {
    title: string | undefined,
    period: Moment[] | undefined,
    category: CategoryTypes | undefined,
    conditionsType: ConditionsTypes | undefined,
    conditionsValue: number | undefined,
    description: string | undefined
}

export interface EditTaskForm {
    id: number,
    title: string | undefined,
    period: Moment[] | undefined,
    category: CategoryTypes | undefined,
    conditionsType: ConditionsTypes | undefined,
    conditionsValue: number | undefined,
    description: string | undefined
}

// STORE TYPE //

export interface TasksStateType {
    data: TaskItem[] | null,
    modals: {
        create: boolean,
        edit: boolean
    }
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum TasksActionsList {
    ERROR_TASKS = 'ERROR_TASKS',
    REQUEST_GET_TASKS = 'REQUEST_GET_TASKS',
    RESPONSE_GET_TASKS = 'RESPONSE_GET_TASKS',
    REQUEST_ADD_TASKS = 'REQUEST_ADD_TASKS',
    RESPONSE_ADD_TASKS = 'RESPONSE_ADD_TASKS',
    REQUEST_EDIT_TASKS = 'REQUEST_EDIT_TASKS',
    RESPONSE_EDIT_TASKS = 'RESPONSE_EDIT_TASKS',
    REQUEST_REMOVE_TASKS = 'REQUEST_REMOVE_TASKS',
    RESPONSE_REMOVE_TASKS = 'RESPONSE_REMOVE_TASKS',
    MODAL_ADD_TASKS = 'MODAL_ADD_TASKS',
    MODAL_EDIT_TASKS = 'MODAL_EDIT_TASKS'
}

// ERROR //

export interface ErrorTasksActionType {
    type: TasksActionsList.ERROR_TASKS,
    error: string
}

// GET TYPES //

export interface RequestGetTasksActionType {
    type: TasksActionsList.REQUEST_GET_TASKS
}

export interface ResponseGetTasksActionType {
    type: TasksActionsList.RESPONSE_GET_TASKS,
    data: TaskItem[]
}

// ADD TYPES //

export interface RequestAddTaskActionType {
    type: TasksActionsList.REQUEST_ADD_TASKS
}

export interface ResponseAddTaskActionType {
    type: TasksActionsList.RESPONSE_ADD_TASKS,
    data: TaskItem
}

// EDIT TYPES //

export interface RequestEditTaskActionType {
    type: TasksActionsList.REQUEST_EDIT_TASKS
}

export interface ResponseEditTaskActionType {
    type: TasksActionsList.RESPONSE_EDIT_TASKS,
    data: TaskItem
}

// REMOVE TYPES //

export interface RequestRemoveTaskActionType {
    type: TasksActionsList.REQUEST_REMOVE_TASKS
}

export interface ResponseRemoveTaskActionType {
    type: TasksActionsList.RESPONSE_REMOVE_TASKS,
    id: number
}

// SWITCH MODALS //

export interface SwitchModalAddActionType {
    type: TasksActionsList.MODAL_ADD_TASKS
}

export interface SwitchModalEditActionType {
    type: TasksActionsList.MODAL_EDIT_TASKS
}

export type TasksActionsType =
ErrorTasksActionType
| RequestGetTasksActionType
| ResponseGetTasksActionType
| RequestAddTaskActionType
| ResponseAddTaskActionType
| RequestEditTaskActionType
| ResponseEditTaskActionType
| RequestRemoveTaskActionType
| ResponseRemoveTaskActionType
| SwitchModalAddActionType
| SwitchModalEditActionType;
