import axios from 'axios';
import {
    AccountData,
    AuthActionsList,
    AuthActionsType,
    AuthStateType
} from './types';

let initialData: AccountData | null = null;

if (localStorage.getItem('token')) {
    initialData = {
        id: null,
        role: null,
        login: '',
        token: localStorage.getItem('token') as string
    };
    axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('token')}` };
} else {
    axios.defaults.headers.common = { Authorization: 'Bearer ' };
}

export const initialState: AuthStateType = {
    data: initialData,
    loading: false,
    error: null
};

const authReducer = (state: AuthStateType = initialState, action: AuthActionsType) : AuthStateType => {
    switch (action.type) {
        case AuthActionsList.ERROR_AUTH: {
            localStorage.removeItem('token');
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case AuthActionsList.REQUEST_SIGNIN_AUTH: {
            return {
                ...state,
                loading: true
            };
        }
        case AuthActionsList.RESPONSE_SIGNIN_AUTH: {
            localStorage.setItem('token', action.data.token);
            axios.defaults.headers.common = { Authorization: `Bearer ${action.data.token}` };
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case AuthActionsList.LOGOUT_AUTH: {
            localStorage.removeItem('token');
            axios.defaults.headers.common = { Authorization: 'Bearer ' };
            return {
                ...state,
                data: null,
                loading: false,
                error: null
            };
        }
        default:
            return state;
    }
};

export default authReducer;
