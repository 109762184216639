import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import { TaskCondition, TaskItem, TaskItemFromServer } from '../Tasks/types';
import {
    AddCupForm, CupItem, CupItemFromServer, CupsActionsList, CupsActionsType, CupsStateType, EditCupForm
} from './types';

export const errorCupAction = (error: string): CupsActionsType => ({
    type: CupsActionsList.ERROR_CUPS,
    error
});

// MODALS ACTIONS //

export const switchAddModalAction = (): CupsActionsType => ({
    type: CupsActionsList.MODAL_ADD_CUPS
});

export const switchEditModalAction = (): CupsActionsType => ({
    type: CupsActionsList.MODAL_EDIT_CUPS
});

// ADD ACTIONS //

export const requestAddCupAction = (): CupsActionsType => ({
    type: CupsActionsList.REQUEST_ADD_CUPS
});

export const responseAddCupAction = (data: CupItem): CupsActionsType => ({
    type: CupsActionsList.RESPONSE_ADD_CUPS,
    data
});

export const addCupAsyncAction = (data: AddCupForm) => async (dispatch: ThunkDispatch<CupsStateType, void, Action>): Promise<void> => {
    dispatch(requestAddCupAction());

    const formData = new FormData();

    formData.append('title', data.title || '');
    formData.append('periodStart', data.period ? data.period[0].format('X') : '');
    formData.append('periodEnd', data.period ? data.period[1].format('X') : '');
    formData.append('conditions', data.conditions || '');
    formData.append('tasks', JSON.stringify(data.tasks));

    const result = await axios.post(`${API}api/v1/cups`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorCupAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const cupData: CupItem = {
            id: response.data.id,
            title: data.title || '',
            period: data.period || [moment(), moment()],
            conditions: data.conditions || '',
            tasks: data.tasks || []
        };

        dispatch(responseAddCupAction(cupData));
        notification('success', 'Успех', 'Кубок добавлен');
    }
};

// EDIT ACTIONS //

export const requestEditCupAction = (): CupsActionsType => ({
    type: CupsActionsList.REQUEST_EDIT_CUPS
});

export const responseEditCupAction = (data: CupItem): CupsActionsType => ({
    type: CupsActionsList.RESPONSE_EDIT_CUPS,
    data
});

export const editCupAsyncAction = (data: EditCupForm) => async (dispatch: ThunkDispatch<CupsStateType, void, Action>): Promise<void> => {
    dispatch(requestEditCupAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('title', data.title || '');
    formData.append('periodStart', data.period ? data.period[0].format('X') : '');
    formData.append('periodEnd', data.period ? data.period[1].format('X') : '');
    formData.append('conditions', data.conditions || '');
    formData.append('tasks', JSON.stringify(data.tasks));

    const result = await axios.post(`${API}api/v1/cups/edit`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorCupAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const cupData: CupItem = {
            id: response.data.id,
            title: data.title || '',
            period: data.period || [moment(), moment()],
            conditions: data.conditions || '',
            tasks: data.tasks || []
        };

        dispatch(responseEditCupAction(cupData));
        notification('success', 'Успех', 'Кубок обновлен');
    }
};

// REMOVE ACTIONS //

export const requestRemoveCupAction = (): CupsActionsType => ({
    type: CupsActionsList.REQUEST_REMOVE_CUPS
});

export const responseRemoveCupAction = (id: number): CupsActionsType => ({
    type: CupsActionsList.RESPONSE_REMOVE_CUPS,
    id
});

export const removeCupAsyncAction = (id: number) => async (dispatch: ThunkDispatch<CupsStateType, void, Action>): Promise<void> => {
    dispatch(requestRemoveCupAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/cups/remove`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorCupAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseRemoveCupAction(id));
        notification('success', 'Успех', 'Задание удалено');
    }
};

// GET ACTIONS //

export const requestGetCupsAction = (): CupsActionsType => ({
    type: CupsActionsList.REQUEST_GET_CUPS
});

export const responseGetCupsAction = (data: CupItem[], tasks: TaskItem[]): CupsActionsType => ({
    type: CupsActionsList.RESPONSE_GET_CUPS,
    data,
    tasks
});

export const getCupsAsyncAction = () => async (dispatch: ThunkDispatch<CupsStateType, void, Action>): Promise<void> => {
    dispatch(requestGetCupsAction());

    const result = await axios.get(`${API}api/v1/cups`);
    const response = result.data;

    if (response.error) {
        dispatch(errorCupAction(response.message));
    } else {
        let cups: CupItem[] | [] = [];
        let tasks: TaskItem[] | [] = [];
        const cupsResponse = response.data as CupItemFromServer[];
        const tasksResponse = response.tasks as TaskItemFromServer[];
        cupsResponse.forEach((item: CupItemFromServer) => {
            cups = [...cups, {
                id: item.id,
                title: item.title,
                period: [moment(item.periodStart), moment(item.periodEnd)],
                conditions: item.conditions,
                tasks: JSON.parse(item.tasks).map((id: string) => +id)
            }];
        });
        tasksResponse.forEach((item: TaskItemFromServer) => {
            const conditionsFromServer = JSON.parse(item.conditions);
            const conditions: TaskCondition = {
                type: conditionsFromServer.type,
                value: +conditionsFromServer.value
            };

            tasks = [...tasks, {
                id: item.id,
                title: item.title,
                period: [moment(item.periodStart), moment(item.periodEnd)],
                category: item.category,
                conditions,
                description: item.description
            }];
        });

        dispatch(responseGetCupsAction(cups, tasks));
    }
};
