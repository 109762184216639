import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table, Tag, Space, Button, Breadcrumb, Modal, Result
} from 'antd';
import moment, { Moment } from 'moment';
import {
    CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, HomeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';

import AddCup from './AddCup';
import EditCup from './EditCup';
import Preloader from '../../components/Preloader';
import { CupItem } from './types';
import {
    getCupsAsyncAction, removeCupAsyncAction, switchAddModalAction, switchEditModalAction
} from './actions';

const { Column } = Table;

const HeaderCupsPage:React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="page__header">
            <Breadcrumb>
                <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Кубки</Breadcrumb.Item>
            </Breadcrumb>
            <Button type="primary" onClick={() => dispatch(switchAddModalAction())}>Добавить кубок</Button>
        </div>
    );
};

const CupsPage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cups = useSelector((state: AppState) => state.cups);

    useEffect(() => {
        dispatch(getCupsAsyncAction());
    }, []);

    const edit = (id: number) => {
        history.push(`/cups/edit/${id}`);
        dispatch(switchEditModalAction());
    };

    const confirm = (id: number) => {
        Modal.confirm({
            title: '',
            icon: null,
            content: (
                <Result
                    status="warning"
                    title="Вы уверены что хотите удалить кубок?"
                />
            ),
            okText: 'Подтвердить',
            cancelText: 'Отменить',
            centered: true,
            width: 685,
            className: 'app__confirm',
            onOk: () => dispatch(removeCupAsyncAction(id))
        });
    };

    if (cups.data === null) {
        return <Preloader />;
    }

    return (
        <section className="badges page">
            <Table dataSource={cups.data || undefined} pagination={false} rowKey="id">
                <Column title="Название" dataIndex="title" />
                <Column
                    title="Период"
                    dataIndex="period"
                    render={(value: Moment[]) => (value ? `${value[0].format('DD.MM.YYYY')} - ${value[1].format('DD.MM.YYYY')}` : null)}
                />
                <Column
                    title="Действия"
                    render={(item: CupItem) => (
                        <Space size="middle">
                            <Button onClick={() => edit(item.id)}>
                                <EditOutlined />
                            </Button>
                            <Button danger onClick={() => confirm(item.id)}>
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    )}
                />
            </Table>
            <AddCup />
            <EditCup />
        </section>
    );
};

export default WrapperPage(CupsPage, HeaderCupsPage);
