import { FormInstance } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    RunsStateType,
    RunsActionsType,
    RunsActionsList,
    RunsItem,
    RunsItemResponse,
    DistanceItem,
    NumberingType,
    NumberingDistanceItem,
    PaymentItem,
    PaymentPriceByDateItem,
    PaymentPriceBySlotsItem,
    RunsUsersItem,
    AddParticipantForm,
    AddResultsForm,
    ParticipantItem
} from './types';

export const errorRunsAction = (error: string): RunsActionsType => ({
    type: RunsActionsList.ERROR_RUNS,
    error
});

// MODALS ACTIONS //

export const switchAddModalAction = (): RunsActionsType => ({
    type: RunsActionsList.MODAL_ADD_RUNS
});

export const switchEditModalAction = (): RunsActionsType => ({
    type: RunsActionsList.MODAL_EDIT_RUNS
});

export const switchParticipantModalAction = (runID: number): RunsActionsType => ({
    type: RunsActionsList.MODAL_PARTICIPANT_RUNS,
    runID
});

export const switchResultsModalAction = (runID: number): RunsActionsType => ({
    type: RunsActionsList.MODAL_RESULTS_RUNS,
    runID
});

// CHANGE STEP //

export const changeStepRunsAction = (direction: 'prev' | 'next' | 'step', step?: number): RunsActionsType => ({
    type: RunsActionsList.CHANGE_STEP_RUNS,
    direction,
    step
});

// CHANGE STEP //

export const changeStepFormRunsAction = (form: FormInstance): RunsActionsType => ({
    type: RunsActionsList.CHANGE_STEP_FORM_RUNS,
    form
});

// ADD RUN ACTIONS //

export const requestAddRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_ADD_RUN
});

export const responseAddRunAction = (data: RunsItem): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_ADD_RUN,
    data
});

export const addRunAsyncAction = (data: RunsItem) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestAddRunAction());

    const formData = new FormData();

    formData.append('title', data.title || '');
    formData.append('typeEvent', data.typeEvent?.join(',') || '');
    formData.append('country', data.country || '');
    formData.append('region', data.region || '');
    formData.append('city', data.city || '');
    formData.append('date', data.date?.format('X') || '');
    formData.append('description', data.description || '');
    formData.append('expo', data.expo || '');
    formData.append('howToGet', data.howToGet || '');
    formData.append('otherInfo', data.otherInfo || '');
    formData.append('program', data.program || '');
    formData.append('requirements', data.requirements || '');
    formData.append('whereToLive', data.whereToLive || '');
    formData.append('start', JSON.stringify(data.start) || '');
    formData.append('finish', JSON.stringify(data.finish) || '');
    formData.append('coords', data.coords !== undefined ? data.coords : '');
    formData.append('image', data.image || '');
    formData.append('distances', JSON.stringify(data.distances) || '');
    formData.append('numbering', JSON.stringify(data.numbering) || '');
    formData.append('payments', JSON.stringify(data.payments) || '');
    formData.append('organizerName', data.organizer.name || '');
    formData.append('organizerEmail', data.organizer.email || '');
    formData.append('organizerPhone', data.organizer.phone || '');
    formData.append('organizerSite', data.organizer.site || '');
    formData.append('registrationType', data.registrationType);
    formData.append('registrationSite', data.registrationSite !== undefined ? data.registrationSite : '');

    if (data.organizer.attachments) {
        data.organizer.attachments.forEach((file, i) => {
            formData.append(`organizerAttachments[${i}]`, file);
        });
    }
    data.distances.forEach((distance, i) => {
        if (distance.routeJpx) {
            formData.append(`distancesAttachments[${i}]`, distance.routeJpx);
        }
    });

    const result = await axios.post(`${API}api/v1/runs`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const responseData: RunsItemResponse = response.data;

        const start: { time: string, place: string } = JSON.parse(responseData.start);
        const finish: { time: string, place: string } = JSON.parse(responseData.finish);
        let distances: DistanceItem[] = [];
        JSON.parse(responseData.distances).forEach((distance: any) => {
            distances = [
                ...distances, {
                    name: distance.name,
                    categories: distance.categories,
                    prizeCategories: distance.prizeCategories,
                    description: distance.description as string,
                    expo: distance.expo as string,
                    startTime: moment(distance.startTime as string),
                    finishTime: moment(distance.finishTime as string),
                    foodOutlets: +distance.foodOutlets as number,
                    mileage: +distance.mileage as number,
                    requirements: distance.requirements as string,
                    routeText: distance.routeText as string,
                    routeJpx: distance.routeJpx as string,
                    slots: +distance.slots as number,
                    startCoords: distance.startCoords as string
                }
            ];
        });
        const responseNumbering = JSON.parse(responseData.numbering);
        const responseNumberingDistances = responseNumbering.numberingDistances !== '' ? responseNumbering.numberingDistances : undefined;
        const numbering: NumberingType = {
            numberingType: responseNumbering.numberingType as string,
            requireFields: responseNumbering.requireFields
        };
        if (responseNumberingDistances) {
            numbering.numberingDistances = <any[]>responseNumberingDistances.map((numberingItem: any): NumberingDistanceItem => ({
                distanceKey: numberingItem.distanceKey as string,
                range: numberingItem.range as string,
                gender: numberingItem.gender,
                exceptions: numberingItem.exceptions as string
            }));
        }
        const payments: PaymentItem[] = JSON.parse(responseData.payments).map((paymentItem: any): PaymentItem => ({
            distanceKey: paymentItem.distanceKey as string,
            basePrice: +paymentItem.basePrice as number,
            priceByDate: paymentItem.priceByDate.map((priceByDateItem: any): PaymentPriceByDateItem => ({
                date: priceByDateItem.date ? moment(priceByDateItem.date) : undefined,
                price: priceByDateItem.price ? +priceByDateItem.price : undefined
            })),
            priceBySlots: paymentItem.priceBySlots.map((priceBySlotsItem: any): PaymentPriceBySlotsItem => ({
                slots: priceBySlotsItem.slots ? +priceBySlotsItem.slots : undefined,
                price: priceBySlotsItem.price ? +priceBySlotsItem.price : undefined
            }))
        }));

        const runData: RunsItem = {
            id: responseData.id,
            title: responseData.title,
            typeEvent: responseData.typeEvent.split(','),
            country: responseData.country,
            region: responseData.region,
            city: responseData.city,
            date: moment(responseData.date),
            description: responseData.description,
            expo: responseData.expo,
            start: {
                time: moment(start.time),
                place: start.place
            },
            finish: {
                time: moment(finish.time),
                place: finish.place
            },
            coords: responseData.coords !== '' ? responseData.coords : undefined,
            howToGet: responseData.howToGet,
            otherInfo: responseData.otherInfo,
            program: responseData.program,
            requirements: responseData.requirements,
            whereToLive: responseData.whereToLive,
            image: responseData.image,
            organizer: {
                name: responseData.organizerName,
                email: responseData.organizerEmail !== '' ? responseData.organizerEmail : undefined,
                phone: responseData.organizerPhone !== '' ? responseData.organizerPhone : undefined,
                site: responseData.organizerSite !== '' ? responseData.organizerSite : undefined,
                attachments: responseData.organizerAttachments && responseData.organizerAttachments !== '' ? responseData.organizerAttachments.split(',') : undefined
            },
            registrationType: responseData.registrationType,
            registrationSite: responseData.registrationSite,
            distances,
            numbering,
            payments,
            participant: responseData.participant
        };

        dispatch(responseAddRunAction(runData));
        notification('success', 'Успех', 'Забег добавлен');
    }
};

// EDIT RUN ACTIONS //

export const requestEditRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_EDIT_RUN
});

export const responseEditRunAction = (data: RunsItem): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_EDIT_RUN,
    data
});

export const editRunAsyncAction = (data: RunsItem) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestEditRunAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('title', data.title || '');
    formData.append('typeEvent', data.typeEvent?.join(',') || '');
    formData.append('country', data.country || '');
    formData.append('region', data.region || '');
    formData.append('city', data.city || '');
    formData.append('date', data.date?.format('X') || '');
    formData.append('description', data.description || '');
    formData.append('expo', data.expo || '');
    formData.append('howToGet', data.howToGet || '');
    formData.append('otherInfo', data.otherInfo || '');
    formData.append('program', data.program || '');
    formData.append('requirements', data.requirements || '');
    formData.append('whereToLive', data.whereToLive || '');
    formData.append('start', JSON.stringify(data.start) || '');
    formData.append('finish', JSON.stringify(data.finish) || '');
    formData.append('coords', data.coords !== undefined ? data.coords : '');
    formData.append('registrationType', data.registrationType);
    formData.append('registrationSite', data.registrationSite !== undefined ? data.registrationSite : '');

    if (data.image !== undefined) {
        if (typeof data.image !== 'string') {
            formData.append('image', data.image);
        } else {
            formData.append('image', '');
        }
    } else {
        formData.append('image', data.image || '');
    }

    formData.append('distances', JSON.stringify(data.distances) || '');
    formData.append('numbering', JSON.stringify(data.numbering) || '');
    formData.append('payments', JSON.stringify(data.payments) || '');
    formData.append('organizerName', data.organizer.name || '');
    formData.append('organizerEmail', data.organizer.email || '');
    formData.append('organizerPhone', data.organizer.phone || '');
    formData.append('organizerSite', data.organizer.site || '');

    if (data.organizer.attachments) {
        data.organizer.attachments.forEach((file, i) => {
            if (typeof file !== 'string' && file.uid !== '0') {
                formData.append(`organizerAttachments[${i}]`, file);
            } else {
                formData.append(`organizerAttachments[${i}]`, '');
            }
        });
    }
    data.distances.forEach((distance, i) => {
        if (distance.routeJpx) {
            if (typeof distance.routeJpx !== 'string' && distance.routeJpx.uid !== '0') {
                formData.append(`distancesAttachments[${i}]`, distance.routeJpx);
            } else {
                formData.append(`distancesAttachments[${i}]`, '');
            }
        }
    });

    const result = await axios.post(`${API}api/v1/runs/edit`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const responseData: RunsItemResponse = response.data;

        const start: { time: string, place: string } = JSON.parse(responseData.start);
        const finish: { time: string, place: string } = JSON.parse(responseData.finish);
        let distances: DistanceItem[] = [];
        JSON.parse(responseData.distances).forEach((distance: any) => {
            distances = [
                ...distances, {
                    name: distance.name,
                    categories: distance.categories,
                    prizeCategories: distance.prizeCategories,
                    description: distance.description as string,
                    expo: distance.expo as string,
                    startTime: moment(distance.startTime as string),
                    finishTime: moment(distance.finishTime as string),
                    foodOutlets: +distance.foodOutlets as number,
                    mileage: +distance.mileage as number,
                    requirements: distance.requirements as string,
                    routeText: distance.routeText as string,
                    routeJpx: distance.routeJpx as string,
                    slots: +distance.slots as number,
                    startCoords: distance.startCoords as string
                }
            ];
        });
        const responseNumbering = JSON.parse(responseData.numbering);
        const responseNumberingDistances = responseNumbering.numberingDistances !== '' ? responseNumbering.numberingDistances : undefined;
        const numbering: NumberingType = {
            numberingType: responseNumbering.numberingType as string,
            requireFields: responseNumbering.requireFields
        };
        if (responseNumberingDistances) {
            numbering.numberingDistances = <any[]>responseNumberingDistances.map((numberingItem: any): NumberingDistanceItem => ({
                distanceKey: numberingItem.distanceKey as string,
                range: numberingItem.range as string,
                gender: numberingItem.gender,
                exceptions: numberingItem.exceptions as string
            }));
        }
        const payments: PaymentItem[] = JSON.parse(responseData.payments).map((paymentItem: any): PaymentItem => ({
            distanceKey: paymentItem.distanceKey as string,
            basePrice: +paymentItem.basePrice as number,
            priceByDate: paymentItem.priceByDate.map((priceByDateItem: any): PaymentPriceByDateItem => ({
                date: priceByDateItem.date ? moment(priceByDateItem.date) : undefined,
                price: priceByDateItem.price ? +priceByDateItem.price : undefined
            })),
            priceBySlots: paymentItem.priceBySlots.map((priceBySlotsItem: any): PaymentPriceBySlotsItem => ({
                slots: priceBySlotsItem.slots ? +priceBySlotsItem.slots : undefined,
                price: priceBySlotsItem.price ? +priceBySlotsItem.price : undefined
            }))
        }));

        const runData: RunsItem = {
            id: responseData.id,
            title: responseData.title,
            typeEvent: responseData.typeEvent.split(','),
            country: responseData.country,
            region: responseData.region,
            city: responseData.city,
            date: moment(responseData.date),
            description: responseData.description,
            expo: responseData.expo,
            start: {
                time: moment(start.time),
                place: start.place
            },
            finish: {
                time: moment(finish.time),
                place: finish.place
            },
            coords: responseData.coords !== '' ? responseData.coords : undefined,
            howToGet: responseData.howToGet,
            otherInfo: responseData.otherInfo,
            program: responseData.program,
            requirements: responseData.requirements,
            whereToLive: responseData.whereToLive,
            image: responseData.image,
            organizer: {
                name: responseData.organizerName,
                email: responseData.organizerEmail !== '' ? responseData.organizerEmail : undefined,
                phone: responseData.organizerPhone !== '' ? responseData.organizerPhone : undefined,
                site: responseData.organizerSite !== '' ? responseData.organizerSite : undefined,
                attachments: responseData.organizerAttachments && responseData.organizerAttachments !== '' ? responseData.organizerAttachments.split(',') : undefined
            },
            registrationType: responseData.registrationType,
            registrationSite: responseData.registrationSite,
            distances,
            numbering,
            payments,
            participant: responseData.participant
        };

        dispatch(responseEditRunAction(runData));
        notification('success', 'Успех', 'Забег обновлен');
    }
};

// REMOVE RUN ACTIONS //

export const requestRemoveRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_REMOVE_RUN
});

export const responseRemoveRunAction = (id: number): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_REMOVE_RUN,
    id
});

export const removeRunAsyncAction = (id: number) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestRemoveRunAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/runs/remove`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseRemoveRunAction(id));
        notification('success', 'Успех', 'Забег удален');
    }
};

// GET RUNS ACTIONS //

export const requestGetRunsAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_GET_RUNS
});

export const responseGetRunsAction = (data: RunsItem[]): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_GET_RUNS,
    data
});

export const getRunsAsyncAction = () => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestGetRunsAction());

    const result = await axios.get(`${API}api/v1/runs`);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
    } else {
        let runs: RunsItem[] | [] = [];
        <RunsItemResponse[]>response.data.forEach((item: RunsItemResponse) => {
            const start: { time: string, place: string } = JSON.parse(item.start);
            const finish: { time: string, place: string } = JSON.parse(item.finish);
            let distances: DistanceItem[] = [];
            JSON.parse(item.distances).forEach((distance: any) => {
                distances = [
                    ...distances, {
                        name: distance.name,
                        categories: distance.categories,
                        prizeCategories: distance.prizeCategories,
                        description: distance.description as string,
                        expo: distance.expo as string,
                        startTime: moment(distance.startTime as string),
                        finishTime: moment(distance.finishTime as string),
                        foodOutlets: +distance.foodOutlets as number,
                        mileage: +distance.mileage as number,
                        requirements: distance.requirements as string,
                        routeText: distance.routeText as string,
                        routeJpx: distance.routeJpx as string,
                        slots: +distance.slots as number,
                        startCoords: distance.startCoords as string
                    }
                ];
            });
            const responseNumbering = JSON.parse(item.numbering);
            const responseNumberingDistances = responseNumbering.numberingDistances !== '' ? responseNumbering.numberingDistances : undefined;
            const numbering: NumberingType = {
                numberingType: responseNumbering.numberingType as string,
                requireFields: responseNumbering.requireFields
            };
            if (responseNumberingDistances) {
                numbering.numberingDistances = <any[]>responseNumberingDistances.map((numberingItem: any): NumberingDistanceItem => ({
                    distanceKey: numberingItem.distanceKey as string,
                    range: numberingItem.range as string,
                    gender: numberingItem.gender,
                    exceptions: numberingItem.exceptions as string
                }));
            }
            const payments: PaymentItem[] = JSON.parse(item.payments).map((paymentItem: any): PaymentItem => ({
                distanceKey: paymentItem.distanceKey as string,
                basePrice: +paymentItem.basePrice as number,
                priceByDate: paymentItem.priceByDate.map((priceByDateItem: any): PaymentPriceByDateItem => ({
                    date: priceByDateItem.date ? moment(priceByDateItem.date) : undefined,
                    price: priceByDateItem.price ? +priceByDateItem.price : undefined
                })),
                priceBySlots: paymentItem.priceBySlots.map((priceBySlotsItem: any): PaymentPriceBySlotsItem => ({
                    slots: priceBySlotsItem.slots ? +priceBySlotsItem.slots : undefined,
                    price: priceBySlotsItem.price ? +priceBySlotsItem.price : undefined
                }))
            }));

            runs = [...runs, {
                id: item.id,
                title: item.title,
                typeEvent: item.typeEvent.split(','),
                country: item.country,
                region: item.region,
                city: item.city,
                date: moment(item.date),
                description: item.description,
                expo: item.expo,
                start: {
                    time: moment(start.time),
                    place: start.place
                },
                finish: {
                    time: moment(finish.time),
                    place: finish.place
                },
                coords: item.coords !== '' ? item.coords : undefined,
                howToGet: item.howToGet,
                otherInfo: item.otherInfo,
                program: item.program,
                requirements: item.requirements,
                whereToLive: item.whereToLive,
                image: item.image,
                organizer: {
                    name: item.organizerName,
                    email: item.organizerEmail !== '' ? item.organizerEmail : undefined,
                    phone: item.organizerPhone !== '' ? item.organizerPhone : undefined,
                    site: item.organizerSite !== '' ? item.organizerSite : undefined,
                    attachments: item.organizerAttachments && item.organizerAttachments !== '' ? item.organizerAttachments.split(',') : undefined
                },
                registrationType: item.registrationType,
                registrationSite: item.registrationSite,
                distances,
                numbering,
                payments,
                participant: item.participant
            }];
        });

        dispatch(responseGetRunsAction(runs));
    }
};

// COPY RUN ACTIONS //

export const requestCopyRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_COPY_RUN
});

export const responseCopyRunAction = (id: number, newID: number): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_COPY_RUN,
    id,
    newID
});

export const copyRunAsyncAction = (id: number) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestCopyRunAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/runs/copy`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseCopyRunAction(id, response.data.id));
        notification('success', 'Успех', 'Забег скопирован');
    }
};

// GET RUNS USERS ACTIONS //

export const requestGetRunsUsersAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_GET_RUNS_USERS
});

export const responseGetRunsUsersAction = (data: RunsUsersItem[]): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_GET_RUNS_USERS,
    data
});

export const getRunsUsersAsyncAction = () => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestGetRunsUsersAction());

    const result = await axios.get(`${API}api/v1/users?role=2`);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
    } else {
        let users: RunsUsersItem[] | [] = [];

        response.data.forEach((item: RunsUsersItem) => {
            users = [...users, {
                id: item.id,
                name: item.name
            }];
        });

        dispatch(responseGetRunsUsersAction(users));
    }
};

// ADD RUN PARTICIPANT ACTIONS //

export const requestAddParticipantRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_ADD_PARTICIPANT_RUN
});

export const responseAddParticipantRunAction = (data: ParticipantItem): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_ADD_PARTICIPANT_RUN,
    data
});

export const addParticipantRunAsyncAction = (data: AddParticipantForm) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestAddParticipantRunAction());

    const formData = new FormData();
    formData.append('runID', data.run.toString());
    formData.append('distanceKey', data.distanceKey.toString());

    if (data.typeParticipant === 1) {
        if (data.user !== undefined) {
            formData.append('userID', data.user.toFixed());
        }
    } else if (data.typeParticipant === 0) {
        if (data.name !== undefined) {
            formData.append('name', data.name);
        }
        if (data.birthday !== undefined) {
            formData.append('birthday', data.birthday.format('YYYY-MM-DD'));
        }
    }

    const result = await axios.post(`${API}api/v1/runs/addParticipant`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseAddParticipantRunAction(response.data as ParticipantItem));
        notification('success', 'Успех', 'Участник добавлен');
    }
};

// ADD RUN RESULTS ACTIONS //

export const requestAddResultsRunAction = (): RunsActionsType => ({
    type: RunsActionsList.REQUEST_ADD_RESULTS_RUN
});

export const responseAddResultsRunAction = (results: AddResultsForm[], runID: number): RunsActionsType => ({
    type: RunsActionsList.RESPONSE_ADD_RESULTS_RUN,
    data: {
        results,
        runID
    }
});

export const addResultsRunAsyncAction = (results: AddResultsForm[], runID: number) => async (dispatch: ThunkDispatch<RunsStateType, void, Action>): Promise<void> => {
    dispatch(requestAddResultsRunAction());

    const formData = new FormData();
    formData.append('results', JSON.stringify(results));

    const result = await axios.post(`${API}api/v1/runs/addResults`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorRunsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseAddResultsRunAction(results, runID));
        notification('success', 'Успех', 'Результаты обновлены');
    }
};
