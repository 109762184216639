import {
    RunsStateType,
    RunsActionsType,
    RunsActionsList,
    RunsItem
} from './types';

export const initialState: RunsStateType = {
    data: null,
    step: 0,
    stepForm: null,
    modals: {
        create: false,
        edit: false,
        addParticipant: {
            visible: false,
            runID: 0,
            users: null,
            loading: false
        },
        addResults: {
            visible: false,
            runID: 0,
            loading: false
        }
    },
    loading: false,
    error: null
};

const authReducer = (state: RunsStateType = initialState, action: RunsActionsType) : RunsStateType => {
    switch (action.type) {
        case RunsActionsList.ERROR_RUNS: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case RunsActionsList.MODAL_ADD_RUNS: {
            return {
                ...state,
                step: 0,
                modals: {
                    ...state.modals,
                    create: !state.modals.create
                }
            };
        }
        case RunsActionsList.MODAL_EDIT_RUNS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    edit: !state.modals.edit
                }
            };
        }
        case RunsActionsList.MODAL_PARTICIPANT_RUNS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addParticipant: {
                        ...state.modals.addParticipant,
                        visible: !state.modals.addParticipant.visible,
                        runID: action.runID
                    }
                }
            };
        }
        case RunsActionsList.MODAL_RESULTS_RUNS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addResults: {
                        ...state.modals.addResults,
                        visible: !state.modals.addResults.visible,
                        runID: action.runID
                    }
                }
            };
        }
        case RunsActionsList.CHANGE_STEP_RUNS: {
            if (action.direction === 'step') {
                return {
                    ...state,
                    step: action.step !== undefined ? action.step : state.step
                };
            }
            return {
                ...state,
                step: action.direction === 'next' ? state.step + 1 : state.step - 1
            };
        }
        case RunsActionsList.CHANGE_STEP_FORM_RUNS: {
            return {
                ...state,
                stepForm: action.form
            };
        }
        case RunsActionsList.REQUEST_GET_RUNS:
        case RunsActionsList.REQUEST_ADD_RUN:
        case RunsActionsList.REQUEST_EDIT_RUN:
        case RunsActionsList.REQUEST_REMOVE_RUN:
        case RunsActionsList.REQUEST_COPY_RUN: {
            return {
                ...state,
                loading: true
            };
        }
        case RunsActionsList.RESPONSE_GET_RUNS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case RunsActionsList.RESPONSE_ADD_RUN: {
            return {
                ...state,
                loading: false,
                data: state.data ? [...state.data, action.data] : state.data,
                modals: {
                    ...state.modals,
                    create: false
                }
            };
        }
        case RunsActionsList.RESPONSE_EDIT_RUN: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.data.id) {
                        return {
                            ...item,
                            ...action.data
                        };
                    }
                    return item;
                }) : state.data,
                modals: {
                    ...state.modals,
                    edit: false
                }
            };
        }
        case RunsActionsList.RESPONSE_REMOVE_RUN: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.filter((item) => item.id !== action.id) : state.data
            };
        }
        case RunsActionsList.RESPONSE_COPY_RUN: {
            const copyingRun = state.data?.filter((item) => item.id === action.id);
            if (copyingRun !== undefined && copyingRun.length) {
                const newRun: RunsItem = {
                    ...copyingRun[0],
                    id: action.newID,
                    title: `Копия ${copyingRun[0].title}`,
                    participant: []
                };

                return {
                    ...state,
                    loading: false,
                    data: state.data ? [...state.data, newRun] : state.data
                };
            }

            return {
                ...state,
                loading: false,
                data: state.data
            };
        }
        case RunsActionsList.REQUEST_GET_RUNS_USERS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addParticipant: {
                        ...state.modals.addParticipant,
                        loading: true
                    }
                }
            };
        }
        case RunsActionsList.RESPONSE_GET_RUNS_USERS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addParticipant: {
                        ...state.modals.addParticipant,
                        users: action.data,
                        loading: false
                    }
                }
            };
        }
        case RunsActionsList.REQUEST_ADD_PARTICIPANT_RUN: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addParticipant: {
                        ...state.modals.addParticipant,
                        loading: true
                    }
                }
            };
        }
        case RunsActionsList.RESPONSE_ADD_PARTICIPANT_RUN: {
            const { runID } = state.modals.addParticipant;
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addParticipant: {
                        ...state.modals.addParticipant,
                        loading: false,
                        visible: false,
                        runID: 0
                    }
                },
                data: state.data ? state.data.map((run) => {
                    if (run.id === runID) {
                        return {
                            ...run,
                            participant: [...run.participant, action.data]
                        };
                    }
                    return run;
                }) : state.data
            };
        }
        case RunsActionsList.REQUEST_ADD_RESULTS_RUN: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addResults: {
                        ...state.modals.addResults,
                        loading: true
                    }
                }
            };
        }
        case RunsActionsList.RESPONSE_ADD_RESULTS_RUN: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    addResults: {
                        ...state.modals.addResults,
                        loading: false,
                        visible: false
                    }
                },
                data: state.data ? state.data.map((run) => {
                    if (run.id === action.data.runID) {
                        return {
                            ...run,
                            participant: run.participant.map((item) => {
                                const resultItem = action.data.results.filter((result) => result.id === item.id);

                                if (resultItem.length) {
                                    return {
                                        ...item,
                                        time: resultItem[0].time
                                    };
                                }
                                return item;
                            })
                        };
                    }
                    return run;
                }) : state.data
            };
        }
        default:
            return state;
    }
};

export default authReducer;
