import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Tabs,
    Checkbox,
    Select
} from 'antd';
import {
    UserOutlined, LockOutlined, MobileOutlined, MailOutlined
} from '@ant-design/icons';

import './style.scss';
import { AppState } from '../../store';
import { SignUpFormData } from './types';
import { signUpAction } from './actions';

const { TabPane } = Tabs;
const { Option } = Select;

const SignUp:React.FC = () => {
    const dispatch = useDispatch();
    const [formSignUp] = Form.useForm();

    useEffect(() => {
        formSignUp.setFields([{ name: 'type', value: 'user' }]);
    });

    const onSend = (values: SignUpFormData) => {
        dispatch(signUpAction(values));
    };

    return (
        <Form
            form={formSignUp}
            className="auth__form"
            onFinish={onSend}
        >
            <Form.Item
                name="login"
                rules={[{ required: true, message: 'Введите телефон или e-mail' }]}
            >
                <Input prefix={<MobileOutlined />} placeholder="Телефон или e-mail" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Введите пароль' }]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Пароль"
                />
            </Form.Item>
            <Form.Item
                name="password-retry"
                rules={[{ required: true, message: 'Повторите пароль' }]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Повторите пароль"
                />
            </Form.Item>
            <Form.Item
                name="type"
                rules={[{ required: true, message: 'Выберите роль' }]}
            >
                <Select placeholder="Тип аккаунта">
                    <Option value="user">Пользователь</Option>
                    <Option value="organizer">Организатор</Option>
                </Select>
            </Form.Item>
            <Form.Item className="auth__code">
                <Form.Item
                    name="code"
                    rules={[{ required: false, message: 'Введите код' }]}
                >
                    <Input prefix={<MailOutlined />} placeholder="Код подтверждения" />
                </Form.Item>
                <Button>Получить код</Button>
            </Form.Item>
            <Form.Item className="auth__actions">
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="auth__btn"
                    loading={false}
                >
                    Зарегистрироваться
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignUp;
