import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Tabs,
    Checkbox
} from 'antd';
import {
    UserOutlined, LockOutlined, MobileOutlined, MailOutlined
} from '@ant-design/icons';

import './style.scss';
import { AppState } from '../../store';
import { SignInFormData } from './types';
import { signInAsyncAction } from './actions';

const SignIn:React.FC = () => {
    const dispatch = useDispatch();
    const [formSignIn] = Form.useForm();

    const onSend = (values: SignInFormData) => {
        dispatch(signInAsyncAction(values));
    };

    useEffect(() => {
        document.title = 'Авторизация | athletica.space';
    }, []);

    return (
        <Form
            form={formSignIn}
            className="auth__form"
            onFinish={onSend}
        >
            <Form.Item
                name="login"
                rules={[{ required: true, message: 'Введите телефон или e-mail' }]}
            >
                <Input prefix={<UserOutlined />} placeholder="Телефон или e-mail" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Введите пароль' }]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Пароль"
                />
            </Form.Item>
            <Form.Item className="auth__actions">
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>
                <a className="auth__forgot" href="" onClick={(e) => e.preventDefault()}>
                    Забыли пароль?
                </a>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="auth__btn"
                    loading={false}
                >
                    Войти
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignIn;
