import { RcFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';
import { TaskItem } from '../Tasks/types';

// DATA ITEMS TYPES //

export interface CupItem {
    id: number,
    title: string,
    conditions: string,
    period: Moment[],
    tasks: number[]
}

// DATA FROM SERVER //

export interface CupItemFromServer {
    id: number,
    title: string,
    conditions: string,
    periodStart: string,
    periodEnd: string,
    tasks: string
}

// DATA FORMS TYPES //

export interface AddCupForm {
    title: string | undefined,
    conditions: string | undefined,
    period: Moment[] | undefined,
    tasks: number[] | undefined
}

export interface EditCupForm {
    id: number,
    title: string | undefined,
    conditions: string | undefined,
    period: Moment[] | undefined,
    tasks: number[] | undefined
}

// STORE TYPE //

export interface CupsStateType {
    data: CupItem[] | null,
    tasks: TaskItem[] | null,
    modals: {
        create: boolean,
        edit: boolean
    }
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum CupsActionsList {
    ERROR_CUPS = 'ERROR_CUPS',
    REQUEST_GET_CUPS = 'REQUEST_GET_CUPS',
    RESPONSE_GET_CUPS = 'RESPONSE_GET_CUPS',
    REQUEST_ADD_CUPS = 'REQUEST_ADD_CUPS',
    RESPONSE_ADD_CUPS = 'RESPONSE_ADD_CUPS',
    REQUEST_EDIT_CUPS = 'REQUEST_EDIT_CUPS',
    RESPONSE_EDIT_CUPS = 'RESPONSE_EDIT_CUPS',
    REQUEST_REMOVE_CUPS = 'REQUEST_REMOVE_CUPS',
    RESPONSE_REMOVE_CUPS = 'RESPONSE_REMOVE_CUPS',
    MODAL_ADD_CUPS = 'MODAL_ADD_CUPS',
    MODAL_EDIT_CUPS = 'MODAL_EDIT_CUPS'
}

// ERROR //

export interface ErrorCupsActionType {
    type: CupsActionsList.ERROR_CUPS,
    error: string
}

// GET TYPES //

export interface RequestGetCupsActionType {
    type: CupsActionsList.REQUEST_GET_CUPS
}

export interface ResponseGetCupsActionType {
    type: CupsActionsList.RESPONSE_GET_CUPS,
    data: CupItem[],
    tasks: TaskItem[]
}

// ADD TYPES //

export interface RequestAddCupActionType {
    type: CupsActionsList.REQUEST_ADD_CUPS
}

export interface ResponseAddCupActionType {
    type: CupsActionsList.RESPONSE_ADD_CUPS,
    data: CupItem
}

// EDIT TYPES //

export interface RequestEditCupActionType {
    type: CupsActionsList.REQUEST_EDIT_CUPS
}

export interface ResponseEditCupActionType {
    type: CupsActionsList.RESPONSE_EDIT_CUPS,
    data: CupItem
}

// REMOVE TYPES //

export interface RequestRemoveCupActionType {
    type: CupsActionsList.REQUEST_REMOVE_CUPS
}

export interface ResponseRemoveCupActionType {
    type: CupsActionsList.RESPONSE_REMOVE_CUPS,
    id: number
}

// SWITCH MODALS //

export interface SwitchModalAddActionType {
    type: CupsActionsList.MODAL_ADD_CUPS
}

export interface SwitchModalEditActionType {
    type: CupsActionsList.MODAL_EDIT_CUPS
}

export type CupsActionsType =
ErrorCupsActionType
| RequestGetCupsActionType
| ResponseGetCupsActionType
| RequestAddCupActionType
| ResponseAddCupActionType
| RequestEditCupActionType
| ResponseEditCupActionType
| RequestRemoveCupActionType
| ResponseRemoveCupActionType
| SwitchModalAddActionType
| SwitchModalEditActionType;
