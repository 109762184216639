/* eslint-disable camelcase */
import { Moment } from 'moment';

// DATA ITEMS TYPES //

export interface TrainingItem {
    id: number,
    name: string,
    date: Moment,
    distance: number,
    time: number,
    elevation: number
}

export interface Workouts {
    run: TrainingItem[],
    walk: TrainingItem[],
    ride: TrainingItem[],
    swim: TrainingItem[],
    ski: TrainingItem[],
    totals: {
        run: number,
        walk: number,
        ride: number,
        swim: number,
        ski: number
    }
}

export interface CalendarWorkouts {
    run: TrainingItem[],
    walk: TrainingItem[],
    ride: TrainingItem[],
    swim: TrainingItem[],
    ski: TrainingItem[]
}

// DATA FROM SERVER //

export interface TrainingItemFromServer {
    id: number,
    name: string,
    date: string,
    distance: number,
    time: number,
    elevation: number
}

export interface WorkoutsFromServer {
    run: TrainingItemFromServer[],
    walk: TrainingItemFromServer[],
    ride: TrainingItemFromServer[],
    swim: TrainingItemFromServer[],
    ski: TrainingItemFromServer[],
    totals: {
        run: number,
        walk: number,
        ride: number,
        swim: number,
        ski: number
    }
}

export interface CalendarWorkoutsFromServer {
    run: TrainingItemFromServer[],
    walk: TrainingItemFromServer[],
    ride: TrainingItemFromServer[],
    swim: TrainingItemFromServer[],
    ski: TrainingItemFromServer[]
}

export interface ResponseWorkoutsFromServer {
    workouts: WorkoutsFromServer,
    calendar: {
        [key: string]: CalendarWorkoutsFromServer
    }
}

// STORE TYPE //

export interface WorkoutStateData {
    workouts: Workouts,
    calendar: {
        [key: string]: CalendarWorkouts
    }
}

export interface WorkoutStateType {
    data: WorkoutStateData | null,
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum WorkoutActionsList {
    ERROR_WORKOUT = 'ERROR_WORKOUT',
    REQUEST_GET_WORKOUTS = 'REQUEST_GET_WORKOUTS',
    RESPONSE_GET_WORKOUTS = 'RESPONSE_GET_WORKOUTS'
}

// ERROR //

export interface ErrorWorkoutActionType {
    type: WorkoutActionsList.ERROR_WORKOUT,
    error: string
}

// GET //

export interface RequestGetWorkoutsActionType {
    type: WorkoutActionsList.REQUEST_GET_WORKOUTS
}

export interface ResponseGetWorkoutsActionType {
    type: WorkoutActionsList.RESPONSE_GET_WORKOUTS,
    data: WorkoutStateData
}

export type WorkoutActionsType =
ErrorWorkoutActionType
| RequestGetWorkoutsActionType
| ResponseGetWorkoutsActionType;
