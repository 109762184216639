import Item from 'antd/lib/list/Item';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    CalendarWorkouts,
    CalendarWorkoutsFromServer,
    ResponseWorkoutsFromServer,
    TrainingItemFromServer,
    WorkoutActionsList,
    WorkoutActionsType,
    Workouts,
    WorkoutStateData,
    WorkoutStateType
} from './types';

export const errorWorkoutAction = (error: string): WorkoutActionsType => ({
    type: WorkoutActionsList.ERROR_WORKOUT,
    error
});

// GET ACTIONS //

export const requestGetWorkoutAction = (): WorkoutActionsType => ({
    type: WorkoutActionsList.REQUEST_GET_WORKOUTS
});

export const responseGetWorkoutAction = (data: WorkoutStateData): WorkoutActionsType => ({
    type: WorkoutActionsList.RESPONSE_GET_WORKOUTS,
    data
});

export const getWorkoutsAsyncAction = (month = 'currentMonth') => async (dispatch: ThunkDispatch<WorkoutStateType, void, Action>): Promise<void> => {
    dispatch(requestGetWorkoutAction());

    const result = await axios.get(`${API}api/v1/workout/trains?month=${month}`);
    const response = result.data;

    if (response.error) {
        dispatch(errorWorkoutAction(response.message));
    } else {
        const workoutResponse = response.data as ResponseWorkoutsFromServer;
        const workouts: Workouts = {
            walk: workoutResponse.workouts.walk.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
            run: workoutResponse.workouts.run.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
            ride: workoutResponse.workouts.ride.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
            swim: workoutResponse.workouts.swim.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
            ski: workoutResponse.workouts.ski.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
            totals: workoutResponse.workouts.totals
        };
        let calendar: { [key: string]: CalendarWorkouts } = {};

        Object.keys(workoutResponse.calendar).forEach((key) => {
            calendar = {
                ...calendar,
                [key]: {
                    walk: workoutResponse.calendar[key].walk.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
                    run: workoutResponse.calendar[key].run.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
                    ride: workoutResponse.calendar[key].ride.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
                    swim: workoutResponse.calendar[key].swim.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) })),
                    ski: workoutResponse.calendar[key].ski.map((item: TrainingItemFromServer) => ({ ...item, date: moment(item.date) }))
                }
            };
        });

        const workoutStateData: WorkoutStateData = {
            workouts,
            calendar
        };

        dispatch(responseGetWorkoutAction(workoutStateData));
    }
};
