import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = (): JSX.Element => (
    <div className="page error">
        <Result
            status="404"
            title="404"
            subTitle="Данной страницы не существует."
            extra={<Button type="primary"><Link to="/">На главную</Link></Button>}
        />
    </div>
);

export default NotFound;
