import {
    TasksStateType,
    TasksActionsType,
    TasksActionsList
} from './types';

export const initialState: TasksStateType = {
    data: null,
    modals: {
        create: false,
        edit: false
    },
    loading: false,
    error: null
};

const tasksReducer = (state: TasksStateType = initialState, action: TasksActionsType) : TasksStateType => {
    switch (action.type) {
        case TasksActionsList.ERROR_TASKS: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case TasksActionsList.MODAL_ADD_TASKS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: !state.modals.create
                }
            };
        }
        case TasksActionsList.MODAL_EDIT_TASKS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    edit: !state.modals.edit
                }
            };
        }
        case TasksActionsList.REQUEST_GET_TASKS:
        case TasksActionsList.REQUEST_ADD_TASKS:
        case TasksActionsList.REQUEST_EDIT_TASKS:
        case TasksActionsList.REQUEST_REMOVE_TASKS:
            return {
                ...state,
                loading: true
            };
        case TasksActionsList.RESPONSE_GET_TASKS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case TasksActionsList.RESPONSE_ADD_TASKS: {
            return {
                ...state,
                loading: false,
                data: state.data ? [...state.data, action.data] : state.data,
                modals: {
                    ...state.modals,
                    create: false
                }
            };
        }
        case TasksActionsList.RESPONSE_EDIT_TASKS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.data.id) {
                        return {
                            ...item,
                            ...action.data
                        };
                    }
                    return item;
                }) : [action.data],
                modals: {
                    ...state.modals,
                    edit: false
                }
            };
        }
        case TasksActionsList.RESPONSE_REMOVE_TASKS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.filter((item) => item.id !== action.id) : state.data
            };
        }
        default:
            return state;
    }
};

export default tasksReducer;
