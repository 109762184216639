import React from 'react';
import { notification as notificationAntd } from 'antd';
import { IconType } from 'antd/lib/notification';

const notification = (type: IconType, message: React.ReactNode, description: React.ReactNode): void => {
    notificationAntd[type]({
        message,
        description
    });
};

export default notification;
