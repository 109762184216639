/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    FormInstance,
    Modal, Upload
} from 'antd';
import { UploadChangeParam, UploadFile, UploadListType } from 'antd/lib/upload/interface';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { AddRunFormContext } from '../../pages/Runs/AddRun';

interface PropsCustomUpload {
    form: FormInstance<any> | undefined,
    name: string,
    onChange?: (info: UploadChangeParam<any>) => void | undefined,
    accept?: string | undefined,
    multiple?: boolean | undefined,
    listType?: UploadListType | undefined,
    value?: UploadFile[] | UploadChangeParam | undefined
}

const CustomUpload:React.FC<PropsCustomUpload> = ({
    form, name, onChange, accept, multiple, listType, value
}) => {
    const [previewModal, setPreviewModal] = useState({
        image: '',
        visible: false,
        title: ''
    });
    const [fileList, setFileList] = useState<UploadFile[] | UploadChangeParam>(value || []);

    useEffect(() => {
        if (value) {
            if (Array.isArray(value)) {
                setFileList({ file: value[0], fileList: value });
            } else {
                setFileList(value);
            }
        }
    }, [value]);

    useEffect(() => {
        if (Array.isArray(fileList) && fileList.length === 0) {
            form?.setFieldsValue({
                [name]: undefined
            });
        }
    }, [fileList]);

    const remove = (file: UploadFile<any>) => {
        if (Array.isArray(fileList)) {
            const newFileList = fileList.filter((item) => item.uid !== file.uid);
            setFileList(newFileList);
            form?.setFieldsValue({
                [name]: {
                    file: newFileList.length ? newFileList[newFileList.length - 1] : undefined,
                    fileList: newFileList
                }
            });
        } else {
            const newFileList = fileList.fileList.filter((item) => item.uid !== file.uid);
            setFileList(newFileList);
            form?.setFieldsValue({
                [name]: {
                    file: newFileList.length ? newFileList[newFileList.length - 1] : undefined,
                    fileList: newFileList
                }
            });
        }
        return false;
    };

    const handlePreview = async (file: UploadFile<unknown>) => {
        if (file.thumbUrl) {
            setPreviewModal({
                image: file.thumbUrl,
                title: file.name,
                visible: true
            });
        }
    };

    const UploadButton = () => {
        let Btn = null;
        if (listType === 'picture-card') {
            Btn = (
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Загрузить</div>
                </div>
            );
        } else if (listType === 'text') {
            Btn = (
                <Button icon={<UploadOutlined />}>Загрузить</Button>
            );
        }

        if (!!multiple === false && ((Array.isArray(fileList) && fileList.length < 1) || (!Array.isArray(fileList) && fileList.fileList.length < 1))) {
            return Btn;
        }
        if (multiple) {
            return Btn;
        }
        return null;
    };

    return (
        <>
            <Upload
                accept={accept || '.jpg,.jpeg,.png'}
                beforeUpload={() => false}
                listType={listType}
                fileList={Array.isArray(fileList) ? fileList : fileList.fileList}
                onPreview={handlePreview}
                onChange={(info: UploadChangeParam) => {
                    setFileList([...info.fileList]);
                    if (onChange) onChange(info);
                }}
                onRemove={remove}
                multiple={!!multiple}
            >
                {UploadButton()}
            </Upload>
            <Modal
                visible={previewModal.visible}
                title={previewModal.title}
                footer={null}
                onCancel={() => setPreviewModal({ ...previewModal, visible: false })}
                centered
            >
                <img style={{ width: '100%' }} src={previewModal.image} alt={previewModal.title} />
            </Modal>
        </>
    );
};

export default CustomUpload;
