import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    AddBadgeForm,
    BadgeItem, BadgeItemConditions, BadgeItemFromServer, BadgesActionsList, BadgesActionsType, BadgesStateType, EditBadgeForm
} from './types';

export const errorBadgeAction = (error: string): BadgesActionsType => ({
    type: BadgesActionsList.ERROR_BADGES,
    error
});

// MODALS ACTIONS //

export const switchAddModalAction = (): BadgesActionsType => ({
    type: BadgesActionsList.MODAL_ADD_BADGES
});

export const switchEditModalAction = (): BadgesActionsType => ({
    type: BadgesActionsList.MODAL_EDIT_BADGES
});

// ADD ACTIONS //

export const requestAddBadgeAction = (): BadgesActionsType => ({
    type: BadgesActionsList.REQUEST_ADD_BADGES
});

export const responseAddBadgeAction = (data: BadgeItem): BadgesActionsType => ({
    type: BadgesActionsList.RESPONSE_ADD_BADGES,
    data
});

export const addBadgeAsyncAction = (data: AddBadgeForm) => async (dispatch: ThunkDispatch<BadgesStateType, void, Action>): Promise<void> => {
    dispatch(requestAddBadgeAction());

    const formData = new FormData();

    formData.append('title', data.title);
    formData.append('level', data.level);
    formData.append('periodStart', data.period[0].format('X'));
    formData.append('periodEnd', data.period[1].format('X'));
    if (data.icon) {
        formData.append('icon', data.icon as Blob);
    }
    formData.append('conditions', JSON.stringify({
        type: data.conditionsType,
        subType: data.conditionsSubType || undefined,
        value: data.conditionsValue
    }));

    const result = await axios.post(`${API}api/v1/badges`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    const response = result.data;

    if (response.error) {
        dispatch(errorBadgeAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const badgeData: BadgeItem = {
            id: response.data.id,
            title: data.title,
            level: data.level,
            icon: response.data.imagePath,
            period: data.period,
            conditions: {
                type: data.conditionsType,
                subType: data.conditionsSubType,
                value: data.conditionsValue
            }
        };

        dispatch(responseAddBadgeAction(badgeData));
        notification('success', 'Успех', 'Бейдж добавлен');
    }
};

// EDIT ACTIONS //

export const requestEditBadgeAction = (): BadgesActionsType => ({
    type: BadgesActionsList.REQUEST_EDIT_BADGES
});

export const responseEditBadgeAction = (data: BadgeItem): BadgesActionsType => ({
    type: BadgesActionsList.RESPONSE_EDIT_BADGES,
    data
});

export const editBadgeAsyncAction = (data: EditBadgeForm) => async (dispatch: ThunkDispatch<BadgesStateType, void, Action>): Promise<void> => {
    dispatch(requestEditBadgeAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('title', data.title);
    formData.append('level', data.level);
    formData.append('periodStart', data.period[0].format('X'));
    formData.append('periodEnd', data.period[1].format('X'));
    if (data.icon) {
        formData.append('icon', data.icon as Blob);
    }
    formData.append('conditions', JSON.stringify({
        type: data.conditionsType,
        subType: data.conditionsSubType || undefined,
        value: data.conditionsValue
    }));

    const result = await axios.post(`${API}api/v1/badges/edit`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    const response = result.data;

    if (response.error) {
        dispatch(errorBadgeAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const badgeData: BadgeItem = {
            id: response.data.id,
            title: data.title,
            level: data.level,
            icon: response.data.imagePath,
            period: data.period,
            conditions: {
                type: data.conditionsType,
                subType: data.conditionsSubType,
                value: data.conditionsValue
            }
        };

        dispatch(responseEditBadgeAction(badgeData));
        notification('success', 'Успех', 'Бейдж обновлен');
    }
};

// REMOVE ACTIONS //

export const requestRemoveBadgeAction = (): BadgesActionsType => ({
    type: BadgesActionsList.REQUEST_REMOVE_BADGES
});

export const responseRemoveBadgeAction = (id: number): BadgesActionsType => ({
    type: BadgesActionsList.RESPONSE_REMOVE_BADGES,
    id
});

export const removeBadgeAsyncAction = (id: number) => async (dispatch: ThunkDispatch<BadgesStateType, void, Action>): Promise<void> => {
    dispatch(requestRemoveBadgeAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/badges/remove`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorBadgeAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseRemoveBadgeAction(id));
        notification('success', 'Успех', 'Бейдж удален');
    }
};

// GET ACTIONS //

export const requestGetBadgesAction = (): BadgesActionsType => ({
    type: BadgesActionsList.REQUEST_GET_BADGES
});

export const responseGetBadgesAction = (data: BadgeItem[]): BadgesActionsType => ({
    type: BadgesActionsList.RESPONSE_GET_BADGES,
    data
});

export const getBadgesAsyncAction = () => async (dispatch: ThunkDispatch<BadgesStateType, void, Action>): Promise<void> => {
    dispatch(requestGetBadgesAction());

    const result = await axios.get(`${API}api/v1/badges`);
    const response = result.data;

    if (response.error) {
        dispatch(errorBadgeAction(response.message));
    } else {
        let badges: BadgeItem[] | [] = [];
        <BadgeItemFromServer>response.data.forEach((item: BadgeItemFromServer) => {
            const conditions: BadgeItemConditions = {
                type: undefined,
                subType: undefined,
                value: undefined
            };
            const conditionsFromServer = JSON.parse(item.conditions);
            conditions.type = conditionsFromServer.type;
            conditions.subType = conditionsFromServer.subType || undefined;
            conditions.value = conditionsFromServer.value;

            if (conditions.type !== undefined) {
                switch (conditions.type) {
                    case 'distance':
                    case 'time':
                    case 'combo':
                        conditions.value = conditions.value ? +conditions.value : undefined;
                        break;
                    default:
                        break;
                }
            }

            badges = [...badges, {
                id: item.id,
                title: item.title,
                level: item.level,
                period: [moment(item.periodStart), moment(item.periodEnd)],
                icon: item.icon,
                conditions
            }];
        });

        dispatch(responseGetBadgesAction(badges));
    }
};
