import { combineReducers } from 'redux';

import authReducer from '../pages/Auth/reducer';
import newsReducer from '../pages/News/reducer';
import runsReducer from '../pages/Runs/reducer';
import badgesReducer from '../pages/Badges/reducer';
import tasksReducer from '../pages/Tasks/reducer';
import cupsReducer from '../pages/Cups/reducer';
import profileReducer from '../pages/Profile/reducer';
import workoutReducer from '../pages/Workout/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    news: newsReducer,
    runs: runsReducer,
    badges: badgesReducer,
    tasks: tasksReducer,
    cups: cupsReducer,
    profile: profileReducer,
    workout: workoutReducer
});

export type RootReducerType = typeof rootReducer;
export type RootStateType = ReturnType<RootReducerType>;

export default rootReducer;
