import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NotAccess = (): JSX.Element => (
    <div className="page error">
        <Result
            status="403"
            title="403"
            subTitle="У вас нет доступа к этой странице."
            extra={<Button type="primary"><Link to="/">На главную</Link></Button>}
        />
    </div>
);

export default NotAccess;
