/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Steps
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { AppState } from '../../store';
import {
    addRunAsyncAction, changeStepRunsAction, editRunAsyncAction, switchEditModalAction
} from './actions';
import BaseInfo from './steps/BaseInfo';
import Contacts from './steps/Contacts';
import Distance from './steps/Distance';
import Payment from './steps/Payment';
import Registration from './steps/Registration';
import { RunsItem } from './types';
import { RunFormContextType } from './index';

interface ParamsPathname {
    id?: string
}

export const EditRunFormContext = React.createContext<RunFormContextType>({
    createItem: null,
    onNext: undefined
});

const EditRun:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<ParamsPathname>();
    const runs = useSelector((state: AppState) => state.runs);
    const [contextData, setContextData] = useState<RunFormContextType>({ createItem: null, onNext: undefined });
    const [editID, setEditID] = useState<number | null>(null);

    window.onpopstate = (event) => {
        if (event.type === 'popstate' && window.location.pathname === '/runs' && runs.modals.edit) {
            dispatch(switchEditModalAction());
            setEditID(null);
        }
    };

    useEffect(() => {
        if (params.id) {
            dispatch(switchEditModalAction());
            setEditID(+params.id);
        }
    }, []);

    useEffect(() => {
        if (runs.modals.edit) {
            if (params.id) {
                setEditID(+params.id);
            }
        } else if (!runs.modals.edit && editID) {
            setContextData({ createItem: null, onNext: undefined });
            setEditID(null);
            history.goBack();
        }
    }, [runs.modals.edit]);

    useEffect(() => {
        if (editID) {
            const editItem = runs.data ? runs.data.filter((item) => item.id === editID)[0] : null;
            if (editItem) {
                setContextData({ createItem: editItem, onNext: undefined });
            }
        }
    }, [editID]);

    const onChangeStep = (step: number) => {
        runs.stepForm?.validateFields().then((values) => {
            runs.stepForm?.submit();
            dispatch(changeStepRunsAction('step', step));
        });
    };

    const onSave = async () => {
        try {
            await runs.stepForm?.validateFields();
            runs.stepForm?.submit();
            setTimeout(() => {
                if (contextData.createItem) {
                    dispatch(editRunAsyncAction(contextData.createItem));
                }
            }, 300);
        } catch (e) {
            console.error('error');
        }
    };

    return (
        <>
            <Modal
                centered
                visible={runs.modals.edit}
                onCancel={() => dispatch(switchEditModalAction())}
                width={1150}
                className="runs__modal edit"
                footer={(
                    <Button
                        key="next"
                        type="primary"
                        loading={runs.loading}
                        onClick={() => onSave()}
                    >
                        Сохранить
                    </Button>
                )}
            >
                {contextData.createItem && (
                    <>
                        <Steps
                            size="small"
                            current={runs.step}
                            style={{ margin: '30px 0' }}
                            onChange={onChangeStep}
                        >
                            <Steps.Step title="Основная инф." />
                            <Steps.Step title="Контакты" />
                            <Steps.Step title="Дистанция" />
                            <Steps.Step title="Оплата" />
                            <Steps.Step title="Рег. данные" />
                        </Steps>
                        <EditRunFormContext.Provider value={contextData}>
                            {runs.step === 0 && (<BaseInfo />)}
                            {runs.step === 1 && (<Contacts />)}
                            {runs.step === 2 && (<Distance />)}
                            {runs.step === 3 && (<Payment />)}
                            {runs.step === 4 && (<Registration />)}
                        </EditRunFormContext.Provider>
                    </>
                )}
            </Modal>
        </>
    );
};

export default EditRun;
