/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Input, Form, Table
} from 'antd';
import moment from 'moment';

import { AppState } from '../../store';
import {
    addParticipantRunAsyncAction, addResultsRunAsyncAction, getRunsUsersAsyncAction, switchResultsModalAction
} from './actions';
import { AddParticipantForm, AddResultsForm, ParticipantItem } from './types';

const { Column } = Table;

const AddResults:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const { runID } = runs.modals.addResults;
    const currentRun = runs.data ? runs.data.filter((run) => run.id === runID) : [];
    const participant = currentRun.length ? currentRun[0].participant : [];

    useEffect(() => {
        dispatch(getRunsUsersAsyncAction());
    }, []);

    useEffect(() => {
        if (runID) {
            participant.forEach((item) => {
                form.setFields([{ name: `result_${item.id}`, value: item.time ? item.time : undefined }]);
            });
        }
    }, [runID]);

    const onFinish = (values: any) => {
        let results: AddResultsForm[] = [];

        Object.entries(values).forEach((item) => {
            const resultID = +item[0].split('_')[1];
            const value = item[1] !== undefined ? item[1] as string : null;

            results = [...results, {
                id: resultID,
                time: value && value.length ? value : null
            }];
        });

        dispatch(addResultsRunAsyncAction(results, runID));
    };

    return (
        <>
            <Modal
                centered
                visible={runs.modals.addResults.visible}
                onCancel={() => dispatch(switchResultsModalAction(0))}
                onOk={() => form.submit()}
                title="Добавить результаты забега"
                width={700}
                className="runs__modal add-results"
                confirmLoading={runs.modals.addResults.loading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Table dataSource={participant} pagination={false} rowKey="id">
                        <Column
                            title="Дистанция"
                            render={(_, item: ParticipantItem) => {
                                if (currentRun.length && item.distance_key !== null) {
                                    return currentRun[0].distances[item.distance_key].name;
                                }
                                return '-';
                            }}
                        />
                        <Column title="Участник" dataIndex="name" />
                        <Column title="Дата рождения" dataIndex="birthday" render={(value) => (value ? moment(value).format('DD.MM.YYYY') : '-')} />
                        <Column
                            title="Время"
                            dataIndex="time"
                            render={(_, record: ParticipantItem) => (
                                <Form.Item
                                    name={`result_${record.id}`}
                                >
                                    <Input placeholder="00:00:00" />
                                </Form.Item>
                            )}
                        />
                    </Table>
                </Form>
            </Modal>
        </>
    );
};

export default AddResults;
