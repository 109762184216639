import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import {
    AuthActionsList,
    AuthStateType,
    SignUpFormData,
    SignInFormData,
    AccountData,
    SignInTokenData,
    AuthActionsType
} from './types';
import { notification } from '../../utils';
import { API } from '../..';

export const errorAuthAction = (error: string): AuthActionsType => ({
    type: AuthActionsList.ERROR_AUTH,
    error
});

// SIGN IN ACOOUNT

export const requestSignInAction = (): AuthActionsType => ({
    type: AuthActionsList.REQUEST_SIGNIN_AUTH
});

export const responseAuthAction = (data: AccountData): AuthActionsType => ({
    type: AuthActionsList.RESPONSE_SIGNIN_AUTH,
    data
});

export const signInAsyncAction = (data: SignInFormData | SignInTokenData) => async (dispatch: ThunkDispatch<AuthStateType, void, Action>): Promise<void> => {
    dispatch(requestSignInAction());

    const formData = new FormData();

    if ((<SignInTokenData>data).token) {
        formData.append('token', (<SignInTokenData>data).token);
    } else {
        formData.append('login', (<SignInFormData>data).login);
        formData.append('password', (<SignInFormData>data).password);
    }

    const result = await axios.post(`${API}api/v1/auth`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorAuthAction(response.message));
    } else {
        dispatch(responseAuthAction(response.data));
    }
};

// SIGN OUT ACCOUNT

export const requestSignOutAction = (): AuthActionsType => ({
    type: AuthActionsList.REQUEST_SIGNOUT_AUTH
});

export const responseSignOutAction = (): AuthActionsType => ({
    type: AuthActionsList.RESPONSE_SIGNOUT_AUTH
});

// REGESTRATION

export const signUpAction = (data: SignUpFormData) => async (dispatch: ThunkDispatch<AuthStateType, void, Action>) => {
    dispatch({ type: AuthActionsList.REQUEST_SIGNUP_AUTH });

    const formData = new FormData();
    formData.append('login', data.login);
    formData.append('password', data.password);
    formData.append('type', data.type);
    formData.append('code', data.code);

    const result = await axios.post(`${API}api/v1/registration`, formData);

    const response = result.data;

    if (response.error === 0) {
        dispatch(responseAuthAction(response.data));
    } else {
        dispatch(errorAuthAction(response.message));
        notification('error', 'Ошибка', response.message);
    }
};

// LOGOUT

export const logoutAction = (): AuthActionsType => ({
    type: AuthActionsList.LOGOUT_AUTH
});
