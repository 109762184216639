import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Row, Col, Form, Input, Select, DatePicker, Upload, InputNumber
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AppState } from '../../store';
import { addBadgeAsyncAction, switchAddModalAction } from './actions';
import { AddBadgeForm } from './types';

const AddBadge:React.FC = () => {
    const dispatch = useDispatch();
    const badges = useSelector((state: AppState) => state.badges);
    const [form] = Form.useForm();
    const [previewModal, setPreviewModal] = useState({
        image: '',
        visible: false,
        title: ''
    });
    const [conditionsType, setConditionsType] = useState<string | undefined>(undefined);
    const [conditionsSubType, setConditionsSubType] = useState<string | undefined>(undefined);
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

    useEffect(() => {
        if (!badges.modals.create) {
            form.resetFields();
        }
    }, [badges.modals.create]);

    useEffect(() => {
        form.resetFields(['conditionsValue', 'conditionsSubType']);
    }, [conditionsType]);

    useEffect(() => {
        form.resetFields(['conditionsValue']);
    }, [conditionsSubType]);

    const handlePreview = async (file: UploadFile<unknown>) => {
        if (file.thumbUrl) {
            setPreviewModal({
                image: file.thumbUrl,
                title: file.name,
                visible: true
            });
        }
    };

    const onSend = (values: AddBadgeForm) => {
        dispatch(addBadgeAsyncAction({
            ...values,
            icon: fileList.length && fileList[0].originFileObj ? fileList[0].originFileObj : null
        }));
    };

    return (
        <>
            <Modal
                title="Добавить бейдж"
                centered
                visible={badges.modals.create}
                onCancel={() => dispatch(switchAddModalAction())}
                width={1150}
                forceRender
                footer={[
                    <Button
                        key="send"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={badges.loading}
                    >
                        Создать
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSend}
                >
                    <Row gutter={15}>
                        <Col lg={12}>
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: 'Введите название' }]}
                                label="Название"
                            >
                                <Input placeholder="Введите название" />
                            </Form.Item>
                            <Form.Item
                                name="level"
                                rules={[{ required: true, message: 'Введите подзаголовок' }]}
                                label="Уровень"
                            >
                                <Select
                                    placeholder="Выберите урвоень"
                                >
                                    <Select.Option value="gold">Золотой</Select.Option>
                                    <Select.Option value="silver">Серебряный</Select.Option>
                                    <Select.Option value="bronze">Бронзовый</Select.Option>
                                    <Select.Option value="unique">Уникальный</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="period"
                                label="Период"
                                rules={[{ required: true, message: 'Выберите период' }]}
                            >
                                <DatePicker.RangePicker
                                    placeholder={['Начало', 'Конец']}
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item
                                name="icon"
                                label="Изображение бейджа"
                                rules={[{ required: true, message: 'Выберите изображение' }]}
                            >
                                <Upload
                                    accept=".jpg,.jpeg,.png,.svg"
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={(info: UploadChangeParam) => setFileList([...fileList, ...info.fileList])}
                                    onRemove={() => { setFileList([]); return false; }}
                                >
                                    {fileList.length < 1 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Загрузить</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                name="conditionsType"
                                rules={[{ required: true, message: 'Выберите тип условия' }]}
                                label="Тип условия"
                            >
                                <Select
                                    placeholder="Выберите тип условия"
                                    onChange={(value: string) => {
                                        form.resetFields(['conditionsValue', 'conditionsSubType']);
                                        setConditionsType(value);
                                    }}
                                >
                                    <Select.Option value="distance">Дистанция</Select.Option>
                                    <Select.Option value="time">Время</Select.Option>
                                    <Select.Option value="run">Забег</Select.Option>
                                    <Select.Option value="volunteering">Волонтёрство</Select.Option>
                                    <Select.Option value="combo">Серия пробежек</Select.Option>
                                </Select>
                            </Form.Item>
                            {conditionsType === 'distance' && (
                                <Form.Item
                                    name="conditionsValue"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Дистанция, км"
                                >
                                    <InputNumber min={1} placeholder="Введите дистанцию" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {conditionsType === 'time' && (
                                <Form.Item
                                    name="conditionsValue"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Время, мин"
                                >
                                    <InputNumber min={1} placeholder="Введите время" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {conditionsType === 'run' && (
                                <>
                                    <Form.Item
                                        name="conditionsSubType"
                                        rules={[{ required: true, message: 'Заполните поле' }]}
                                        label="Условие для забега"
                                    >
                                        <Select
                                            placeholder="Выберите условие"
                                            onChange={(value: string) => {
                                                form.resetFields(['conditionsValue']);
                                                setConditionsSubType(value);
                                            }}
                                        >
                                            <Select.Option value="caterory">Категория</Select.Option>
                                            <Select.Option value="organizer">Организатор</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    {conditionsSubType === 'caterory' && (
                                        <Form.Item
                                            name="conditionsValue"
                                            rules={[{ required: true, message: 'Заполните поле' }]}
                                            label="Категория"
                                        >
                                            <Select
                                                placeholder="Выберите категорию"
                                            >
                                                <Select.Option value="Бег">Бег</Select.Option>
                                                <Select.Option value="Триатлон">Триатлон</Select.Option>
                                                <Select.Option value="Велосипед">Велосипед</Select.Option>
                                                <Select.Option value="Плаванье">Плаванье</Select.Option>
                                                <Select.Option value="Лыжи">Лыжи</Select.Option>
                                                <Select.Option value="OCR-гонки">OCR-гонки</Select.Option>
                                                <Select.Option value="Прочее">Прочее</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {conditionsSubType === 'organizer' && (
                                        <Form.Item
                                            name="conditionsValue"
                                            rules={[{ required: true, message: 'Заполните поле' }]}
                                            label="Организатор"
                                        >
                                            <Input placeholder="Введите организатора" />
                                        </Form.Item>
                                    )}
                                </>
                            )}
                            {conditionsType === 'combo' && (
                                <Form.Item
                                    name="conditionsValue"
                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                    label="Кол-во дней"
                                    tooltip="Кол-во дней ддя непрерывной серии пробежек"
                                >
                                    <InputNumber min={1} placeholder="15" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                visible={previewModal.visible}
                title={previewModal.title}
                footer={null}
                onCancel={() => setPreviewModal({ ...previewModal, visible: false })}
                centered
            >
                <img style={{ width: '100%' }} src={previewModal.image} alt={previewModal.title} />
            </Modal>
        </>
    );
};

export default AddBadge;
