/* eslint-disable camelcase */
import { Moment } from 'moment';

// DATA ITEMS TYPES //

export interface ProfileItem {
    email: string
    birthday: Moment | null
    city: string | null
    country: string | null
    gender: string | null
    phone: string | null,
    avatar: string | null,
    stravaID: number | null,
    stravaName: string | null
}

// DATA FROM SERVER //

export interface ProfileItemFromServer {
    login: string
    birthday: string | null
    city: string | null
    country: string | null
    gender: string | null
    phone: string | null,
    avatar: string | null,
    strava_id: number | null,
    strava_name: string | null
}

// DATA FORMS TYPES //

export interface ProfileForm {
    birthday: Moment | undefined
    city: string | undefined
    country: string | undefined
    email: string | undefined
    gender: string | undefined
    phone: string | undefined
}

// STORE TYPE //

export interface ProfileStateType {
    data: ProfileItem | null,
    edit: boolean,
    save: boolean,
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum ProfileActionsList {
    ERROR_PROFILE = 'ERROR_PROFILE',
    EDIT_PROFILE = 'EDIT_PROFILE',
    SAVE_PROFILE = 'SAVE_PROFILE',
    REQUEST_GET_PROFILE = 'REQUEST_GET_PROFILE',
    RESPONSE_GET_PROFILE = 'RESPONSE_GET_PROFILE',
    REQUEST_SAVE_PROFILE = 'REQUEST_SAVE_PROFILE',
    RESPONSE_SAVE_PROFILE = 'RESPONSE_SAVE_PROFILE',
    REQUEST_UPLOAD_AVATAR_PROFILE = 'REQUEST_UPLOAD_AVATAR_PROFILE',
    RESPONSE_UPLOAD_AVATAR_PROFILE = 'RESPONSE_UPLOAD_AVATAR_PROFILE'
}

// ERROR //

export interface ErrorProfileActionType {
    type: ProfileActionsList.ERROR_PROFILE,
    error: string
}

// EDIT //

export interface EditProfileActionType {
    type: ProfileActionsList.EDIT_PROFILE
}

export interface RequestSaveProfileActionType {
    type: ProfileActionsList.REQUEST_SAVE_PROFILE
}

export interface ResponseSaveProfileActionType {
    type: ProfileActionsList.RESPONSE_SAVE_PROFILE,
    data: ProfileItem
}

export interface RequestUploadAvatarProfileActionType {
    type: ProfileActionsList.REQUEST_UPLOAD_AVATAR_PROFILE
}

export interface ResponseUploadAvatarProfileActionType {
    type: ProfileActionsList.RESPONSE_UPLOAD_AVATAR_PROFILE,
    data: string
}

// SAVE //

export interface SaveProfileActionType {
    type: ProfileActionsList.SAVE_PROFILE
}

export interface RequestGetProfileActionType {
    type: ProfileActionsList.REQUEST_GET_PROFILE
}

export interface ResponseGetProfileActionType {
    type: ProfileActionsList.RESPONSE_GET_PROFILE,
    data: ProfileItem
}

export type ProfileActionsType =
ErrorProfileActionType
| EditProfileActionType
| SaveProfileActionType
| RequestGetProfileActionType
| ResponseGetProfileActionType
| RequestSaveProfileActionType
| ResponseSaveProfileActionType
| RequestUploadAvatarProfileActionType
| ResponseUploadAvatarProfileActionType;
