import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table, Tag, Space, Button, Breadcrumb, Modal, Result
} from 'antd';
import moment, { Moment } from 'moment';
import {
    CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, HomeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
import { NewsItemData } from './types';
import {
    getNewsAsyncAction, publishNewAsyncAction, removeNewAsyncAction, switchAddModalAction, switchEditModalAction, unpublishNewAsyncAction
} from './actions';
import AddNew from './AddNew';
import EditNew from './EditNew';
import Preloader from '../../components/Preloader';

const { Column } = Table;

const HeaderNewsPage:React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="page__header">
            <Breadcrumb>
                <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Новости</Breadcrumb.Item>
            </Breadcrumb>
            <Button type="primary" onClick={() => dispatch(switchAddModalAction())}>Добавить новость</Button>
        </div>
    );
};

const NewsPage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const news = useSelector((state: AppState) => state.news);

    useEffect(() => {
        dispatch(getNewsAsyncAction());
    }, []);

    const editNew = (id: number) => {
        history.push(`/news/edit/${id}`);
        dispatch(switchEditModalAction());
    };

    const visibleNew = (type: string, id: number) => {
        if (type === 'publish') {
            dispatch(publishNewAsyncAction(id));
        } else if (type === 'unpublish') {
            dispatch(unpublishNewAsyncAction(id));
        }
    };

    const confirm = (id: number) => {
        Modal.confirm({
            title: '',
            icon: null,
            content: (
                <Result
                    status="warning"
                    title="Вы уверены что хотите удалить новость?"
                />
            ),
            okText: 'Подтвердить',
            cancelText: 'Отменить',
            centered: true,
            width: 685,
            className: 'app__confirm',
            onOk: () => dispatch(removeNewAsyncAction(id))
        });
    };

    if (news.data === null) {
        return <Preloader />;
    }

    return (
        <section className="news page">
            <Table dataSource={news.data} pagination={false} rowKey="id">
                <Column title="Название" dataIndex="title" />
                <Column title="Дата" dataIndex="publish" render={(value: Moment | null) => (value ? value.format('DD.MM.YYYY') : null)} />
                <Column
                    title="Категория"
                    dataIndex="categories"
                    render={(categories: string[]) => (
                        <>
                            {categories.map((category) => {
                                let name = '';
                                switch (category) {
                                    case 'runs': name = 'Забеги'; break;
                                    case 'cup': name = 'Кубок'; break;
                                    default: name = '';
                                }

                                return (
                                    <Tag key={category}>
                                        {name}
                                    </Tag>
                                );
                            })}
                        </>
                    )}
                />
                <Column
                    title="Действия"
                    render={(item: NewsItemData) => (
                        <Space size="middle">
                            <Button onClick={() => editNew(item.id)}>
                                <EditOutlined />
                            </Button>
                            <Button onClick={() => visibleNew(item.publish ? 'unpublish' : 'publish', item.id)}>
                                {item.publish ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </Button>
                            <Button danger onClick={() => confirm(item.id)}>
                                <DeleteOutlined />
                            </Button>
                        </Space>
                    )}
                />
            </Table>
            <AddNew />
            <EditNew />
        </section>
    );
};

export default WrapperPage(NewsPage, HeaderNewsPage);
