import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    NewsStateType,
    NewsActionsType,
    NewsActionsList,
    NewsItemData,
    AddNewFormData,
    NewItemDataFromServer,
    EditNewFormData
} from './types';

export const errorNewsAction = (error: string): NewsActionsType => ({
    type: NewsActionsList.ERROR_NEWS,
    error
});

// MODALS ACTIONS //

export const switchAddModalAction = (): NewsActionsType => ({
    type: NewsActionsList.MODAL_ADD_NEWS
});

export const switchEditModalAction = (): NewsActionsType => ({
    type: NewsActionsList.MODAL_EDIT_NEWS
});

// ADD NEW ACTIONS //

export const requestAddNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_ADD_NEWS
});

export const responseAddNewsAction = (data: NewsItemData): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_ADD_NEWS,
    data
});

export const addNewAsyncAction = (data: AddNewFormData) => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestAddNewsAction());

    const formData = new FormData();

    formData.append('categories', JSON.stringify(data.categories));
    formData.append('publish', data.publish ? data.publish.format('X') : '');
    formData.append('title', data.title);
    formData.append('subtitle', data.subtitle);
    formData.append('text', data.text);
    if (data.image) {
        formData.append('file', data.image as Blob);
    }

    const result = await axios.post(`${API}api/v1/news`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const newData:NewsItemData = {
            id: response.data.id,
            title: data.title,
            subtitle: data.subtitle,
            text: data.text,
            categories: data.categories,
            publish: data.publish,
            image: data.image ? response.data.imagePath : null
        };

        dispatch(responseAddNewsAction(newData));
        notification('success', 'Успех', 'Новость добавлена');
    }
};

// EDIT NEW ACTIONS //

export const requestEditNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_EDIT_NEWS
});

export const responseEditNewsAction = (data: NewsItemData): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_EDIT_NEWS,
    data
});

export const editNewAsyncAction = (data: EditNewFormData) => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestEditNewsAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('categories', JSON.stringify(data.categories));
    formData.append('publish', data.publish ? data.publish.format('X') : '');
    formData.append('title', data.title);
    formData.append('subtitle', data.subtitle);
    formData.append('text', data.text);
    if (data.image) {
        formData.append('file', data.image as Blob);
    }

    const result = await axios.post(`${API}api/v1/news/edit`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const newData:NewsItemData = {
            id: response.data.id,
            title: data.title,
            subtitle: data.subtitle,
            text: data.text,
            categories: data.categories,
            publish: data.publish,
            image: data.image ? response.data.imagePath : null
        };

        dispatch(responseEditNewsAction(newData));
        notification('success', 'Успех', 'Новость обновлена');
    }
};

// REMOVE NEW ACTIONS //

export const requestRemoveNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_REMOVE_NEWS
});

export const responseRemoveNewsAction = (id: number): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_REMOVE_NEWS,
    id
});

export const removeNewAsyncAction = (id: number) => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestRemoveNewsAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/news/remove`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseRemoveNewsAction(id));
        notification('success', 'Успех', 'Новость удалена');
    }
};

// UNPUBLISH NEW ACTIONS //

export const requestUnpublishNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_UNPUBLISH_NEWS
});

export const responseUnpublishNewsAction = (id: number): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_UNPUBLISH_NEWS,
    id
});

export const unpublishNewAsyncAction = (id: number) => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestUnpublishNewsAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/news/unpublish`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseUnpublishNewsAction(id));
        notification('success', 'Успех', 'Новость скрыта');
    }
};

// UNPUBLISH NEW ACTIONS //

export const requestPublishNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_PUBLISH_NEWS
});

export const responsePublishNewsAction = (id: number, publish: Moment): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_PUBLISH_NEWS,
    id,
    publish
});

export const publishNewAsyncAction = (id: number) => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestPublishNewsAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/news/publish`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responsePublishNewsAction(id, moment(response.data.publish)));
        notification('success', 'Успех', 'Новость опубликована');
    }
};

// GET NEWS ACTIONS //

export const requestGetNewsAction = (): NewsActionsType => ({
    type: NewsActionsList.REQUEST_GET_NEWS
});

export const responseGetNewsAction = (data: NewsItemData[]): NewsActionsType => ({
    type: NewsActionsList.RESPONSE_GET_NEWS,
    data
});

export const getNewsAsyncAction = () => async (dispatch: ThunkDispatch<NewsStateType, void, Action>): Promise<void> => {
    dispatch(requestGetNewsAction());

    const result = await axios.get(`${API}api/v1/news`);
    const response = result.data;

    if (response.error) {
        dispatch(errorNewsAction(response.message));
    } else {
        let news: NewsItemData[] | [] = [];
        <NewItemDataFromServer>response.data.forEach((item: NewItemDataFromServer) => {
            news = [...news, {
                id: item.id,
                title: item.title,
                subtitle: item.subtitle,
                text: item.text,
                categories: JSON.parse(item.categories),
                publish: item.publish ? moment(item.publish) : null,
                image: item.image ? item.image : ''
            }];
        });

        dispatch(responseGetNewsAction(news));
    }
};
