import { RcFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';

// DATA ITEMS TYPES //

export interface BadgeItemConditions {
    type: 'distance' | 'time' | 'run' | 'volunteering' | 'combo' | undefined,
    subType: 'category' | 'organizer' | undefined,
    value: string | number | undefined
}

export interface BadgeItem {
    id: number,
    title: string | undefined,
    level: 'gold' | 'silver' | 'bronze' | 'unique' | undefined,
    period: Moment[] | undefined
    icon: RcFile | string | undefined,
    conditions: BadgeItemConditions
}

// DATA FROM SERVER //

export interface BadgeItemFromServer {
    id: number,
    title: string,
    level: 'gold' | 'silver' | 'bronze' | 'unique',
    periodStart: string,
    periodEnd: string,
    icon: string,
    conditions: string
}

// DATA FORMS TYPES //

export interface AddBadgeForm {
    title: string,
    level: 'gold' | 'silver' | 'bronze' | 'unique',
    period: Moment[],
    icon: RcFile | null,
    conditionsType: 'distance' | 'time' | 'run' | 'volunteering' | 'combo',
    conditionsSubType?: 'category' | 'organizer',
    conditionsValue: string | number
}

export interface EditBadgeForm {
    id: number,
    title: string,
    level: 'gold' | 'silver' | 'bronze' | 'unique',
    period: Moment[],
    icon: RcFile | null,
    conditionsType: 'distance' | 'time' | 'run' | 'volunteering' | 'combo',
    conditionsSubType?: 'category' | 'organizer',
    conditionsValue: string | number
}

// STORE TYPE //

export interface BadgesStateType {
    data: BadgeItem[] | null,
    modals: {
        create: boolean,
        edit: boolean
    }
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum BadgesActionsList {
    ERROR_BADGES = 'ERROR_BADGES',
    REQUEST_GET_BADGES = 'REQUEST_GET_BADGES',
    RESPONSE_GET_BADGES = 'RESPONSE_GET_BADGES',
    REQUEST_ADD_BADGES = 'REQUEST_ADD_BADGES',
    RESPONSE_ADD_BADGES = 'RESPONSE_ADD_BADGES',
    REQUEST_EDIT_BADGES = 'REQUEST_EDIT_BADGES',
    RESPONSE_EDIT_BADGES = 'RESPONSE_EDIT_BADGES',
    REQUEST_REMOVE_BADGES = 'REQUEST_REMOVE_BADGES',
    RESPONSE_REMOVE_BADGES = 'RESPONSE_REMOVE_BADGES',
    MODAL_ADD_BADGES = 'MODAL_ADD_BADGES',
    MODAL_EDIT_BADGES = 'MODAL_EDIT_BADGES'
}

// ERROR //

export interface ErrorBadgesActionType {
    type: BadgesActionsList.ERROR_BADGES,
    error: string
}

// GET TYPES //

export interface RequestGetBadgesActionType {
    type: BadgesActionsList.REQUEST_GET_BADGES
}

export interface ResponseGetBadgesActionType {
    type: BadgesActionsList.RESPONSE_GET_BADGES,
    data: BadgeItem[]
}

// ADD TYPES //

export interface RequestAddNewActionType {
    type: BadgesActionsList.REQUEST_ADD_BADGES
}

export interface ResponseAddNewActionType {
    type: BadgesActionsList.RESPONSE_ADD_BADGES,
    data: BadgeItem
}

// EDIT TYPES //

export interface RequestEditNewActionType {
    type: BadgesActionsList.REQUEST_EDIT_BADGES
}

export interface ResponseEditNewActionType {
    type: BadgesActionsList.RESPONSE_EDIT_BADGES,
    data: BadgeItem
}

// REMOVE TYPES //

export interface RequestRemoveNewActionType {
    type: BadgesActionsList.REQUEST_REMOVE_BADGES
}

export interface ResponseRemoveNewActionType {
    type: BadgesActionsList.RESPONSE_REMOVE_BADGES,
    id: number
}

// SWITCH MODALS //

export interface SwitchModalAddActionType {
    type: BadgesActionsList.MODAL_ADD_BADGES
}

export interface SwitchModalEditActionType {
    type: BadgesActionsList.MODAL_EDIT_BADGES
}

export type BadgesActionsType =
ErrorBadgesActionType
| RequestGetBadgesActionType
| ResponseGetBadgesActionType
| RequestAddNewActionType
| ResponseAddNewActionType
| RequestEditNewActionType
| ResponseEditNewActionType
| RequestRemoveNewActionType
| ResponseRemoveNewActionType
| SwitchModalAddActionType
| SwitchModalEditActionType;
