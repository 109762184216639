import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Row, Col, Form, Input, Select, DatePicker, Upload, Typography, TimePicker
} from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';

import { AppState } from '../../../store';
import CustomUpload from '../../../components/CustomUpload';
import { changeStepFormRunsAction, changeStepRunsAction } from '../actions';
import { AddRunFormContext } from '../AddRun';
import { EditRunFormContext } from '../EditRun';
import { API } from '../../..';

const { Title } = Typography;

/*
    organizer: {
        name: string,
        email?: string,
        phone?: string,
        site?: string
    },
    howToGet: string,
    whereToLive: string,
    otherInfo: string
*/

const Contacts:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const { createItem } = runs.modals.create ? useContext(AddRunFormContext) : useContext(EditRunFormContext);

    useEffect(() => {
        if (createItem) {
            form.setFields([{ name: 'organizerName', value: createItem.organizer.name }]);
            form.setFields([{ name: 'organizerEmail', value: createItem.organizer.email }]);
            form.setFields([{ name: 'organizerSite', value: createItem.organizer.site }]);
            form.setFields([{ name: 'organizerPhone', value: createItem.organizer.phone }]);
            form.setFields([{ name: 'howToGet', value: createItem.howToGet }]);
            form.setFields([{ name: 'whereToLive', value: createItem.whereToLive }]);
            form.setFields([{ name: 'otherInfo', value: createItem.otherInfo }]);

            if (createItem?.organizer.attachments !== undefined) {
                let fileList: UploadFile[] = [];
                createItem?.organizer.attachments.forEach((item) => {
                    if (typeof item === 'string') {
                        const filePath = item.split('/');
                        const fileName = filePath[filePath.length - 1];
                        fileList = [...fileList, {
                            uid: '0',
                            name: fileName,
                            thumbUrl: `${API}${item}`
                        }];
                    } else {
                        fileList = [...fileList, item as UploadFile];
                    }
                });

                form.setFields([{
                    name: 'attachmentsContacts',
                    value: fileList
                }]);
            } else {
                form.setFields([{
                    name: 'attachmentsContacts',
                    value: undefined
                }]);
            }
            // form.setFields([{ name: 'attachmentsContacts', value: createItem?.organizer.attachments === undefined ? undefined : createItem.organizer.attachments as UploadFile[] }]);
        }
    }, []);

    useEffect(() => {
        if (runs.step === 1) {
            dispatch(changeStepFormRunsAction(form));
        }
    }, [runs.step]);

    const onFinish = (values: any) => {
        if (createItem) {
            Object.keys(values).forEach((key) => {
                switch (key) {
                    case 'organizerName': createItem.organizer.name = values[key]; break;
                    case 'organizerEmail': createItem.organizer.email = values[key]; break;
                    case 'organizerPhone': createItem.organizer.phone = values[key]; break;
                    case 'organizerSite': createItem.organizer.site = values[key]; break;
                    case 'attachmentsContacts': {
                        if (values[key]) {
                            const field: UploadChangeParam | RcFile[] = values[key];
                            const fileList: RcFile[] = [];
                            if (Array.isArray(field)) {
                                field.forEach((item) => {
                                    if (item) {
                                        fileList.push(item);
                                    }
                                });
                            } else {
                                field.fileList.forEach((item) => {
                                    if (item.originFileObj) {
                                        fileList.push(item.originFileObj);
                                    }
                                });
                            }

                            createItem.organizer.attachments = fileList;
                        } else {
                            createItem.organizer.attachments = values[key];
                        }
                        break;
                    }
                    case 'howToGet': createItem.howToGet = values[key]; break;
                    case 'whereToLive': createItem.whereToLive = values[key]; break;
                    case 'otherInfo': createItem.otherInfo = values[key]; break;
                    default:
                        break;
                }
            });

            if (runs.modals.create) {
                dispatch(changeStepRunsAction('next'));
            }
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={15}>
                <Col lg={24}>
                    <Title level={3}>Шаг 2: Контакты</Title>
                </Col>
                <Col lg={12}>
                    <Form.Item
                        name="organizerName"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Контакт организатора"
                    >
                        <Input placeholder="Ответственное лицо" />
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item
                                name="organizerEmail"
                                label="Место"
                            >
                                <Input placeholder="Введите почту" />
                            </Form.Item>
                            <Form.Item
                                name="organizerSite"
                                label="Сайт"
                            >
                                <Input placeholder="Адрес сайта" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="organizerPhone"
                                label="Телефон"
                            >
                                <Input placeholder="Введите номер" />
                            </Form.Item>
                            <Form.Item
                                name="attachmentsContacts"
                                label="Вложения"
                            >
                                <CustomUpload
                                    form={form}
                                    name="attachmentsContacts"
                                    listType="text"
                                    multiple
                                    accept=".jpg,.doc,.docs,.xls"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Form.Item
                        name="howToGet"
                        rules={[{ required: false }]}
                        label="Как добраться"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="whereToLive"
                        rules={[{ required: false }]}
                        label="Где жить"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="otherInfo"
                        rules={[{ required: false }]}
                        label="Прочая информация"
                    >
                        <Input.TextArea placeholder="Введите текст" rows={5} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Contacts;
