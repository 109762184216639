/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Layout,
    Typography,
    Menu,
    Avatar,
    Button,
    Dropdown
} from 'antd';
import Icon, {
    CalendarOutlined,
    FileDoneOutlined,
    LaptopOutlined,
    LogoutOutlined,
    NotificationOutlined,
    StarOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import './style.scss';
import routes from '../../routes';
import { AppState } from '../../store';
import { ReactComponent as BadgeSvg } from '../../assets/badge.svg';
import { ReactComponent as CupSvg } from '../../assets/cup.svg';
import { ReactComponent as TaskSvg } from '../../assets/task.svg';
import { ReactComponent as RunnerSvg } from '../../assets/runner.svg';
import { ReactComponent as SiteSvg } from '../../assets/browser-website.svg';
import { ReactComponent as PulseSvg } from '../../assets/pulse.svg';
import { logoutAction } from '../../pages/Auth/actions';

const { Title } = Typography;
const { SubMenu } = Menu;
const {
    Header, Content, Sider
} = Layout;
const companyName = 'athletica.space';

const isMobile = window.matchMedia('(max-width: 991px)').matches;

const adminMenu = (
    <>
        <SubMenu
            key="site"
            icon={<Icon component={SiteSvg} />}
            title="Сайт"
        >
            <Menu.Item key="/news">
                <Link to="/news">Новости</Link>
            </Menu.Item>
        </SubMenu>
        <Menu.Item key="/runs" icon={<Icon component={RunnerSvg} />}>
            <Link to="/runs">Забеги</Link>
        </Menu.Item>
        <Menu.Item key="/badges" icon={<Icon component={BadgeSvg} />}>
            <Link to="/badges">Бейджи</Link>
        </Menu.Item>
        <Menu.Item key="/tasks" icon={<Icon component={TaskSvg} />}>
            <Link to="/tasks">Задания кубков</Link>
        </Menu.Item>
        <Menu.Item key="/cups" icon={<Icon component={CupSvg} />}>
            <Link to="/cups">Кубки</Link>
        </Menu.Item>
    </>
);

const userMenu = (
    <>
        <Menu.Item key="/profile" icon={<UserOutlined />}>
            <Link to="/profile">Профиль</Link>
        </Menu.Item>
        <Menu.Item key="/workout" icon={<Icon component={PulseSvg} />}>
            <Link to="/workout">Тренировки</Link>
        </Menu.Item>
        <Menu.Item key="/calendar" icon={<CalendarOutlined />}>
            <Link to="/calendar">Календарь забегов</Link>
        </Menu.Item>
        <Menu.Item key="/rating" icon={<StarOutlined />}>
            <Link to="/rating">Рейтинг</Link>
        </Menu.Item>
    </>
);

const WrapperPage = <P extends Record<string, unknown>>(
    PageContent: React.ComponentType<P>,
    HeaderContent?: React.ComponentType
) => (props: P) : JSX.Element => {
        const [collapsed, onCollapse] = useState(isMobile);
        const [pageName, changePagename] = useState(companyName);
        const [pageNameClass, changePageNameClass] = useState('');
        const dispatch = useDispatch();
        const location = useLocation();
        const history = useHistory();
        const auth = useSelector((state: AppState) => state.auth);
        const workout = useSelector((state: AppState) => state.workout);
        const pathnameArray = location.pathname.split('/');
        const currentRootPath = `/${pathnameArray[1]}`;
        let menu: JSX.Element | null = null;

        useEffect(() => {
            const currentPage = routes.filter((item) => location.pathname.indexOf(item.path[0]) !== -1)[0];

            if (currentPage.nameInPage !== undefined) {
                document.title = `${currentPage.nameInPage} | athletica.space`;
                changePagename(currentPage.nameInPage);
            } else {
                document.title = companyName;
                changePagename(companyName);
            }
        }, [location]);

        const logout = () => {
            dispatch(logoutAction());
            history.push('/sign-in');
        };

        if (auth.data && auth.data.role) {
            switch (auth.data.role) {
                case 'admin':
                    menu = adminMenu;
                    break;
                case 'user':
                    menu = userMenu;
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <Layout>
                    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                        <div className="logo" style={{ backgroundImage: "url('assets/logo-mini.svg')" }} />
                        <Menu
                            mode="inline"
                            selectedKeys={[currentRootPath]}
                            style={{ height: '100%', borderRight: 0 }}
                            defaultOpenKeys={['/news'].includes(currentRootPath) ? ['site'] : []}
                        >
                            {menu}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout layout">
                        <Header className="site-layout-background header">
                            <Title level={3}>{pageName}</Title>
                            <div className="header__right">
                                <Dropdown
                                    overlay={(
                                        <Menu>
                                            <Menu.Item key="logout" danger icon={<LogoutOutlined />} onClick={() => logout()}>
                                                Выйти
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                    placement="bottomRight"
                                >
                                    <div className="user">
                                        <Avatar
                                            style={{ backgroundColor: '#abcf42' }}
                                            icon={<UserOutlined />}
                                        />
                                        <span>{auth.data ? auth.data.login.split('@')[0] : ''}</span>
                                    </div>
                                </Dropdown>
                            </div>
                        </Header>
                        <Content className="app__layout">
                            {HeaderContent && <HeaderContent />}
                            <div
                                className={`site-layout-background page__wrapper ${pageNameClass}`}
                                style={{ overflow: workout.loading ? 'hidden' : 'auto' }}
                            >
                                <PageContent {...props as P} />
                            </div>
                            <div className="page__footer">
                                <a href="#">athletica.space</a> © {moment().format('YYYY')}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </>
        );
    };

export default WrapperPage;
