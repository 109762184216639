/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Row, Col, Form, DatePicker, Typography, InputNumber, Space, Tabs
} from 'antd';
import { MinusOutlined } from '@ant-design/icons';

import { AppState } from '../../../store';
import { AddRunFormContext } from '../AddRun';
import { changeStepFormRunsAction, changeStepRunsAction } from '../actions';
import { PaymentItem, PaymentPriceByDateItem, PaymentPriceBySlotsItem } from '../types';
import { EditRunFormContext } from '../EditRun';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

/*
    name: string,
    mileage: number,
    slots: number,
    startTime: Moment,
    finishTime: Moment,
    categories: string[],
    prizeCategories: string[],
    foodOutlets: number,
    description: string,
    requirements: string,
    expo: string,
    route: {
        text: string,
        jpx: string
    }
*/

const Payment:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const { createItem } = runs.modals.create ? useContext(AddRunFormContext) : useContext(EditRunFormContext);
    const [activeKey, setActiveKey] = useState('0');
    const [payments, setPayments] = useState<PaymentItem[]>([]);

    const setFieldsTab = (key: number, newPayments?: PaymentItem[] | undefined) => {
        const itemPayments = newPayments || payments;

        form.resetFields();
        form.setFields([
            {
                name: 'basePricePayment',
                value: itemPayments[key].basePrice
            },
            ...itemPayments[key].priceByDate.map((itemPrice, i) => ({
                name: `priceDatePayment-${i}`,
                value: itemPrice.price
            })),
            ...itemPayments[key].priceByDate.map((itemPrice, i) => ({
                name: `datePayment-${i}`,
                value: itemPrice.date
            })),
            ...itemPayments[key].priceBySlots.map((itemPrice, i) => ({
                name: `priceSlotsPayment-${i}`,
                value: itemPrice.price
            })),
            ...itemPayments[key].priceBySlots.map((itemPrice, i) => ({
                name: `slotsPayment-${i}`,
                value: itemPrice.slots
            }))
        ]);
    };

    useEffect(() => {
        if (createItem?.payments.length) {
            const newPayments = createItem.payments.filter((payment) => createItem?.distances[+payment.distanceKey]);

            if (createItem?.payments.length < createItem?.distances.length) {
                createItem?.distances.forEach((_, i) => {
                    const isFound = createItem.payments.filter((payment) => +payment.distanceKey === i).length;

                    if (!isFound) {
                        newPayments.push({
                            distanceKey: i.toString(),
                            basePrice: undefined,
                            priceByDate: [{ date: undefined, price: undefined }],
                            priceBySlots: [{ slots: undefined, price: undefined }]
                        });
                    }
                });
            }

            setPayments(newPayments);
            setActiveKey('0');
            setFieldsTab(0, createItem.payments);
        } else if (createItem?.payments) {
            const newPayments: PaymentItem[] = createItem.distances.map((_, i): PaymentItem => ({
                distanceKey: i.toString(),
                basePrice: undefined,
                priceByDate: [{ date: undefined, price: undefined }],
                priceBySlots: [{ slots: undefined, price: undefined }]
            }));
            setPayments(newPayments);
        }
    }, []);

    useEffect(() => {
        if (runs.step === 3) {
            dispatch(changeStepFormRunsAction(form));
        }
    }, [runs.step]);

    const onChangeTabs = (key: string) => {
        setActiveKey(key);
        setFieldsTab(+key);
    };

    const onChange = (keys: string[], index: number[], value: any) => {
        let newPayments = [];
        if (keys.length === 1) {
            newPayments = payments.map((payment, i) => {
                if (i === index[0]) {
                    return {
                        ...payment,
                        [keys[0]]: value
                    };
                }
                return payment;
            });
        } else {
            newPayments = payments.map((payment: PaymentItem, i) => {
                if (i === index[0]) {
                    return {
                        ...payment,
                        [keys[0]]: (payment[keys[0]] as (PaymentPriceByDateItem[] | PaymentPriceBySlotsItem[])).map((item: any, j: number) => {
                            if (j === index[1]) {
                                return {
                                    ...item,
                                    [keys[1]]: value
                                };
                            }
                            return item;
                        })
                    };
                }
                return payment;
            });
        }

        setPayments(newPayments);
    };

    const addPrice = (keyPayment: number, type: 'byDate' | 'bySlots') => {
        const newPayments = [...payments];
        if (type === 'byDate') {
            newPayments[keyPayment].priceByDate.push({ date: undefined, price: undefined });
        } else if (type === 'bySlots') {
            newPayments[keyPayment].priceBySlots.push({ slots: undefined, price: undefined });
        }
        setPayments(newPayments);
    };

    const removePrice = (keyPayment: number, type: 'byDate' | 'bySlots', keyPrice: number) => {
        const newPayments = [...payments];
        if (type === 'byDate') {
            newPayments[keyPayment].priceByDate = newPayments[keyPayment].priceByDate.filter((_, i) => i !== keyPrice);
        } else if (type === 'bySlots') {
            newPayments[keyPayment].priceBySlots = newPayments[keyPayment].priceBySlots.filter((_, i) => i !== keyPrice);
        }
        setPayments(newPayments);
        setFieldsTab(keyPayment, newPayments);
    };

    const onFinish = () => {
        if (createItem) {
            for (let i = 0; i < payments.length; i++) {
                const keys = Object.keys(payments[i]);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];

                    if (key === 'basePrice') {
                        if (payments[i][key] === undefined || payments[i][key] === null) {
                            setActiveKey(i.toString());
                            setFieldsTab(i);
                            form.validateFields();
                            return;
                        }
                    }

                    // if (key === 'priceByDate') {
                    //     for (let indexPrice = 0; indexPrice < payments[i].priceByDate.length; indexPrice++) {
                    //         const priceKeys = Object.keys(payments[i].priceByDate[indexPrice]);
                    //         for (let indexKey = 0; indexKey < priceKeys.length; indexKey++) {
                    //             const priceKey = priceKeys[indexKey];
                    //             if (payments[i].priceByDate[indexPrice][priceKey] === undefined) {
                    //                 setActiveKey(i.toString());
                    //                 setFieldsTab(i);
                    //                 form.validateFields();
                    //                 return;
                    //             }
                    //         }
                    //     }
                    // } else if (key === 'priceBySlots') {
                    //     for (let indexPrice = 0; indexPrice < payments[i].priceBySlots.length; indexPrice++) {
                    //         const priceKeys = Object.keys(payments[i].priceBySlots[indexPrice]);
                    //         for (let indexKey = 0; indexKey < priceKeys.length; indexKey++) {
                    //             const priceKey = priceKeys[indexKey];
                    //             if (payments[i].priceBySlots[indexPrice][priceKey] === undefined) {
                    //                 setActiveKey(i.toString());
                    //                 setFieldsTab(i);
                    //                 form.validateFields();
                    //                 return;
                    //             }
                    //         }
                    //     }
                    // }
                    // else if (payments[i][key] === undefined || null) {
                    //     setActiveKey(i.toString());
                    //     setFieldsTab(i);
                    //     form.validateFields();
                    //     return;
                    // }
                }
            }

            createItem.payments = payments;

            if (runs.modals.create) {
                dispatch(changeStepRunsAction('next'));
            }
        }
    };

    return (
        <Row gutter={15}>
            <Col lg={24}>
                <Title level={3}>Шаг 4: Оплата</Title>
            </Col>
            <Col lg={24}>
                <Tabs
                    type="card"
                    onChange={onChangeTabs}
                    activeKey={activeKey}
                >
                    {payments.map((payment, i) => (
                        <TabPane
                            tab={createItem?.distances[+payment.distanceKey].name}
                            key={i}
                            closable={false}
                        >
                            {+activeKey === i && (
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                >
                                    <Row gutter={15}>
                                        <Col lg={12}>
                                            <Form.Item
                                                name="basePricePayment"
                                                rules={[{ required: true, message: 'Заполните поле' }]}
                                                label="Базовая стоимость, руб"
                                            >
                                                <InputNumber
                                                    min={0}
                                                    placeholder="Введите цену"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => onChange(['basePrice'], [i], value)}
                                                    value={payment.basePrice}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24}>
                                            <Row gutter={15}>
                                                <Col span={12}>
                                                    <Text>Изменение стоимости по дате</Text>
                                                    {payment.priceByDate.map((priceItem, j) => (
                                                        <Row gutter={15} key={`byDate-${j}`}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label="Стоимость, руб"
                                                                    required={false}
                                                                >
                                                                    {j !== 0 ? (
                                                                        <Space>
                                                                            <Button
                                                                                onClick={() => removePrice(i, 'byDate', j)}
                                                                            >
                                                                                <MinusOutlined />
                                                                            </Button>
                                                                            <Form.Item
                                                                                name={[`priceDatePayment-${j}`]}
                                                                                rules={[{ required: false, message: 'Заполните поле' }]}
                                                                                noStyle
                                                                            >
                                                                                <InputNumber
                                                                                    min={1}
                                                                                    placeholder="Введите цену"
                                                                                    style={{ width: '100%' }}
                                                                                    onChange={(value) => onChange(['priceByDate', 'price'], [i, j], value)}
                                                                                    value={priceItem.price}
                                                                                />
                                                                            </Form.Item>
                                                                        </Space>
                                                                    ) : (
                                                                        <Form.Item
                                                                            name={[`priceDatePayment-${j}`]}
                                                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                                                            noStyle
                                                                        >
                                                                            <InputNumber
                                                                                min={1}
                                                                                placeholder="Введите цену"
                                                                                style={{ width: '100%' }}
                                                                                onChange={(value) => onChange(['priceByDate', 'price'], [i, j], value)}
                                                                                value={priceItem.price}
                                                                            />
                                                                        </Form.Item>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={`datePayment-${j}`}
                                                                    rules={[{ required: false, message: 'Заполните поле' }]}
                                                                    label="Дата"
                                                                >
                                                                    <DatePicker
                                                                        placeholder="Выберите дату"
                                                                        style={{ width: '100%' }}
                                                                        format="DD.MM.YYYY"
                                                                        onChange={(value) => onChange(['priceByDate', 'date'], [i, j], value)}
                                                                        value={priceItem.date}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => addPrice(i, 'byDate')}>Добавить ещё</Button>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <Text>Изменение стоимости по купленым слотам</Text>
                                                    {payment.priceBySlots.map((priceItem, j) => (
                                                        <Row gutter={15} key={`bySlots-${j}`}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label="Стоимость, руб"
                                                                    required={false}
                                                                >
                                                                    {j !== 0 ? (
                                                                        <Space>
                                                                            <Button
                                                                                onClick={() => removePrice(i, 'bySlots', j)}
                                                                            >
                                                                                <MinusOutlined />
                                                                            </Button>
                                                                            <Form.Item
                                                                                name={[`priceSlotsPayment-${j}`]}
                                                                                rules={[{ required: false, message: 'Заполните поле' }]}
                                                                                noStyle
                                                                            >
                                                                                <InputNumber
                                                                                    min={1}
                                                                                    placeholder="Введите цену"
                                                                                    style={{ width: '100%' }}
                                                                                    onChange={(value) => onChange(['priceBySlots', 'price'], [i, j], value)}
                                                                                    value={priceItem.price}
                                                                                />
                                                                            </Form.Item>
                                                                        </Space>
                                                                    ) : (
                                                                        <Form.Item
                                                                            name={[`priceSlotsPayment-${j}`]}
                                                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                                                            noStyle
                                                                        >
                                                                            <InputNumber
                                                                                min={1}
                                                                                placeholder="Введите цену"
                                                                                style={{ width: '100%' }}
                                                                                onChange={(value) => onChange(['priceBySlots', 'price'], [i, j], value)}
                                                                                value={priceItem.price}
                                                                            />
                                                                        </Form.Item>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={`slotsPayment-${j}`}
                                                                    rules={[{ required: false, message: 'Заполните поле' }]}
                                                                    label="Слоты"
                                                                >
                                                                    <InputNumber
                                                                        min={1}
                                                                        placeholder="100"
                                                                        style={{ width: '100%' }}
                                                                        onChange={(value) => onChange(['priceBySlots', 'slots'], [i, j], value)}
                                                                        value={priceItem.slots}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => addPrice(i, 'bySlots')}>Добавить ещё</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </TabPane>
                    ))}
                </Tabs>
            </Col>
        </Row>
    );
};

export default Payment;
