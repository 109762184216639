import {
    NewsStateType,
    NewsActionsType,
    NewsActionsList
} from './types';

export const initialState: NewsStateType = {
    data: null,
    modals: {
        create: false,
        edit: false
    },
    loading: false,
    error: null
};

const authReducer = (state: NewsStateType = initialState, action: NewsActionsType) : NewsStateType => {
    switch (action.type) {
        case NewsActionsList.ERROR_NEWS: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case NewsActionsList.MODAL_ADD_NEWS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: !state.modals.create
                }
            };
        }
        case NewsActionsList.MODAL_EDIT_NEWS: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    edit: !state.modals.edit
                }
            };
        }
        case NewsActionsList.REQUEST_GET_NEWS:
        case NewsActionsList.REQUEST_ADD_NEWS:
        case NewsActionsList.REQUEST_EDIT_NEWS:
        case NewsActionsList.REQUEST_REMOVE_NEWS:
        case NewsActionsList.REQUEST_UNPUBLISH_NEWS:
        case NewsActionsList.REQUEST_PUBLISH_NEWS:
            return {
                ...state,
                loading: true
            };
        case NewsActionsList.RESPONSE_GET_NEWS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case NewsActionsList.RESPONSE_ADD_NEWS: {
            return {
                ...state,
                loading: false,
                data: state.data ? [...state.data, action.data] : [action.data],
                modals: {
                    ...state.modals,
                    create: false
                }
            };
        }
        case NewsActionsList.RESPONSE_EDIT_NEWS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.data.id) {
                        return {
                            ...item,
                            ...action.data,
                            image: action.data.image ? action.data.image : item.image
                        };
                    }
                    return item;
                }) : [action.data],
                modals: {
                    ...state.modals,
                    edit: false
                }
            };
        }
        case NewsActionsList.RESPONSE_REMOVE_NEWS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.filter((item) => item.id !== action.id) : state.data
            };
        }
        case NewsActionsList.RESPONSE_UNPUBLISH_NEWS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            publish: null
                        };
                    }
                    return item;
                }) : state.data
            };
        }
        case NewsActionsList.RESPONSE_PUBLISH_NEWS: {
            return {
                ...state,
                loading: false,
                data: state.data ? state.data.map((item) => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            publish: action.publish
                        };
                    }
                    return item;
                }) : state.data
            };
        }
        default:
            return state;
    }
};

export default authReducer;
