import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Tabs,
    Checkbox
} from 'antd';
import {
    UserOutlined, LockOutlined, MobileOutlined, MailOutlined
} from '@ant-design/icons';

import './style.scss';
import { AppState } from '../../store';
import SignUp from './SignUp';
import SignIn from './SignIn';

const { TabPane } = Tabs;

interface AuthPageProps {
    history: {
        push(url: string): void;
    };
}

const AuthPage:React.FC<AuthPageProps> = ({ history }) => {
    const dispatch = useDispatch();
    const path = window.location.pathname.split('/')[1];
    const auth = useSelector((state: AppState) => state.auth);

    const changeTab = (activeKey: string) => {
        history.push(activeKey);
    };

    if (auth.data && auth.data.role) {
        if (window.location.pathname === '/sign-in' || window.location.pathname === '/sign-up') {
            switch (auth.data.role) {
                case 'admin': return <Redirect to={{ pathname: '/news' }} />;
                case 'user': return <Redirect to={{ pathname: '/profile' }} />;
                default: return <Redirect to={{ pathname: '/404' }} />;
            }
        }
    }

    return (
        <section className="auth page">
            <img src="assets/logo.svg" alt="athletica.space" className="auth__logo" />
            <Tabs defaultActiveKey={path} onChange={changeTab}>
                <TabPane tab="Вход" key="sign-in">
                    <SignIn />
                </TabPane>
                <TabPane tab="Регистрация" key="sign-up">
                    <SignUp />
                </TabPane>
            </Tabs>
        </section>
    );
};

export default AuthPage;
