/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row, Col, Form, Input, Select, DatePicker, Typography, TimePicker, InputNumber, Tabs
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';

import { AppState } from '../../../store';
import CustomUpload from '../../../components/CustomUpload';
import { AddRunFormContext } from '../AddRun';
import { changeStepFormRunsAction, changeStepRunsAction } from '../actions';
import { DistanceItem } from '../types';
import { EditRunFormContext } from '../EditRun';
import { API } from '../../..';

const { Title } = Typography;
const { TabPane } = Tabs;

/*
    name: string,
    mileage: number,
    slots: number,
    date: Moment,
    startTime: Moment,
    finishTime: Moment,
    categories: string[],
    prizeCategories: string[],
    foodOutlets: number,
    description: string,
    requirements: string,
    expo: string,
    route: {
        text: string,
        jpx: RcFile | undefined
    }
*/

export const defaultDistanceData: DistanceItem = {
    name: undefined,
    categories: undefined,
    description: undefined,
    startTime: undefined,
    finishTime: undefined,
    foodOutlets: undefined,
    mileage: undefined,
    prizeCategories: undefined,
    requirements: undefined,
    routeText: undefined,
    routeJpx: undefined,
    slots: undefined,
    startCoords: undefined
};

const Distance:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const { createItem } = runs.modals.create ? useContext(AddRunFormContext) : useContext(EditRunFormContext);
    const [form] = Form.useForm();
    const [distances, setDistances] = useState<DistanceItem[]>([defaultDistanceData]);
    const [activeKey, setActiveKey] = useState('0');

    const setFieldsTab = (key: number, newDistances?: DistanceItem[] | undefined) => {
        const itemDistance = newDistances || distances;

        form.resetFields();
        form.setFields([
            {
                name: 'nameDistance',
                value: itemDistance[key].name
            },
            {
                name: 'categoryDistance',
                value: itemDistance[key].categories
            },
            {
                name: 'prizeCategoryDistance',
                value: itemDistance[key].prizeCategories
            },
            {
                name: 'descriptionDistance',
                value: itemDistance[key].description
            },
            {
                name: 'startTimeDistance',
                value: itemDistance[key].startTime
            },
            {
                name: 'finishTimeDistance',
                value: itemDistance[key].finishTime?.isValid() ? itemDistance[key].finishTime : undefined
            },
            {
                name: 'foodOutletsDistance',
                value: itemDistance[key].foodOutlets ? itemDistance[key].foodOutlets : undefined
            },
            {
                name: 'mileageDistance',
                value: itemDistance[key].mileage
            },
            {
                name: 'requirementsDistance',
                value: itemDistance[key].requirements
            },
            {
                name: 'slotsDistance',
                value: itemDistance[key].slots ? itemDistance[key].slots : undefined
            },
            {
                name: 'routeTextDistance',
                value: itemDistance[key].routeText
            },
            {
                name: 'startCoords',
                value: itemDistance[key].startCoords
            }
        ]);

        if (itemDistance[key].routeJpx && itemDistance[key].routeJpx !== undefined) {
            let fileList: UploadFile[] = [];
            const file = itemDistance[key].routeJpx;
            if (typeof file === 'string') {
                const filePath = file.split('/');
                const fileName = filePath[filePath.length - 1];
                fileList = [...fileList, {
                    uid: '0',
                    name: fileName,
                    thumbUrl: `${API}${file}`
                }];
            } else {
                fileList = [...fileList, file as UploadFile];
            }

            form.setFields([{
                name: 'routeJpxDistance',
                value: fileList
            }]);
        } else {
            form.setFields([{
                name: 'routeJpxDistance',
                value: undefined
            }]);
        }
    };

    useEffect(() => {
        if (createItem?.distances.length) {
            setDistances(createItem.distances);
            setActiveKey('0');
            setFieldsTab(0, createItem.distances);
        }
    }, []);

    useEffect(() => {
        if (runs.step === 2) {
            dispatch(changeStepFormRunsAction(form));
        }
    }, [runs.step]);

    const onChange = (key: string, index: number, value: any) => {
        const newDistances = distances.map((distance, i) => {
            if (i === index) {
                return {
                    ...distance,
                    [key]: value
                };
            }
            return distance;
        });

        setDistances(newDistances);

        if (runs.modals.edit && createItem) {
            createItem.distances = newDistances;
        }
    };

    const add = () => {
        const newDistances = [...distances, defaultDistanceData];
        const newKey = newDistances.length - 1;
        setDistances(newDistances);
        setFieldsTab(newKey, newDistances);
        setActiveKey(newKey.toString());

        if (runs.modals.edit && createItem) {
            createItem.distances = newDistances;
        }
    };

    const remove = (target: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        const newDistances = distances.filter((_, i) => i !== +target);
        const newKey = newDistances.length - 1;
        setDistances(newDistances);
        setFieldsTab(newKey, newDistances);
        setActiveKey(newKey.toString());
    };

    const onEditTabs = (target: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        switch (action) {
            case 'add': add(); break;
            case 'remove': remove(target); break;
            default: break;
        }
    };

    const onChangeTabs = (key: string) => {
        setActiveKey(key);
        setFieldsTab(+key);
    };

    const onFinish = async () => {
        if (createItem && distances.length === 1) {
            createItem.distances = distances;

            if (runs.modals.create) {
                dispatch(changeStepRunsAction('next'));
            }
        } else if (createItem && distances.length > 1) {
            for (let i = 0; i < distances.length; i++) {
                const keys = Object.keys(distances[i]);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    if (distances[i][key] === undefined && key !== 'routeJpx') {
                        setActiveKey(i.toString());
                        setFieldsTab(i);
                        form.validateFields();
                        return;
                    }
                }
            }
            createItem.distances = distances;

            if (runs.modals.create) {
                dispatch(changeStepRunsAction('next'));
            }
        }
    };

    return (
        <Row gutter={15}>
            <Col lg={24}>
                <Title level={3}>Шаг 3: Дистанция</Title>
            </Col>
            <Col lg={24}>
                <Tabs
                    type="editable-card"
                    onEdit={onEditTabs}
                    onChange={onChangeTabs}
                    activeKey={activeKey}
                >
                    {distances.map((distance, i) => (
                        <TabPane
                            tab={distance.name === undefined ? `Дистанция ${i + 1}` : distance.name}
                            key={i}
                            closable={!!(i !== 0 && runs.modals.create)}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                            >
                                <Row gutter={15}>
                                    <Col lg={12}>
                                        <Form.Item
                                            name="nameDistance"
                                            rules={[{ required: true, message: 'Заполните поле' }]}
                                            label="Название"
                                        >
                                            <Input
                                                placeholder="Введите название"
                                                onChange={(e) => onChange('name', i, e.target.value)}
                                                value={distance.name}
                                            />
                                        </Form.Item>
                                        <Row gutter={15}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="mileageDistance"
                                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                                    label="Дистанция, м"
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        placeholder="1"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChange('mileage', i, value)}
                                                        value={distance.mileage}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="slotsDistance"
                                                    rules={[{ required: false, message: 'Заполните поле' }]}
                                                    label="Кол-во слотов"
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        placeholder="100"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChange('slots', i, value)}
                                                        value={distance.slots ? distance.slots : undefined}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="startTimeDistance"
                                                    rules={[{ required: true, message: 'Заполните поле' }]}
                                                    label="Старт"
                                                >
                                                    <DatePicker
                                                        format="DD.MM.YYYY HH:mm"
                                                        showTime
                                                        minuteStep={5}
                                                        placeholder="Время"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChange('startTime', i, value)}
                                                        value={distance.startTime}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="finishTimeDistance"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Заполните поле'
                                                        },
                                                        {
                                                            message: 'Финиш меньше или равен старту',
                                                            validator: (_, valueEnd) => {
                                                                const valueStart = form.getFieldValue('startTimeDistance');
                                                                if (valueEnd && valueEnd !== undefined && valueStart && valueStart !== undefined) {
                                                                    const end = valueEnd as Moment;
                                                                    const start = valueStart as Moment;

                                                                    if (+end.format('x') <= +start.format('x')) {
                                                                        return Promise.reject();
                                                                    }

                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.resolve();
                                                            }
                                                        }
                                                    ]}
                                                    label="Финиш"
                                                >
                                                    <DatePicker
                                                        format="DD.MM.YYYY HH:mm"
                                                        showTime
                                                        minuteStep={5}
                                                        placeholder="Время"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChange('finishTime', i, value)}
                                                        value={distance.finishTime}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            name="categoryDistance"
                                            rules={[{ required: true, message: 'Заполните поле' }]}
                                            label="Категория"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Выберите категорию"
                                                onChange={(value) => onChange('categories', i, value)}
                                                value={distance.categories}
                                            >
                                                <Select.Option value="Бег">Бег</Select.Option>
                                                <Select.Option value="Триатлон">Триатлон</Select.Option>
                                                <Select.Option value="Велосипед">Велосипед</Select.Option>
                                                <Select.Option value="Плаванье">Плаванье</Select.Option>
                                                <Select.Option value="Лыжи">Лыжи</Select.Option>
                                                <Select.Option value="OCR-гонки">OCR-гонки</Select.Option>
                                                <Select.Option value="Прочее">Прочее</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="prizeCategoryDistance"
                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                            label="Призовые категории"
                                        >
                                            <Input
                                                placeholder="Введите название категорий"
                                                onChange={(e) => onChange('prizeCategories', i, e.target.value)}
                                                value={distance.prizeCategories}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="foodOutletsDistance"
                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                            label="Кол-во пунктов питания"
                                        >
                                            <InputNumber
                                                min={1}
                                                placeholder="5"
                                                style={{ width: '100%' }}
                                                onChange={(value) => onChange('foodOutlets', i, value)}
                                                value={distance.foodOutlets}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="startCoords"
                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                            label="Координаты начала"
                                        >
                                            <Input
                                                placeholder="59.213532, 39.885053"
                                                onChange={(e) => onChange('startCoords', i, e.target.value)}
                                                value={distance.startCoords}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Item
                                            name="descriptionDistance"
                                            rules={[{ required: false, message: 'Введите текст' }]}
                                            label="Описание"
                                        >
                                            <Input.TextArea
                                                placeholder="Введите текст"
                                                rows={5}
                                                onChange={(e) => onChange('description', i, e.target.value)}
                                                value={distance.description}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="requirementsDistance"
                                            rules={[{ required: false, message: 'Введите текст' }]}
                                            label="Требования к участникам"
                                        >
                                            <Input.TextArea
                                                placeholder="Введите текст"
                                                rows={5}
                                                onChange={(e) => onChange('requirements', i, e.target.value)}
                                                value={distance.requirements}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="routeTextDistance"
                                            rules={[{ required: false, message: 'Введите текст' }]}
                                            label="Маршрут"
                                        >
                                            <Input.TextArea
                                                placeholder="Введите текст"
                                                rows={5}
                                                onChange={(e) => onChange('routeText', i, e.target.value)}
                                                value={distance.routeText}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="routeJpxDistance"
                                            rules={[{ required: false, message: 'Заполните поле' }]}
                                        >
                                            <CustomUpload
                                                form={form}
                                                name="routeJpxDistance"
                                                listType="text"
                                                accept=".gpx"
                                                onChange={(info) => onChange('routeJpx', i, info.fileList[0].originFileObj)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                    ))}
                </Tabs>
            </Col>
        </Row>
    );
};

export default Distance;
