/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table, Breadcrumb, Row, Col, Typography, Divider, Button, Space, Form, DatePicker, Select, Input
} from 'antd';
import {
    EditOutlined,
    HomeOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './style.scss';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
import Preloader from '../../components/Preloader';
import UserIcon from '../../assets/user-avatar.svg';
import { ReactComponent as GoldBadgeIcon } from '../../assets/gold-badge.svg';
import { ReactComponent as SilverBadgeIcon } from '../../assets/silver-badge.svg';
import { ReactComponent as BronzeBadgeIcon } from '../../assets/bronze-badge.svg';
import {
    editProfileAction, getProfileAsyncAction, pairStravaProfileAsyncAction, saveProfileAction, saveProfileAsyncAction, uploadAvatarProfileAsyncAction
} from './actions';
import { ProfileForm } from './types';
import { API } from '../..';

interface ProfileBadgesTableRow {
    label: string,
    level: string,
    image: string,
    count: number
}

const { Column } = Table;
const { Title, Text } = Typography;

const HeaderProfilePage:React.FC = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: AppState) => state.profile);

    return (
        <div className="page__header">
            <Breadcrumb>
                <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Мой профиль</Breadcrumb.Item>
            </Breadcrumb>
            {profile.edit ? (
                <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => dispatch(saveProfileAction())}
                    loading={profile.loading}
                >
                    Сохранить
                </Button>
            ) : (
                <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => dispatch(editProfileAction())}
                    loading={profile.loading}
                >
                    Редактировать
                </Button>
            )}
        </div>
    );
};

const ProfilePage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state: AppState) => state.auth);
    const profile = useSelector((state: AppState) => state.profile);
    const [form] = Form.useForm();
    const [avatarFile, setAvatarFile] = useState<File | null>(null);

    useEffect(() => {
        if (auth.data && auth.data.id) {
            dispatch(getProfileAsyncAction(auth.data.id));
        }
    }, []);

    useEffect(() => {
        if (profile.save) {
            form.submit();
        }
    }, [profile.save]);

    useEffect(() => {
        if (profile.edit && profile.data) {
            form.setFields([
                {
                    name: 'email',
                    value: profile.data.email
                },
                {
                    name: 'birthday',
                    value: profile.data?.birthday
                },
                {
                    name: 'gender',
                    value: profile.data?.gender
                },
                {
                    name: 'country',
                    value: profile.data?.country
                },
                {
                    name: 'city',
                    value: profile.data?.city
                },
                {
                    name: 'phone',
                    value: profile.data?.phone
                }
            ]);
        }
    }, [profile.edit]);

    useEffect(() => {
        if (avatarFile && auth.data && auth.data.id) {
            dispatch(uploadAvatarProfileAsyncAction({
                file: avatarFile,
                id: auth.data.id
            }));
        }
    }, [avatarFile]);

    const onSend = (values: ProfileForm) => {
        if (auth.data && auth.data.id) {
            dispatch(saveProfileAsyncAction({
                ...values,
                id: auth.data.id,
                avatar: profile.data ? profile.data.avatar : null
            }));
        }
    };

    const getGenderLabel = (key: string) => (key === 'male' ? 'Мужской' : 'Женский');

    const onPairStrava = () => {
        dispatch(pairStravaProfileAsyncAction());
    };

    if (profile.data === null) {
        return <Preloader />;
    }

    return (
        <section className="profile page">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSend}
                className="profile__form"
            >
                <label
                    className={`profile__avatar ${profile.data && profile.data.avatar ? 'profile__avatar_custom' : ''}`}
                    style={{
                        backgroundImage: profile.data && profile.data.avatar ? `url('${API}${profile.data.avatar}')` : `url('${UserIcon}')`
                    }}
                >
                    <input
                        type="file"
                        hidden
                        onChange={(e) => {
                            const { files } = e.target;
                            if (files?.length) {
                                setAvatarFile(files[0]);
                            } else {
                                setAvatarFile(null);
                            }
                        }}
                    />
                </label>
                <Row gutter={30}>
                    <Col lg={24} xxl={16}>
                        <Row gutter={30}>
                            <Col lg={24}>
                                <Title level={1}>Евгений Петров</Title>
                                <Divider />
                            </Col>
                            <Col lg={12}>
                                <Space direction="vertical">
                                    <Title level={5}>Основная информация</Title>
                                    <Row gutter={30} align="middle" className="profile__form-group">
                                        <Col span={12}>
                                            <Text type="secondary">Дата рождения:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="birthday" className="profile__form-item">
                                                    <DatePicker placeholder="Дата рождения" format="DD.MM.YYYY" />
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.birthday ? profile.data.birthday.format('DD.MM.YYYY') : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">Пол:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="gender" className="profile__form-item">
                                                    <Select placeholder="Пол">
                                                        <Select.Option value="male">Мужской</Select.Option>
                                                        <Select.Option value="female">Женский</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.gender ? getGenderLabel(profile.data.gender) : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">Страна:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="country" className="profile__form-item">
                                                    <Select placeholder="Страна">
                                                        <Select.Option value="ru">Россия</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.country ? profile.data.country : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">Город:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="city" className="profile__form-item">
                                                    <Select placeholder="Город">
                                                        <Select.Option value="moscow">Москва</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.city ? profile.data.city : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                    </Row>
                                    <Title level={5} className="profile__form-title">Контактная информация</Title>
                                    <Row gutter={30} align="middle" className="profile__form-group">
                                        <Col span={12}>
                                            <Text type="secondary">E-mail:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="email" className="profile__form-item">
                                                    <Input placeholder="E-mail" />
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.email ? profile.data.email : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">Телефон:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.edit ? (
                                                <Form.Item name="phone" className="profile__form-item">
                                                    <Input placeholder="Телефон" />
                                                </Form.Item>
                                            ) : (
                                                <Text>{profile.data?.phone ? profile.data.phone : 'Не указано'}</Text>
                                            )}
                                        </Col>
                                    </Row>
                                    <Title level={5} className="profile__form-title">Связанные аккаунты</Title>
                                    <Row gutter={30} align="middle" className="profile__form-group">
                                        <Col span={12}>
                                            <Text type="secondary">Strava:</Text>
                                        </Col>
                                        <Col span={12}>
                                            {profile.data.stravaID && profile.data.stravaName ? (
                                                <a
                                                    href={`https://www.strava.com/athletes/${profile.data.stravaID}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {profile.data.stravaName}
                                                </a>
                                            ) : (
                                                <Button
                                                    onClick={onPairStrava}
                                                >
                                                    Привязать
                                                </Button>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">ParkRun:</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text>parkrun</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text type="secondary">Google fit:</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text>google_fit</Text>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col lg={12}>
                                <Title level={5}>Информация и достижения</Title>
                                <Divider />
                                <Row gutter={30} align="middle">
                                    <Col span={12}>
                                        <Text type="secondary">Клуб:</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Button type="link">Московские бегуны</Button>
                                    </Col>
                                    <Col span={24}>
                                        <Space direction="vertical" style={{ width: '100%', marginTop: 8 }}>
                                            <Text type="secondary">Бейджи:</Text>
                                            <Table
                                                dataSource={[
                                                    {
                                                        label: 'Золото',
                                                        level: 'gold',
                                                        image: '',
                                                        count: 2
                                                    },
                                                    {
                                                        label: 'Серебро',
                                                        level: 'silver',
                                                        image: '',
                                                        count: 1
                                                    },
                                                    {
                                                        label: 'Бронза',
                                                        level: 'bronze',
                                                        image: '',
                                                        count: 5
                                                    }
                                                ]}
                                                className="badges"
                                                pagination={false}
                                                rowKey="level"
                                            >
                                                <Column
                                                    title="Уровень"
                                                    dataIndex="label"
                                                    key="label"
                                                />
                                                <Column
                                                    title="Бейджи"
                                                    dataIndex="image"
                                                    key="image"
                                                    className="badges__items"
                                                    render={(value, record: ProfileBadgesTableRow) => {
                                                        const images = [];
                                                        let imageTemplate: any = null;
                                                        switch (record.level) {
                                                            case 'gold':
                                                                imageTemplate = (key: React.Key) => <GoldBadgeIcon key={key} />;
                                                                break;
                                                            case 'silver':
                                                                imageTemplate = (key: React.Key) => <SilverBadgeIcon key={key} />;
                                                                break;
                                                            case 'bronze':
                                                                imageTemplate = (key: React.Key) => <BronzeBadgeIcon key={key} />;
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                        for (let i = 0; i < record.count; i++) {
                                                            images.push(imageTemplate(i));
                                                        }
                                                        return images;
                                                    }}
                                                />
                                                <Column
                                                    title="Кол-во"
                                                    dataIndex="count"
                                                    key="count"
                                                    render={(value) => (
                                                        <Text strong>
                                                            x
                                                            {value}
                                                        </Text>
                                                    )}
                                                />
                                            </Table>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </section>
    );
};

export default WrapperPage(ProfilePage, HeaderProfilePage);
