/* eslint-disable no-plusplus */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import './style.scss';
import { Breadcrumb } from 'antd';
import { AppState } from '../../store';
import WrapperPage from '../../components/WrapperPage';
import Preloader from '../../components/Preloader';

const HeaderCalendarPage:React.FC = () => (
    <div className="page__header">
        <Breadcrumb>
            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>Календарь забегов</Breadcrumb.Item>
        </Breadcrumb>
    </div>
);

const CalendarPage:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state: AppState) => state.auth);

    // if (profile.data === null) {
    //     return <Preloader />;
    // }

    return (
        <section className="calendar page">
            calendar
        </section>
    );
};

export default WrapperPage(CalendarPage, HeaderCalendarPage);
