import {
    WorkoutStateType,
    WorkoutActionsList,
    WorkoutActionsType
} from './types';

export const initialState: WorkoutStateType = {
    data: null,
    loading: false,
    error: null
};

const workoutReducer = (state: WorkoutStateType = initialState, action: WorkoutActionsType) : WorkoutStateType => {
    switch (action.type) {
        case WorkoutActionsList.ERROR_WORKOUT: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case WorkoutActionsList.REQUEST_GET_WORKOUTS: {
            return {
                ...state,
                loading: true
            };
        }
        case WorkoutActionsList.RESPONSE_GET_WORKOUTS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        default:
            return state;
    }
};

export default workoutReducer;
