import axios from 'axios';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../..';
import { notification } from '../../utils';
import {
    AddTaskForm, TaskItem, TasksActionsList, TasksActionsType, TasksStateType, EditTaskForm, ConditionsTypes, TaskItemFromServer, TaskCondition
} from './types';

export const errorTaskAction = (error: string): TasksActionsType => ({
    type: TasksActionsList.ERROR_TASKS,
    error
});

// MODALS ACTIONS //

export const switchAddModalAction = (): TasksActionsType => ({
    type: TasksActionsList.MODAL_ADD_TASKS
});

export const switchEditModalAction = (): TasksActionsType => ({
    type: TasksActionsList.MODAL_EDIT_TASKS
});

// ADD ACTIONS //

export const requestAddTaskAction = (): TasksActionsType => ({
    type: TasksActionsList.REQUEST_ADD_TASKS
});

export const responseAddTaskAction = (data: TaskItem): TasksActionsType => ({
    type: TasksActionsList.RESPONSE_ADD_TASKS,
    data
});

export const addTaskAsyncAction = (data: AddTaskForm) => async (dispatch: ThunkDispatch<TasksStateType, void, Action>): Promise<void> => {
    dispatch(requestAddTaskAction());

    const formData = new FormData();

    formData.append('title', data.title || '');
    formData.append('periodStart', data.period ? data.period[0].format('X') : '');
    formData.append('periodEnd', data.period ? data.period[1].format('X') : '');
    formData.append('category', data.category || '');
    formData.append('conditions', JSON.stringify({
        type: data.conditionsType,
        value: data.conditionsValue
    }));
    formData.append('description', data.description || '');

    const result = await axios.post(`${API}api/v1/tasks`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorTaskAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const taskData: TaskItem = {
            id: response.data.id,
            title: data.title || '',
            period: data.period || [moment(), moment()],
            category: data.category || 'single',
            conditions: {
                type: data.conditionsType as ConditionsTypes,
                value: data.conditionsValue || 0
            },
            description: data.description || ''
        };

        dispatch(responseAddTaskAction(taskData));
        notification('success', 'Успех', 'Задание добавлено');
    }
};

// EDIT ACTIONS //

export const requestEditTaskAction = (): TasksActionsType => ({
    type: TasksActionsList.REQUEST_EDIT_TASKS
});

export const responseEditTaskAction = (data: TaskItem): TasksActionsType => ({
    type: TasksActionsList.RESPONSE_EDIT_TASKS,
    data
});

export const editTaskAsyncAction = (data: EditTaskForm) => async (dispatch: ThunkDispatch<TasksStateType, void, Action>): Promise<void> => {
    dispatch(requestEditTaskAction());

    const formData = new FormData();

    formData.append('id', data.id.toString());
    formData.append('title', data.title || '');
    formData.append('periodStart', data.period ? data.period[0].format('X') : '');
    formData.append('periodEnd', data.period ? data.period[1].format('X') : '');
    formData.append('category', data.category || '');
    formData.append('conditions', JSON.stringify({
        type: data.conditionsType,
        value: data.conditionsValue
    }));
    formData.append('description', data.description || '');

    const result = await axios.post(`${API}api/v1/tasks/edit`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorTaskAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        const taskData: TaskItem = {
            id: response.data.id,
            title: data.title || '',
            period: data.period || [moment(), moment()],
            category: data.category || 'single',
            conditions: {
                type: data.conditionsType as ConditionsTypes,
                value: data.conditionsValue || 0
            },
            description: data.description || ''
        };

        dispatch(responseEditTaskAction(taskData));
        notification('success', 'Успех', 'Задание обновлено');
    }
};

// REMOVE ACTIONS //

export const requestRemoveTaskAction = (): TasksActionsType => ({
    type: TasksActionsList.REQUEST_REMOVE_TASKS
});

export const responseRemoveTaskAction = (id: number): TasksActionsType => ({
    type: TasksActionsList.RESPONSE_REMOVE_TASKS,
    id
});

export const removeTaskAsyncAction = (id: number) => async (dispatch: ThunkDispatch<TasksStateType, void, Action>): Promise<void> => {
    dispatch(requestRemoveTaskAction());

    const formData = new FormData();

    formData.append('id', id.toString());

    const result = await axios.post(`${API}api/v1/tasks/remove`, formData);
    const response = result.data;

    if (response.error) {
        dispatch(errorTaskAction(response.message));
        notification('error', 'Ошибка', response.message);
    } else {
        dispatch(responseRemoveTaskAction(id));
        notification('success', 'Успех', 'Задание удалено');
    }
};

// GET ACTIONS //

export const requestGetTasksAction = (): TasksActionsType => ({
    type: TasksActionsList.REQUEST_GET_TASKS
});

export const responseGetTasksAction = (data: TaskItem[]): TasksActionsType => ({
    type: TasksActionsList.RESPONSE_GET_TASKS,
    data
});

export const getTasksAsyncAction = () => async (dispatch: ThunkDispatch<TasksStateType, void, Action>): Promise<void> => {
    dispatch(requestGetTasksAction());

    const result = await axios.get(`${API}api/v1/tasks`);
    const response = result.data;

    if (response.error) {
        dispatch(errorTaskAction(response.message));
    } else {
        let tasks: TaskItem[] | [] = [];
        <TaskItemFromServer>response.data.forEach((item: TaskItemFromServer) => {
            const conditionsFromServer = JSON.parse(item.conditions);
            const conditions: TaskCondition = {
                type: conditionsFromServer.type,
                value: +conditionsFromServer.value
            };

            tasks = [...tasks, {
                id: item.id,
                title: item.title,
                period: [moment(item.periodStart), moment(item.periodEnd)],
                category: item.category,
                conditions,
                description: item.description
            }];
        });

        dispatch(responseGetTasksAction(tasks));
    }
};
