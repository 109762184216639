import { RcFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';

// DATA ITEMS TYPES //

export interface NewsItemData {
    id: number,
    title: string,
    subtitle: string,
    text: string,
    publish: Moment | null,
    categories: string[],
    image?: string
}

// DATA FROM SERVER //

export interface NewItemDataFromServer {
    id: number,
    title: string,
    subtitle: string,
    text: string,
    publish: string | null,
    categories: string,
    image: string | null
}

// DATA FORMS TYPES //

export interface AddNewFormData {
    categories: string[],
    publish: Moment | null,
    subtitle: string,
    text: string,
    title: string,
    image: RcFile | null
}

export interface EditNewFormData {
    id: number,
    categories: string[],
    publish: Moment | null,
    subtitle: string,
    text: string,
    title: string,
    image: RcFile | null
}

// STORE TYPE //

export interface NewsStateType {
    data: NewsItemData[] | null,
    modals: {
        create: boolean,
        edit: boolean
    }
    loading: boolean,
    error: string | null
}

// ACTIONS TYPES //

export enum NewsActionsList {
    ERROR_NEWS = 'ERROR_NEWS',
    REQUEST_GET_NEWS = 'REQUEST_GET_NEWS',
    RESPONSE_GET_NEWS = 'RESPONSE_GET_NEWS',
    REQUEST_ADD_NEWS = 'REQUEST_ADD_NEWS',
    RESPONSE_ADD_NEWS = 'RESPONSE_ADD_NEWS',
    REQUEST_EDIT_NEWS = 'REQUEST_EDIT_NEWS',
    RESPONSE_EDIT_NEWS = 'RESPONSE_EDIT_NEWS',
    REQUEST_REMOVE_NEWS = 'REQUEST_REMOVE_NEWS',
    RESPONSE_REMOVE_NEWS = 'RESPONSE_REMOVE_NEWS',
    REQUEST_UNPUBLISH_NEWS = 'REQUEST_UNPUBLISH_NEWS',
    RESPONSE_UNPUBLISH_NEWS = 'RESPONSE_UNPUBLISH_NEWS',
    REQUEST_PUBLISH_NEWS = 'REQUEST_PUBLISH_NEWS',
    RESPONSE_PUBLISH_NEWS = 'RESPONSE_PUBLISH_NEWS',
    MODAL_ADD_NEWS = 'MODAL_ADD_NEWS',
    MODAL_EDIT_NEWS = 'MODAL_EDIT_NEWS'
}

// ERROR //

export interface ErrorNewsActionType {
    type: NewsActionsList.ERROR_NEWS,
    error: string
}

// GET NEWS TYPES //

export interface RequestGetNewsActionType {
    type: NewsActionsList.REQUEST_GET_NEWS
}

export interface ResponseGetNewsActionType {
    type: NewsActionsList.RESPONSE_GET_NEWS,
    data: NewsItemData[]
}

// ADD NEW TYPES //

export interface RequestAddNewActionType {
    type: NewsActionsList.REQUEST_ADD_NEWS
}

export interface ResponseAddNewActionType {
    type: NewsActionsList.RESPONSE_ADD_NEWS,
    data: NewsItemData
}

// EDIT NEW TYPES //

export interface RequestEditNewActionType {
    type: NewsActionsList.REQUEST_EDIT_NEWS
}

export interface ResponseEditNewActionType {
    type: NewsActionsList.RESPONSE_EDIT_NEWS,
    data: NewsItemData
}

// REMOVE NEW TYPES //

export interface RequestRemoveNewActionType {
    type: NewsActionsList.REQUEST_REMOVE_NEWS
}

export interface ResponseRemoveNewActionType {
    type: NewsActionsList.RESPONSE_REMOVE_NEWS,
    id: number
}

// UNPUBLISH NEW TYPES //

export interface RequestUnpublishNewActionType {
    type: NewsActionsList.REQUEST_UNPUBLISH_NEWS
}

export interface ResponseUnpublishNewActionType {
    type: NewsActionsList.RESPONSE_UNPUBLISH_NEWS,
    id: number
}

// UNPUBLISH NEW TYPES //

export interface RequestPublishNewActionType {
    type: NewsActionsList.REQUEST_PUBLISH_NEWS
}

export interface ResponsePublishNewActionType {
    type: NewsActionsList.RESPONSE_PUBLISH_NEWS,
    id: number,
    publish: Moment
}

// SWITCH MODALS NEWS //

export interface SwitchModalAddActionType {
    type: NewsActionsList.MODAL_ADD_NEWS
}

export interface SwitchModalEditActionType {
    type: NewsActionsList.MODAL_EDIT_NEWS
}

export type NewsActionsType =
ErrorNewsActionType
| RequestGetNewsActionType
| ResponseGetNewsActionType
| RequestAddNewActionType
| ResponseAddNewActionType
| RequestEditNewActionType
| ResponseEditNewActionType
| RequestRemoveNewActionType
| ResponseRemoveNewActionType
| RequestUnpublishNewActionType
| ResponseUnpublishNewActionType
| RequestPublishNewActionType
| ResponsePublishNewActionType
| SwitchModalAddActionType
| SwitchModalEditActionType;
