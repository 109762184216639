/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Select, Input, Form, DatePicker
} from 'antd';

import { AppState } from '../../store';
import { addParticipantRunAsyncAction, getRunsUsersAsyncAction, switchParticipantModalAction } from './actions';
import { AddParticipantForm } from './types';

const AddParticipant:React.FC = () => {
    const dispatch = useDispatch();
    const runs = useSelector((state: AppState) => state.runs);
    const [form] = Form.useForm();
    const [typeParticipant, changeTypeParticipant] = useState<undefined | number>(undefined);
    const run = runs.data ? runs.data.filter((item) => item.id === runs.modals.addParticipant.runID) : null;
    const runDistances = run && run.length ? run[0].distances : null;

    useEffect(() => {
        dispatch(getRunsUsersAsyncAction());
        form.resetFields();
    }, []);

    const onFinish = (values: AddParticipantForm) => {
        dispatch(addParticipantRunAsyncAction({ ...values, run: runs.modals.addParticipant.runID }));
        form.resetFields();
        changeTypeParticipant(undefined);
    };

    return (
        <>
            <Modal
                centered
                visible={runs.modals.addParticipant.visible}
                onCancel={() => dispatch(switchParticipantModalAction(0))}
                onOk={() => form.submit()}
                title="Добавить участника забега"
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="distanceKey"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Дистанция"
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Выберите дистанцию"
                        >
                            {runDistances?.map((distance, key) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Select.Option value={key} key={`distance-${key}`}>{distance.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="typeParticipant"
                        rules={[{ required: true, message: 'Заполните поле' }]}
                        label="Тип участника"
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Выберите тип участника"
                            onChange={(value: number) => changeTypeParticipant(value)}
                            value={typeParticipant}
                        >
                            <Select.Option value={1}>Есть аккаунт</Select.Option>
                            <Select.Option value={0}>Нет аккаунта</Select.Option>
                        </Select>
                    </Form.Item>
                    {typeParticipant !== undefined && typeParticipant === 1 && (
                        <Form.Item
                            name="user"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Пользователь"
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Выберите пользователя"
                                showSearch
                            >
                                {runs.modals.addParticipant.users ? runs.modals.addParticipant.users.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    )}
                    {typeParticipant !== undefined && typeParticipant === 0 && (
                        <>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="Имя участника"
                            >
                                <Input placeholder="Введите имя" />
                            </Form.Item>
                            <Form.Item
                                name="birthday"
                                rules={[{ required: true, message: 'Заполните поле' }]}
                                label="ДР участника"
                            >
                                <DatePicker
                                    placeholder="Выберите дату"
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default AddParticipant;
